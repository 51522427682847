import React from 'react';
import './MessageView.scss';
import BrandHeader from '../BrandHeader/BrandHeader';
import BrandFooter from '../BrandFooter/BrandFooter';

const MessageView = ({
  children,
  className,
  noContentPadding,
  hasHeaderBorder
}) => {
  if (!children) {
    return null;
  }

  const messageViewClassName = className ? className : '';

  const messageViewPaddingClass = noContentPadding
    ? 'crxdo-message-view-default-padding'
    : 'crxdo-message-view-padding';

  return (
    <div className={`crxdo-message-view ${messageViewClassName}`}>
      <BrandHeader hasHeaderBorder={hasHeaderBorder} />
      <div className="crxdo-message-view-border-wrap">
        <div
          className={`crxdo-message-view-content container ${messageViewPaddingClass}`}
        >
          {children}
        </div>
      </div>
      <BrandFooter />
    </div>
  );
};

export default MessageView;
