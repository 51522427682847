import React from 'react';
import PropTypes from 'prop-types';
import '../components/shared/BrandFooter/BrandFooter.scss';

class SafetyInformationModal extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="backdrop">
        <div className="modal">
          {this.props.children}
          <div className="model-header">Important Safety Information</div>
          <div className="modelMargin"></div>
          <div className="model-body">
            <div className="modelContainer">
              <div>
                <p></p>
                <p>
                  <b>Suicidal Ideation and Behavior</b>
                </p>
                <p>
                  Suicidal ideation and behavior, including a completed suicide, have occurred in subjects treated
                  with BIMZELX<sup>&reg;</sup> (bimekizumab-bkzx). Prior to prescribing BIMZELX, weigh the potential risks
                  and benefits in patients with a history of depression and/or suicidal ideation or behavior.
                  Patients with new or worsening suicidal ideation and behavior should be referred to a mental
                  health professional, as appropriate. Advise patients and caregivers to seek medical attention for
                  manifestations of suicidal ideation or behavior, new onset or worsening depression, anxiety, or other mood changes.</p>
                <p>
                  <b>Infections</b>
                </p>
                <p>
                  BIMZELX may increase the risk of infections.  Do not initiate treatment with BIMZELX in patients
                  with any clinically important active infection until the infection resolves or is adequately treated.
                  In patients with a chronic infection or a history of recurrent infection, consider the risks and
                  benefits prior to prescribing BIMZELX.  Instruct patients to seek medical advice if signs or
                  symptoms suggestive of clinically important infection occur. If a patient develops such an
                  infection or is not responding to standard therapy, monitor the patient closely and do not
                  administer BIMZELX until the infection resolves.
                </p>
                <p>
                  <b>Tuberculosis</b>
                </p>
                <p>
                  Evaluate patients for tuberculosis (TB) infection prior to initiating treatment with BIMZELX. Avoid
                  the use of BIMZELX in patients with active TB infection. Initiate treatment of latent TB prior to
                  administering BIMZELX.  Consider anti-TB therapy prior to initiation of BIMZELX in patients with
                  a past history of latent or active TB in whom an adequate course of treatment cannot be
                  confirmed.  Closely monitor patients for signs and symptoms of active TB during and after
                  treatment.
                </p>

                <p>
                  <b>Liver Biochemical Abnormalities</b>
                </p>
                <p>

                  Elevated serum transaminases were reported in clinical trials with BIMZELX. Test liver
                  enzymes, alkaline phosphatase and bilirubin at baseline, periodically during treatment with
                  BIMZELX, and according to routine patient management. If treatment-related increases in liver
                  enzymes occur and drug-induced liver injury is suspected, interrupt BIMZELX until a diagnosis
                  of liver injury is excluded. Permanently discontinue use of BIMZELX in patients with causally
                  associated combined elevations of transaminases and bilirubin. Avoid use of BIMZELX in
                  patients with acute liver disease or cirrhosis.
                </p>

                <p>
                  <b>Inflammatory Bowel Disease</b>
                </p>
                <p>
                  Cases of inflammatory bowel disease (IBD) have been reported in patients treated with IL-17
                  inhibitors, including BIMZELX. Avoid use of BIMZELX in patients with active IBD. During
                  BIMZELX treatment, monitor patients for signs and symptoms of IBD and discontinue treatment
                  if new onset or worsening of signs and symptoms occurs.
                </p>

                <p>
                  <b>Immunizations</b>
                </p>
                <p>
                  Prior to initiating therapy with BIMZELX, complete all age-appropriate vaccinations according to
                  current immunization guidelines. Avoid the use of live vaccines in patients treated with
                  BIMZELX.
                </p>

                <p>
                  <b>MOST COMMON ADVERSE REACTIONS</b>
                </p>
                <p>
                  Most common adverse reactions (&#8805;1&#37;) are upper respiratory infections, oral candidiasis,
                  headache, injection site reactions, tinea infections, gastroenteritis, Herpes Simplex infections,
                  acne, folliculitis, other Candida infections, and fatigue.
                </p>
              </div>
            </div>
          </div>
          <div className="model-footer">
            <button className="modalButton" onClick={this.props.onClose}>
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SafetyInformationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default SafetyInformationModal;
