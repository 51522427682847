import React from 'react';
import PropTypes from 'prop-types';
import './LocationDetails.scss';
import { formatPhoneNumber } from '../../../utils/format';
import brandConfig from '../../../brand/brand-config.json';

const LocationDetails = ({ location }) => {
  if (!location) {
    return null;
  }

  const {
    locationName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCodeMasked,
    phone,
    fax
  } = location;

  const phoneMasked = formatPhoneNumber(phone);
  const faxMasked = formatPhoneNumber(fax);
  const { language } = brandConfig;

  return (
    <address className="crxdo-location-details">
      {locationName && (
        <div className="crxdo-location-detail crxdo-location-detail-name">
          {locationName}
        </div>
      )}
      {addressLine1 && (
        <div className="crxdo-location-detail">{addressLine1}</div>
      )}
      {addressLine2 && (
        <div className="crxdo-location-detail">{addressLine2}</div>
      )}
      <div className="crxdo-location-detail">
        {city && <span className="crxdo-location-detail">{city},</span>}
        {state && <span className="crxdo-location-detail">{state}</span>}
        {zipCodeMasked && (
          <span className="crxdo-location-detail">{zipCodeMasked}</span>
        )}
      </div>
      {phone && (
        <div className="crxdo-location-detail">
          {language.GENERAL_LABEL_PHONE && (
            <span className="crxdo-location-detail-label">
              {language.GENERAL_LABEL_PHONE}
            </span>
          )}
          <a href={`tel:${phone}`}>{phoneMasked}</a>
        </div>
      )}
      {fax && (
        <div className="crxdo-location-detail">
          {language.GENERAL_LABEL_FAX && (
            <span className="crxdo-location-detail-label">
              {language.GENERAL_LABEL_FAX}
            </span>
          )}
          <a href={`tel:${phone}`}>{faxMasked}</a>
        </div>
      )}
    </address>
  );
};

LocationDetails.propTypes = {
  location: PropTypes.object.isRequired
};

export default LocationDetails;
