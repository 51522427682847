import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './EnrollmentEjectButton.scss';
import { Button, Tooltip } from 'reactstrap';
import { STEP_ACTION_EJECT } from '../../../constants/StepActions';
import SubmitButtonSpinner from '../SubmitButtonSpinner/SubmitButtonSpinner';
import brandConfig from '../../../brand/brand-config.json';
import { isNullOrUndefinedOrEmpty } from '../../../utils';

const EnrollmentEjectButton = ({
  id,
  form,
  submitting,
  disabled,
  stepAction,
  skipSubmit,
  onSkipSubmit,
  userGuid,
  enrollmentData
}) => {
  const [sendToPrimaryTooltipOpen, setSendToPrimaryTooltip] = useState(false);

  if (!id || !form || !stepAction || !enrollmentData) {
    return null;
  }

  const { practiceAdminGuid, submittedToPrimaryContactDate } = enrollmentData;
  const canSkipSubmit = skipSubmit && onSkipSubmit;

  if (
    userGuid === practiceAdminGuid ||
    !isNullOrUndefinedOrEmpty(submittedToPrimaryContactDate)
  ) {
    return null;
  }

  const onEjectClick = () => {
    form.change('updateStepAction', STEP_ACTION_EJECT);

    if (skipSubmit && onSkipSubmit) {
      onSkipSubmit();
    }
  };

  const { language } = brandConfig;

  return (
    <div className="crdo-enrollment-eject-button">
      <Tooltip
        placement="top"
        isOpen={sendToPrimaryTooltipOpen}
        autohide={true}
        target={id}
        className="crdo-enrollment-eject-button-tooltip"
      >
        {language.GENERAL_SEND_TO_PRIMARY_CONTACT_MESSAGE}
      </Tooltip>

      <Button
        id={id}
        type={canSkipSubmit ? 'button' : 'submit'}
        color="secondary"
        className="btn-block-sm-down"
        onMouseOver={() => setSendToPrimaryTooltip(true)}
        onMouseOut={() => setSendToPrimaryTooltip(false)}
        onClick={onEjectClick}
        disabled={disabled || submitting || stepAction === STEP_ACTION_EJECT}
      >
        {stepAction === STEP_ACTION_EJECT ? (
          <SubmitButtonSpinner submitting={stepAction === STEP_ACTION_EJECT} />
        ) : (
          language.GENERAL_SEND_TO_PRIMARY_CONTACT_BTN
        )}
      </Button>
    </div>
  );
};

EnrollmentEjectButton.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  stepAction: PropTypes.string.isRequired,
  skipSubmit: PropTypes.bool,
  onSkipSubmit: PropTypes.func,
  userGuid: PropTypes.string.isRequired,
  enrollmentData: PropTypes.object
};

export default EnrollmentEjectButton;
