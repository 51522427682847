import brandConfig from '../brand/brand-config';


const initAnalytics = (userGuid, enrollmentData) => {
  const { brandId, brandName } = brandConfig;
  const { practiceAdminGuid, salesRepresentativeGuid, enrollmentGuid, initiatorRole} = enrollmentData;
  const pendo = window.pendo;
  if (pendo) {
    pendo.initialize({
      visitor: {
          id: `DO-${userGuid}`,
          enrollmentGuid: `DO-${enrollmentGuid}`,
          practiceAdminGuid: `DO-${practiceAdminGuid}`,
          salesRepresentativeGuid: `DO-${salesRepresentativeGuid}`,
          initiatorRole: `DO-${initiatorRole}`
      },
  
      account: {
          id:   `DO-${brandId}`,
          name: `DO-${brandName}`
      }
    });
  }

}

const initAnalyticsRegistrationPage = () => {
  const { brandId, brandName } = brandConfig;
  const pendo = window.pendo;
  if (pendo) {
    pendo.initialize({
      visitor: {
        id: ''
      },
      account: {
        id:        `DO-${brandId}`,
        name: `DO-${brandName}`
    }
    })
  }
}

export default {
  initAnalytics,
  initAnalyticsRegistrationPage
};
