const defaultAvailableServicesData = {
  serviceOnlineProductOrdering: false,
  serviceInventoryManagement: false,
  serviceFaxOnly: false,
  serviceBenefitsVerificationPortal: false,
  subservices: {
    patientAssistanceProgram: false,
    priorAuthorization: false,
    reverificationServices: false,
    specialtyPharmacy: false
  }
};

export default defaultAvailableServicesData;
