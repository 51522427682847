import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import PracticeDemographics, {
  PRACTICE_DEMOGRAPHICS_ROUTE_NAME
} from '../practice-demographics/PracticeDemographics';
import PracticeLocations, {
  PRACTICE_LOCATIONS_ROUTE_NAME
} from '../practice-locations/PracticeLocations';
import ServicesSelection, {
  SERVICES_SELECTION_ROUTE_NAME
} from '../services-selection/ServicesSelection';
import EjectSuccess, {
  EJECT_SUCCESS_ROUTE_NAME
} from '../eject-success/EjectSuccess';
import EjectStatus, {
  EJECT_STATUS_ROUTE_NAME
} from '../eject-status/EjectStatus';
import EnrollmentSuccess, {
  ENROLLMENT_SUCCESS_ROUTE_NAME
} from '../enrollment-success/EnrollmentSuccess';
import BVPortalDetails, {
  BV_PORTAL_DETAILS_ROUTE_NAME
} from '../bv-portal-details/BVPortalDetails';
import AddPrescribers, {
  ADD_PRESCRIBERS_ROUTE_NAME
} from '../add-prescribers/AddPrescribers';
import AddUsers, { ADD_USERS_ROUTE_NAME } from '../add-users/AddUsers';
import Summary, { SUMMARY_ROUTE_NAME } from '../summary/Summary';
import { OPT_OUT_ROUTE_NAME } from '../opt-out/OptOut';
import { EXPIRED_ROUTE_NAME } from '../expired/Expired';
import BaaSign, { BAA_SIGN_ROUTE_NAME } from '../baa-sign/BaaSign';
import ProgressHeader from '../shared/ProgressHeader/ProgressHeader';
import {
  getISODateString,
  getPercentageComplete,
  isBaaSignatureRequiredForPracticeUser,
  isBaaSignatureCompleted,
  isNullOrUndefinedOrEmpty,
  analytics
} from '../../utils';
import './EnrollmentApp.scss';
import { formatEnrollmentData, formatPatchData } from '../../utils/format';
import {
  STEP_ACTION_ADD_BV_DETAILS,
  STEP_ACTION_ADD_LOCATIONS,
  STEP_ACTION_BACK,
  STEP_ACTION_COMPLETE_ENROLLMENT,
  STEP_ACTION_EJECT,
  STEP_ACTION_NEXT
} from '../../constants/StepActions';
import NotFound from '../not-found/NotFound';
import { patchEnrollment } from '../../api';
import ViewDebug from '../shared/ViewDebug/ViewDebug';
import { ConfigContext } from '../../config';
const defaultAllSteps = [
  {
    stepId: PRACTICE_DEMOGRAPHICS_ROUTE_NAME,
    stepComplete: false
  },
  {
    stepId: PRACTICE_LOCATIONS_ROUTE_NAME,
    stepComplete: false,
    previousStep: PRACTICE_DEMOGRAPHICS_ROUTE_NAME
  },
  {
    stepId: SERVICES_SELECTION_ROUTE_NAME,
    stepComplete: false
  },
  {
    stepId: BV_PORTAL_DETAILS_ROUTE_NAME,
    stepComplete: false,
    previousStep: SERVICES_SELECTION_ROUTE_NAME
  },
  {
    stepId: ADD_PRESCRIBERS_ROUTE_NAME,
    stepComplete: false
  },
  {
    stepId: ADD_USERS_ROUTE_NAME,
    stepComplete: false
  },
  {
    stepId: SUMMARY_ROUTE_NAME,
    stepComplete: false
  }
];

const defaultEnrollmentState = {
  showProgressHeader: true,
  enrollmentDataLoaded: false,
  enrollmentError: null,
  enrollmentDataFromApi: {},
  formattedEnrollmentData: {},
  stepOrder: [
    PRACTICE_DEMOGRAPHICS_ROUTE_NAME,
    SERVICES_SELECTION_ROUTE_NAME,
    ADD_PRESCRIBERS_ROUTE_NAME,
    ADD_USERS_ROUTE_NAME,
    SUMMARY_ROUTE_NAME
  ],
  allSteps: [...defaultAllSteps],
  currentStepId: null,
  progressPercentage: 0
};

class EnrollmentAppView extends Component {
  state = { ...defaultEnrollmentState, apiConfig: this.props.apiConfig };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  updateEnrollmentWizardStepProgress(currentStepId) {
    const { stepOrder } = this.state;
    const currentStepIndex = stepOrder.indexOf(currentStepId);
    const currentStepCount = currentStepIndex + 1;
    const isFirstStep = currentStepIndex === 0;

    const percentageComplete = isFirstStep
      ? 0
      : getPercentageComplete(currentStepCount, stepOrder.length);

    this.setState({
      currentStepId,
      progressPercentage: percentageComplete
    });
  }

  startFromBeginning() {
    const { history, defaultEmptyEnrollmentData } = this.props;

    this.setState({
      ...defaultEnrollmentState,
      enrollmentData: defaultEmptyEnrollmentData
    });

    history.push(`/`);
  }

  startFromStepOne(guid) {
    const { history, defaultEmptyEnrollmentData } = this.props;
    const { stepOrder } = this.state;

    this.setState({
      ...defaultEnrollmentState,
      enrollmentData: defaultEmptyEnrollmentData
    });

    const stepOneId = stepOrder[0];
    if (stepOneId) {
      history.push(`/enrollment/${guid}/${stepOneId}`);
    }
  }

  findStepDetailsForStep(stepId) {
    const { allSteps } = this.state;

    const stepValuesForStep = allSteps.find(step => {
      return step.stepId === stepId;
    });

    return stepValuesForStep;
  }

  updateEnrollmentState(
    updatedStepId,
    stepComplete,
    enrollmentDataFromApi,
    nextStepId
  ) {
    const { location } = this.props;
    const { allSteps } = this.state;
    const { additionalLocations } = enrollmentDataFromApi;
    const { pathname } = location;
    const hasLocations = additionalLocations && additionalLocations.length > 0;
    // Below makes sure that if practice-locations is the intended
    // navigation from any page (submit, browser back, back),
    // updates the stepOrder to include practice-location step
    const isOnPracticeLocationsView =
      nextStepId === PRACTICE_LOCATIONS_ROUTE_NAME ||
      (pathname ? pathname.includes(PRACTICE_LOCATIONS_ROUTE_NAME) : false);

    const stepOrderWithoutLocations = this.getStepOrderMinusSteps([
      PRACTICE_LOCATIONS_ROUTE_NAME
    ]);
    const updatedStepOrder =
      hasLocations || isOnPracticeLocationsView
        ? this.getStepOrderCloneInjection(
            PRACTICE_DEMOGRAPHICS_ROUTE_NAME,
            PRACTICE_LOCATIONS_ROUTE_NAME
          )
        : stepOrderWithoutLocations;

    const updatedAllSteps = allSteps.map(step => {
      return step.stepId === updatedStepId
        ? Object.assign({}, step, { stepComplete })
        : step;
    });
    const formattedEnrollmentData = formatEnrollmentData(enrollmentDataFromApi);
    const showProgressHeader = !isNullOrUndefinedOrEmpty(
      this.findStepDetailsForStep(updatedStepId)
    );

    this.setState(
      {
        allSteps: updatedAllSteps,
        showProgressHeader,
        enrollmentDataLoaded: true,
        enrollmentDataFromApi,
        formattedEnrollmentData,
        stepOrder: updatedStepOrder
      },
      () => {
        this.updateEnrollmentWizardStepProgress(nextStepId || updatedStepId);
      }
    );
    const urlGuid = this.props.match.params.guid;

    analytics.initAnalytics(urlGuid, enrollmentDataFromApi);
  }

  isStepInStepOrder = stepId => {
    const { stepOrder } = this.state;
    return stepOrder.includes(stepId);
  };

  onRequestAddStep = stepIdToAdd => {
    if (this.isStepInStepOrder(stepIdToAdd)) {
      return;
    }
    const stepDetail = this.findStepDetailsForStep(stepIdToAdd);

    if (stepDetail && stepDetail.previousStep) {
      const updatedStepOrder = this.getStepOrderCloneInjection(
        stepDetail.previousStep,
        stepIdToAdd
      );

      this.replaceStepOrder(updatedStepOrder);
    }
  };

  onRequestRemoveStep = stepIdToRemove => {
    if (!this.isStepInStepOrder(stepIdToRemove)) {
      return;
    }

    const updatedStepOrder = this.getStepOrderMinusSteps([stepIdToRemove]);

    this.replaceStepOrder(updatedStepOrder);
  };

  getStepOrderMinusSteps(stepsArray) {
    const { stepOrder } = this.state;

    if (!Array.isArray(stepsArray)) {
      return stepsArray;
    }

    const updatedStepOrder = stepOrder.filter(function(stepId) {
      return !stepsArray.includes(stepId);
    });

    return updatedStepOrder;
  }

  getStepOrderCloneInjection(previousStepId, stepIdToAdd) {
    const { stepOrder } = this.state;

    if (this.isStepInStepOrder(stepIdToAdd)) {
      return stepOrder;
    }

    const previousStepIndex = stepOrder.indexOf(previousStepId);

    const updatedStepOrder = [...stepOrder];

    if (previousStepIndex > -1) {
      updatedStepOrder.splice(previousStepIndex + 1, 0, stepIdToAdd);
    }

    return updatedStepOrder;
  }

  replaceStepOrder(stepOrder, onStepOrderReplace) {
    this.setState({ stepOrder }, onStepOrderReplace);
  }

  onStepBackwards = (fromStepId, guid, removeStep = false) => {
    const { history } = this.props;
    const { stepOrder } = this.state;
    const previousStepIndex = stepOrder.indexOf(fromStepId);
    const previousStepId = stepOrder[previousStepIndex - 1];

    const toStepBackRouteOnComplete = toStepId => {
      this.updateEnrollmentWizardStepProgress(toStepId);

      history.push(`/enrollment/${guid}/${toStepId}`);
    };

    if (previousStepId) {
      if (removeStep) {
        const updatedStepOrder = this.getStepOrderMinusSteps([fromStepId]);

        this.replaceStepOrder(updatedStepOrder, () => {
          toStepBackRouteOnComplete(previousStepId);
        });
      } else {
        toStepBackRouteOnComplete(previousStepId);
      }
    } else {
      const firstStepId = stepOrder[0];
      toStepBackRouteOnComplete(firstStepId);
    }
  };

  onSkipStep = (fromStepId, guid, removeStep = false) => {
    const { history } = this.props;
    const { stepOrder } = this.state;
    const previousStepIndex = stepOrder.indexOf(fromStepId);
    const nextStepId = stepOrder[previousStepIndex + 1];

    const toStepBackRouteOnComplete = toStepId => {
      this.updateEnrollmentWizardStepProgress(toStepId);

      history.push(`/enrollment/${guid}/${toStepId}`);
    };

    if (nextStepId) {
      if (removeStep) {
        const updatedStepOrder = this.getStepOrderMinusSteps([fromStepId]);

        this.replaceStepOrder(updatedStepOrder, () => {
          toStepBackRouteOnComplete(nextStepId);
        });
      } else {
        toStepBackRouteOnComplete(nextStepId);
      }
    }
  };

  checkForCompletedForm = enrollmentData => {
    return (
      (isBaaSignatureRequiredForPracticeUser &&
        isBaaSignatureCompleted &&
        !isNullOrUndefinedOrEmpty(enrollmentData.signatureEvent)) ||
      !isNullOrUndefinedOrEmpty(enrollmentData.submittedToCareformDate)
    );
  };

  onStepComplete = (
    stepId,
    guid,
    stepAction,
    enrollmentDataFromApi,
    config
  ) => {
    const { history } = this.props;
    const { stepOrder } = this.state;
    const fromStepIndex = stepOrder.indexOf(stepId);
    const toStepRouteOnComplete = (nextStepId, toRoute) => {
      this.onEnrollmentDataUpdated(
        stepId,
        guid,
        true,
        enrollmentDataFromApi,
        nextStepId
      );

      history.push(toRoute);
    };

    if (stepAction === STEP_ACTION_NEXT) {
      if (!isNaN(fromStepIndex) && fromStepIndex > -1) {
        const nextStepId = stepOrder[fromStepIndex + 1];
        if (nextStepId) {
          toStepRouteOnComplete(
            nextStepId,
            `/enrollment/${guid}/${nextStepId}`
          );
        }
      }
    } else if (stepAction === STEP_ACTION_BACK) {
      if (!isNaN(fromStepIndex) && fromStepIndex > -1) {
        const previousStepId = stepOrder[fromStepIndex - 1];
        if (previousStepId) {
          toStepRouteOnComplete(
            previousStepId,
            `/enrollment/${guid}/${previousStepId}`
          );
        }
      }
    } else if (stepAction === STEP_ACTION_ADD_LOCATIONS) {
      const updatedStepOrder = this.getStepOrderCloneInjection(
        stepId,
        PRACTICE_LOCATIONS_ROUTE_NAME
      );

      this.replaceStepOrder(updatedStepOrder, () => {
        toStepRouteOnComplete(
          PRACTICE_LOCATIONS_ROUTE_NAME,
          `/enrollment/${guid}/${PRACTICE_LOCATIONS_ROUTE_NAME}`
        );
      });
    } else if (stepAction === STEP_ACTION_ADD_BV_DETAILS) {
      const updatedStepOrder = this.getStepOrderCloneInjection(
        stepId,
        BV_PORTAL_DETAILS_ROUTE_NAME
      );

      this.replaceStepOrder(updatedStepOrder, () => {
        toStepRouteOnComplete(
          BV_PORTAL_DETAILS_ROUTE_NAME,
          `/enrollment/${guid}/${BV_PORTAL_DETAILS_ROUTE_NAME}`
        );
      });
    } else if (stepAction === STEP_ACTION_EJECT) {
      toStepRouteOnComplete(
        STEP_ACTION_EJECT,
        `/enrollment/${guid}/${EJECT_SUCCESS_ROUTE_NAME}`
      );
    } else if (stepAction === STEP_ACTION_COMPLETE_ENROLLMENT) {
      const baaSignatureRequired = isBaaSignatureRequiredForPracticeUser(
        enrollmentDataFromApi,
        guid,
        config
      );
      const baaaSignatureCompleted = isBaaSignatureCompleted(
        enrollmentDataFromApi
      );

      toStepRouteOnComplete(
        STEP_ACTION_COMPLETE_ENROLLMENT,
        `/enrollment/${guid}/${
          baaSignatureRequired && !baaaSignatureCompleted
            ? BAA_SIGN_ROUTE_NAME
            : ENROLLMENT_SUCCESS_ROUTE_NAME
        }`
      );
    }
  };

  setEnrollmentError(enrollmentError) {
    this.setState({ enrollmentError });
  }

  onClearEnrollmentError = () => {
    this.setEnrollmentError(null);
  };

  onShowEjectStatus = guid => {
    const { history } = this.props;

    history.push(`/enrollment/${guid}/${EJECT_STATUS_ROUTE_NAME}`);
  };

  onEjectEnrollment = (
    stepId,
    userGuid,
    enrollmentGuid,
    stepAction,
    config
  ) => {
    const todayIsoDate = getISODateString();
    const ejectionData = formatPatchData(
      {
        submittedToPrimaryContactDate: todayIsoDate
      },
      ''
    );

    const onHandleEjectPatchSuccess = apiValues => {
      this.onStepComplete(stepId, userGuid, STEP_ACTION_EJECT, apiValues);
    };

    const onHandleEjectPatchFailure = error => {
      this.setEnrollmentError(error);
    };

    return patchEnrollment(ejectionData, enrollmentGuid, config)
      .then(onHandleEjectPatchSuccess)
      .catch(onHandleEjectPatchFailure);
  };

  onCompleteEnrollment = (stepId, userGuid, enrollmentDataFromApi, config) => {
    this.onStepComplete(
      stepId,
      userGuid,
      STEP_ACTION_COMPLETE_ENROLLMENT,
      enrollmentDataFromApi,
      config
    );
  };

  onEnrollmentDataUpdated = (
    stepId,
    guid,
    stepComplete,
    enrollmentDataFromApi,
    nextStepId
  ) => {
    this.updateEnrollmentState(
      stepId,
      stepComplete,
      enrollmentDataFromApi,
      nextStepId
    );
  };

  onShowOptOut = () => {
    const { history } = this.props;

    history.push(`/${OPT_OUT_ROUTE_NAME}`);
  };

  onShowExpired = () => {
    const { history } = this.props;

    history.push(`/${EXPIRED_ROUTE_NAME}`);
  };

  onResetEnrollment = () => {
    this.startFromBeginning();
  };

  onRequestEnrollmentStep = (toGuid, toStepId) => {
    const { history } = this.props;
    this.updateEnrollmentWizardStepProgress(toStepId);

    history.push(`/enrollment/${toGuid}/${toStepId}`);
  };

  onRequestEnrollmentStart = toGuid => {
    if (toGuid) {
      this.startFromStepOne(toGuid);
    } else {
      this.startFromBeginning();
    }
  };

  render() {
    const { location, match, history } = this.props;

    const {
      progressPercentage,
      showProgressHeader,
      enrollmentDataLoaded,
      enrollmentError,
      formattedEnrollmentData,
      stepOrder,
      allSteps,
      currentStepId
    } = this.state;

    const currentStepDetails = this.findStepDetailsForStep(currentStepId);

    return (
      <ConfigContext.Consumer>
        {({ config }) => (
          <div className="crxdo-enrollment-app">
            {showProgressHeader && enrollmentDataLoaded && (
              <ProgressHeader
                id="crxdo-enrollment-app_progress-header"
                progressPercentage={progressPercentage}
                progressTitle="Registration Progress"
              />
            )}
            <div className="crxdo-enrollment-app-routes">
              <Switch>
                <Route
                  exact
                  path={`/enrollment/:guid/${PRACTICE_DEMOGRAPHICS_ROUTE_NAME}`}
                  render={props => (
                    <PracticeDemographics
                      {...props}
                      onStepComplete={this.onStepComplete}
                      onResetEnrollment={this.onResetEnrollment}
                      onEjectEnrollment={this.onEjectEnrollment}
                      onEnrollmentDataUpdated={this.onEnrollmentDataUpdated}
                      enrollmentData={formattedEnrollmentData}
                      stepDetails={currentStepDetails}
                      enrollmentError={enrollmentError}
                      onClearEnrollmentError={this.onClearEnrollmentError}
                      onShowOptOut={this.onShowOptOut}
                      onShowExpired={this.onShowExpired}
                      onShowEjectStatus={this.onShowEjectStatus}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${PRACTICE_LOCATIONS_ROUTE_NAME}`}
                  render={props => (
                    <PracticeLocations
                      {...props}
                      onStepComplete={this.onStepComplete}
                      onStepBackwards={this.onStepBackwards}
                      onSkipStep={this.onSkipStep}
                      onEjectEnrollment={this.onEjectEnrollment}
                      onRequestEnrollmentStart={this.onRequestEnrollmentStart}
                      onEnrollmentDataUpdated={this.onEnrollmentDataUpdated}
                      enrollmentData={formattedEnrollmentData}
                      stepDetails={currentStepDetails}
                      enrollmentError={enrollmentError}
                      onClearEnrollmentError={this.onClearEnrollmentError}
                      isStepAdded={this.isStepInStepOrder(
                        PRACTICE_LOCATIONS_ROUTE_NAME
                      )}
                      onRequestAddStep={this.onRequestAddStep}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${SERVICES_SELECTION_ROUTE_NAME}`}
                  render={props => (
                    <ServicesSelection
                      {...props}
                      onStepComplete={this.onStepComplete}
                      onStepBackwards={this.onStepBackwards}
                      onEjectEnrollment={this.onEjectEnrollment}
                      onRequestEnrollmentStart={this.onRequestEnrollmentStart}
                      onRequestAddStep={this.onRequestAddStep}
                      onRequestRemoveStep={this.onRequestRemoveStep}
                      enrollmentData={formattedEnrollmentData}
                      stepDetails={currentStepDetails}
                      enrollmentError={enrollmentError}
                      onClearEnrollmentError={this.onClearEnrollmentError}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${BV_PORTAL_DETAILS_ROUTE_NAME}`}
                  render={props => (
                    <BVPortalDetails
                      {...props}
                      onStepComplete={this.onStepComplete}
                      onStepBackwards={this.onStepBackwards}
                      onEjectEnrollment={this.onEjectEnrollment}
                      onRequestEnrollmentStart={this.onRequestEnrollmentStart}
                      enrollmentData={formattedEnrollmentData}
                      stepDetails={currentStepDetails}
                      enrollmentError={enrollmentError}
                      onClearEnrollmentError={this.onClearEnrollmentError}
                      isStepAdded={this.isStepInStepOrder(
                        BV_PORTAL_DETAILS_ROUTE_NAME
                      )}
                      onRequestAddStep={this.onRequestAddStep}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${ADD_PRESCRIBERS_ROUTE_NAME}`}
                  render={props => (
                    <AddPrescribers
                      {...props}
                      onStepComplete={this.onStepComplete}
                      onStepBackwards={this.onStepBackwards}
                      onSkipStep={this.onSkipStep}
                      onEjectEnrollment={this.onEjectEnrollment}
                      onRequestEnrollmentStart={this.onRequestEnrollmentStart}
                      onEnrollmentDataUpdated={this.onEnrollmentDataUpdated}
                      enrollmentData={formattedEnrollmentData}
                      stepDetails={currentStepDetails}
                      enrollmentError={enrollmentError}
                      onClearEnrollmentError={this.onClearEnrollmentError}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${ADD_USERS_ROUTE_NAME}`}
                  render={props => (
                    <AddUsers
                      {...props}
                      onStepComplete={this.onStepComplete}
                      onStepBackwards={this.onStepBackwards}
                      onSkipStep={this.onSkipStep}
                      onEjectEnrollment={this.onEjectEnrollment}
                      onRequestEnrollmentStart={this.onRequestEnrollmentStart}
                      onEnrollmentDataUpdated={this.onEnrollmentDataUpdated}
                      enrollmentData={formattedEnrollmentData}
                      stepDetails={currentStepDetails}
                      enrollmentError={enrollmentError}
                      onClearEnrollmentError={this.onClearEnrollmentError}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${SUMMARY_ROUTE_NAME}`}
                  render={props => (
                    <Summary
                      {...props}
                      onCompleteEnrollment={this.onCompleteEnrollment}
                      onStepComplete={this.onStepComplete}
                      onStepBackwards={this.onStepBackwards}
                      onRequestEnrollmentStart={this.onRequestEnrollmentStart}
                      onRequestEnrollmentStep={this.onRequestEnrollmentStep}
                      onEjectEnrollment={this.onEjectEnrollment}
                      enrollmentData={formattedEnrollmentData}
                      enrollmentError={enrollmentError}
                      onClearEnrollmentError={this.onClearEnrollmentError}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${EJECT_SUCCESS_ROUTE_NAME}`}
                  render={props => (
                    <EjectSuccess
                      {...props}
                      onResetEnrollment={this.onResetEnrollment}
                      onEnrollmentDataUpdated={this.onEnrollmentDataUpdated}
                      enrollmentData={formattedEnrollmentData}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${EJECT_STATUS_ROUTE_NAME}`}
                  render={props => (
                    <EjectStatus
                      {...props}
                      onResetEnrollment={this.onResetEnrollment}
                      onEnrollmentDataUpdated={this.onEnrollmentDataUpdated}
                      enrollmentData={formattedEnrollmentData}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${ENROLLMENT_SUCCESS_ROUTE_NAME}`}
                  render={props => (
                    <EnrollmentSuccess
                      {...props}
                      onResetEnrollment={this.onResetEnrollment}
                      onEnrollmentDataUpdated={this.onEnrollmentDataUpdated}
                      enrollmentData={formattedEnrollmentData}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/enrollment/:guid/${BAA_SIGN_ROUTE_NAME}`}
                  render={props => (
                    <BaaSign
                      {...props}
                      onResetEnrollment={this.onResetEnrollment}
                      onEnrollmentDataUpdated={this.onEnrollmentDataUpdated}
                      onCompleteEnrollment={this.onCompleteEnrollment}
                      enrollmentData={formattedEnrollmentData}
                      enrollmentError={enrollmentError}
                      onClearEnrollmentError={this.onClearEnrollmentError}
                    />
                  )}
                />
                <Route render={NotFound} />
              </Switch>

              <ViewDebug className="crxdo-view-debug-bottom-left">
                {enrollmentDataLoaded &&
                  match.params['guid'] &&
                  defaultAllSteps && (
                    <div className="crxdo-form-buttons crxdo-form-buttons-left crxdo-form-buttons-wrap">
                      <div className="crxdo-form-btn-container">
                        {defaultAllSteps.map(stepToLink => {
                          const { stepId } = stepToLink;
                          return (
                            <Button
                              key={`${stepId}-button`}
                              type="button"
                              color="brand-1"
                              className="btn-sm"
                              onClick={() => {
                                this.onRequestEnrollmentStep(
                                  match.params['guid'],
                                  stepId
                                );
                              }}
                            >
                              {stepId}
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  )}

                <div className="h4" role="heading">
                  Current Application Values (currentStepId = {currentStepId})
                </div>
                <div className="mb-2">
                  <span className="font-weight-bold">stepOrder</span>=
                  {JSON.stringify(stepOrder)}
                </div>
                <div className="mb-2">
                  <span className="font-weight-bold">allSteps</span>=
                  {JSON.stringify(allSteps)}
                </div>
                <div className="mb-2">
                  <span className="font-weight-bold">
                    formattedEnrollmentData
                  </span>
                  ={JSON.stringify(formattedEnrollmentData)}
                </div>
                <div className="h4" role="heading">
                  Route Info
                </div>
                <div className="mb-2">
                  <span className="font-weight-bold">location</span>=
                  {JSON.stringify(location)}
                </div>
                <div className="mb-2">
                  <span className="font-weight-bold">match</span>=
                  {JSON.stringify(match)}
                </div>
                <div className="mb-2">
                  <span className="font-weight-bold">history</span>=
                  {JSON.stringify(history)}
                </div>
              </ViewDebug>
            </div>
          </div>
        )}
      </ConfigContext.Consumer>
    );
  }
}

const EnrollmentApp = withRouter(EnrollmentAppView);

export default EnrollmentApp;
