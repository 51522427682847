import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, FormGroup, Row } from 'reactstrap';
import formatString from 'format-string-by-pattern';
import brandConfig from '../../brand/brand-config';
import './PracticeLocations.scss';
import { postLocation, patchLocation, deleteLocation } from '../../api/index';
import { useConfig } from '../../config';
import {
  getUnitedStates,
  isNullOrUndefinedOrEmpty,
  useMount,
} from '../../utils';
import {
  composeFieldValidators,
  FIELD_REQUIRED_ERROR,
  validateFieldPhone,
  validateFieldRequired,
  validateFieldZip
} from '../../utils/form';
import { formatLocationData, stripNonDigits } from '../../utils/format';
import { zipMask, phoneMask } from '../../constants/InputMasks';
import {
  STEP_ACTION_NEXT,
  STEP_ACTION_ADD_NEW_LOCATION,
  STEP_ACTION_UPDATE_LOCATION,
  STEP_ACTION_EJECT,
  STEP_ACTION_BACK
} from '../../constants/StepActions';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import BrandFooter from '../shared/BrandFooter/BrandFooter';
import AlertError from '../shared/AlertError/AlertError';
// import editIcon from "../../images/edit.svg";
import deleteIcon from '../../images/delete.svg';
import ValidateInputText from '../shared/ValidateInputText/ValidateInputText';
import ValidateSelect from '../shared/ValidateSelect/ValidateSelect';
import EnrollmentEjectButton from '../shared/EnrollmentEjectButton/EnrollmentEjectButton';
import ViewDebug from '../shared/ViewDebug/ViewDebug';
import LocationHighlights from '../shared/LocationHighlights/LocationHighlights';

export const PRACTICE_LOCATIONS_ROUTE_NAME = 'practice-locations';

const NEW_LOCATION_ID = null;

const defaultNewLocation = { locationId: NEW_LOCATION_ID };

export const PracticeLocationsView = ({
  enrollmentData,
  stepDetails,
  match,
  isStepAdded,
  onRequestAddStep,
  onRequestEnrollmentStart,
  onEjectEnrollment,
  onStepBackwards,
  onSkipStep,
  onStepComplete,
  onEnrollmentDataUpdated,
  enrollmentError,
  onClearEnrollmentError
}) => {
  const config = useConfig();
  const [error, setError] = useState(null);
  const [currentUserGuid, setCurrentUserGuid] = useState(null);
  const [enrollmentGuid, setEnrollmentGuid] = useState(null);
  const [formattedCurrentLocation, setFormattedCurrentLocation] = useState({});
  const [currentLocationIndex, setCurrentLocationIndex] = useState(0);
  const [
    formattedAdditionalLocations,
    setFormattedAdditionalLocations
  ] = useState([]);
  const [stepReady, setStepReady] = useState(false);
  const [stepAction, setStepAction] = useState(STEP_ACTION_NEXT);

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const {
      enrollmentGuid,
      salesRepresentativeGuid,
      practiceAdminGuid
    } = enrollmentData;

    const hasNoGuid = isNullOrUndefinedOrEmpty(guid);
    const hasNoEnrollmentData =
      !enrollmentGuid && !salesRepresentativeGuid && !practiceAdminGuid;

    if (
      hasNoGuid ||
      hasNoEnrollmentData ||
      (guid !== salesRepresentativeGuid && guid !== practiceAdminGuid)
    ) {
      onRequestEnrollmentStart(guid);
    } else {
      initializeEnrollmentDataStateForLocations(guid);
    }
  });

  const initializeEnrollmentDataStateForLocations = guid => {
    const { enrollmentGuid, additionalLocations } = enrollmentData;
    const initCurrentLocationIndex =
      additionalLocations && additionalLocations.length > 0
        ? additionalLocations.length
        : 0;

    const initFormattedAdditionalLocations = additionalLocations.map(
      additionalLocation => {
        return formatLocationData(additionalLocation);
      }
    );

    const initFormattedCurrentLocation = initFormattedAdditionalLocations[
      initCurrentLocationIndex
    ] || { ...defaultNewLocation };

    setEnrollmentGuid(enrollmentGuid);
    setCurrentUserGuid(guid);
    setCurrentLocationIndex(initCurrentLocationIndex);
    setFormattedCurrentLocation(initFormattedCurrentLocation);
    setFormattedAdditionalLocations(initFormattedAdditionalLocations);

    setTimeout(() => {
      setStepReady(true);
    }, 300);

    if (!isStepAdded) {
      onRequestAddStep(PRACTICE_LOCATIONS_ROUTE_NAME);
    }
  };

  const isStepComplete = locationsArray => {
    return Array.isArray(locationsArray) && locationsArray.length > 0;
  };

  const getUpdatedEnrollmentData = updatedAdditionalLocations => {
    const updatedEnrollmentData = Object.assign(
      { ...enrollmentData },
      { additionalLocations: updatedAdditionalLocations }
    );

    return updatedEnrollmentData;
  };

  // TODO: Use for edit.
  // handleSetCurrentLocationByIndex = currentLocationIndex => {
  //   const { formattedAdditionalLocations } = this.state;
  //   const formattedCurrentLocation =
  //     formattedAdditionalLocations[currentLocationIndex];
  //
  //   this.setState({
  //     currentLocationIndex,
  //     formattedCurrentLocation,
  //     formattedAdditionalLocations
  //   });
  // };

  const onLocationsUpdated = updatedLocations => {
    const updatedAdditionalLocations = updatedLocations.map(
      additionalLocation => {
        return additionalLocation;
      }
    );
    const updatedFormattedAdditionalLocations = updatedAdditionalLocations.map(
      updatedAdditionalLocation => {
        return formatLocationData(updatedAdditionalLocation);
      }
    );
    const updatedEnrollmentData = getUpdatedEnrollmentData(
      updatedAdditionalLocations
    );
    const stepCompleteOnLocationsChange = isStepComplete(
      updatedAdditionalLocations
    );
    setFormattedAdditionalLocations(updatedFormattedAdditionalLocations);
    onEnrollmentDataUpdated(
      PRACTICE_LOCATIONS_ROUTE_NAME,
      currentUserGuid,
      stepCompleteOnLocationsChange,
      updatedEnrollmentData
    );
  };

  const handleDeleteLocationById = async locationId => {
    const onHandleDeleteSuccess = apiValues => {
      if (apiValues && Array.isArray(apiValues)) {
        const updatedCurrentLocationIndex = currentLocationIndex - 1;
        onLocationsUpdated(apiValues);
        setCurrentLocationIndex(updatedCurrentLocationIndex);
      }
    };

    const onHandleDeleteFailure = error => {
      setError(error);
    };

    return await deleteLocation(enrollmentGuid, locationId, config)
      .then(onHandleDeleteSuccess)
      .catch(onHandleDeleteFailure);
  };

  const handleStepBackwards = form => {
    const removeStep = formattedAdditionalLocations.length < 1;

    if (form && form.reset) {
      form.reset();
    }

    onStepBackwards(PRACTICE_LOCATIONS_ROUTE_NAME, currentUserGuid, removeStep);
  };

  const handleSkipStep = form => {
    const removeStep = formattedAdditionalLocations.length < 1;

    if (form && form.reset) {
      form.reset();
    }

    onSkipStep(PRACTICE_LOCATIONS_ROUTE_NAME, currentUserGuid, removeStep);
  };

  const handleStepSubmit = async (formValues, form) => {
    const {
      phoneMasked,
      faxMasked,
      zipCodeMasked,
      locationId,
      updateStepAction
    } = formValues;
    const isLocationNew =
      isNullOrUndefinedOrEmpty(locationId) || locationId === NEW_LOCATION_ID;

    setError(null);
    setStepAction(updateStepAction);
    onClearEnrollmentError();

    const maskRemovedValues = {
      phone: stripNonDigits(phoneMasked),
      fax: stripNonDigits(faxMasked),
      zipCode: stripNonDigits(zipCodeMasked)
    };
    const formValuesToApi = Object.assign(formValues, maskRemovedValues);

    const onHandleSubmitFailure = error => {
      setError(error);
      setStepAction(STEP_ACTION_NEXT);
    };

    const onHandleSubmitSuccess = apiValues => {

      const { additionalLocations } = enrollmentData;

      const updatedAdditionalLocations = [...additionalLocations];

      if (isLocationNew) {
        updatedAdditionalLocations.push(apiValues);
      } else {
        // FIXME: update edited location with apiValues
      }

      const updatedEnrollmentData = getUpdatedEnrollmentData(
        updatedAdditionalLocations
      );

      if (
        (updateStepAction === STEP_ACTION_ADD_NEW_LOCATION ||
          updateStepAction === STEP_ACTION_UPDATE_LOCATION) &&
        updatedAdditionalLocations &&
        Array.isArray(updatedAdditionalLocations)
      ) {
        const updatedCurrentLocationIndex = currentLocationIndex + 1;
        onLocationsUpdated(updatedAdditionalLocations);
        setCurrentLocationIndex(updatedCurrentLocationIndex);
        setFormattedCurrentLocation({ ...defaultNewLocation });
      } else if (updateStepAction === STEP_ACTION_EJECT) {
        onEjectEnrollment(
          PRACTICE_LOCATIONS_ROUTE_NAME,
          currentUserGuid,
          enrollmentGuid,
          updateStepAction,
          config
        );
      } else {
        onStepComplete(
          PRACTICE_LOCATIONS_ROUTE_NAME,
          currentUserGuid,
          updateStepAction,
          updatedEnrollmentData
        );
      }

      form.reset();

      return true;
    };

    return isLocationNew
      ? await postLocation(formValuesToApi, enrollmentGuid, config)
          .then(onHandleSubmitSuccess)
          .catch(onHandleSubmitFailure)
      : await patchLocation(formValuesToApi, enrollmentGuid, locationId, config)
          .then(onHandleSubmitSuccess)
          .catch(onHandleSubmitFailure);
  };

  const canSkipNewLocationValidation = formValues => {
    let canSkipValidation = false;

    if (formValues.locationId === NEW_LOCATION_ID) {
      const formValuesKeys = Object.keys(formValues);
      canSkipValidation =
        !formValuesKeys.includes('locationName') &&
        !formValuesKeys.includes('addressLine1') &&
        !formValuesKeys.includes('addressLine2') &&
        !formValuesKeys.includes('phoneMasked') &&
        !formValuesKeys.includes('faxMasked') &&
        !formValuesKeys.includes('city') &&
        !formValuesKeys.includes('state') &&
        !formValuesKeys.includes('zipCodeMasked');
    }

    return canSkipValidation;
  };

  const onSkipSubmitEject = async () => {
    await onEjectEnrollment(
      PRACTICE_LOCATIONS_ROUTE_NAME,
      currentUserGuid,
      enrollmentGuid,
      STEP_ACTION_EJECT,
      config
    );
  };

  const render = () => {
    const { stepComplete } = stepDetails || {};
    const enrollmentParams = match['params'];
    const { language, portalName } = brandConfig;

    if (!stepReady || !formattedCurrentLocation) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    const isNewLocation =
      formattedCurrentLocation.locationId === NEW_LOCATION_ID;

    return (
      <div className="crxdo-practice-locations">
        <div className="container">
          <h1 className="h2 text-primary crxdo-heading">
            {language.PRACTICE_LOCATIONS_TITLE}
          </h1>
        </div>
        <div className="crxdo-form-top-notes pt-2 px-2 pb-3">
          <div className="container crxdo-body">
            <p>
              {language.PRACTICE_LOCATIONS_FORM_NOTES} {portalName}.
            </p>
          </div>
        </div>

        <Form
          validateOnBlur={false}
          initialValues={formattedCurrentLocation}
          onSubmit={handleStepSubmit}
          validate={formValidatePracticeLocations}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            const canSkipValidation = canSkipNewLocationValidation(values);

            return (
              <form
                onSubmit={handleSubmit}
                className="crxdo-form"
                autoComplete="off"
                noValidate={true}
              >
                <div className="container">
                  {(error || enrollmentError) && (
                    <AlertError
                      error={error || enrollmentError}
                      scrollToError={true}
                    />
                  )}

                  <div className="crxdo-form-card card">
                    <div
                      className="crxdo-form-card-header card-header crxdo-heading"
                      role="heading"
                    >
                      {language.PRACTICE_LOCATIONS_SUB_TITLE}
                    </div>

                    <div className="crxdo-practice-location-highlights">
                      {formattedAdditionalLocations &&
                        formattedAdditionalLocations.map(
                          (additionalLocation, index) => {
                            return (
                              <div
                                className="crxdo-practice-location-highlight"
                                key={additionalLocation.locationId}
                              >
                                <span className="crxdo-form-item-badge badge badge-pill badge-medium">
                                  {index + 1}
                                </span>

                                <LocationHighlights
                                  location={additionalLocation}
                                />

                                <div className="crxdo-practice-location-highlight-actions">
                                  {/*TODO: Use for edit.*/}
                                  {/*<button*/}
                                  {/*  type="button"*/}
                                  {/*  className="crxdo-practice-location-highlight-action"*/}
                                  {/*  onClick={() =>*/}
                                  {/*    this.handleSetCurrentLocationByIndex(index)*/}
                                  {/*  }*/}
                                  {/*  title={*/}
                                  {/*    language.GENERAL_EDIT_BTN*/}
                                  {/*  }*/}
                                  {/*  aria-label={*/}
                                  {/*    language.GENERAL_EDIT_BTN*/}
                                  {/*  }*/}
                                  {/*>*/}
                                  {/*  <img*/}
                                  {/*    src={editIcon}*/}
                                  {/*    alt={*/}
                                  {/*      language.GENERAL_EDIT_BTN*/}
                                  {/*    }*/}
                                  {/*  />*/}
                                  {/*</button>*/}
                                  <button
                                    type="button"
                                    className="crxdo-practice-location-highlight-action"
                                    title={language.GENERAL_DELETE_BTN}
                                    aria-label={language.GENERAL_DELETE_BTN}
                                    onClick={() =>
                                      handleDeleteLocationById(
                                        additionalLocation.locationId
                                      )
                                    }
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt={language.GENERAL_DELETE_BTN}
                                    />
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        )}

                      <div className="crxdo-practice-location-highlight">
                        <span className="crxdo-form-item-badge badge badge-pill badge-medium">
                          {currentLocationIndex + 1}
                        </span>
                      </div>
                    </div>

                    <div className="card-body">
                      <Row className="pb-4">
                        <div className="col-12 col-lg-6">
                          <FormGroup>
                            <ValidateInputText
                              id="locationName"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_LOCATION_NAME"
                              placeHolderTextLanguageKey="GENERAL_FIELD_LOCATION_NAME_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              maxLength={150}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                      </Row>

                      <Row className="pb-4">
                        <div className="col-12 col-md-6">
                          <FormGroup>
                            <ValidateInputText
                              id="addressLine1"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_ADDRESS_1"
                              placeHolderTextLanguageKey="GENERAL_FIELD_ADDRESS_1_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              maxLength={50}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-6">
                          <FormGroup>
                            <ValidateInputText
                              id="addressLine2"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_ADDRESS_2"
                              placeHolderTextLanguageKey="GENERAL_FIELD_ADDRESS_2_PLACEHOLDER"
                              maxLength={50}
                              required={false}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-12 col-md-4 col-lg-4">
                          <FormGroup>
                            <ValidateInputText
                              id="city"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_CITY"
                              placeHolderTextLanguageKey="GENERAL_FIELD_CITY_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              maxLength={35}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3">
                          <FormGroup>
                            <ValidateSelect
                              id="state"
                              labelTextLanguageKey="GENERAL_FIELD_STATE"
                              placeHolderTextLanguageKey="GENERAL_FIELD_STATE_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              autoComplete="none"
                              required={true}
                              options={getUnitedStates()}
                              optionNameKey="name"
                              optionValueKey="code"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-2 col-lg-2">
                          <FormGroup>
                            <ValidateInputText
                              id="zipCodeMasked"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_ZIP"
                              placeHolderTextLanguageKey="GENERAL_FIELD_ZIP_PLACEHOLDER"
                              parse={formatString(zipMask.parse)}
                              validate={composeFieldValidators(
                                validateFieldRequired,
                                validateFieldZip
                              )}
                              minLength={5}
                              maxLength={5}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                      </Row>

                      <Row className="pb-4">
                        <div className="col-12 col-md-4 col-lg-4">
                          <FormGroup>
                            <ValidateInputText
                              id="phoneMasked"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_PHONE"
                              placeHolderTextLanguageKey="GENERAL_FIELD_PHONE_PLACEHOLDER"
                              parse={formatString(phoneMask.parse)}
                              validate={composeFieldValidators(
                                validateFieldRequired,
                                validateFieldPhone
                              )}
                              maxLength={14}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                          <FormGroup>
                            <ValidateInputText
                              id="faxMasked"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_FAX"
                              placeHolderTextLanguageKey="GENERAL_FIELD_FAX_PLACEHOLDER"
                              parse={formatString(phoneMask.parse)}
                              validate={composeFieldValidators(
                                validateFieldRequired,
                                validateFieldPhone
                              )}
                              maxLength={14}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                      </Row>

                      <div className="crxdo-form-buttons crxdo-form-buttons-left">
                        <div className="crxdo-form-btn-container">
                          <Button
                            type="button"
                            outline
                            color="secondary"
                            className="btn-block-sm-down crxdo-form-btn-auto-wide"
                            onClick={form.reset}
                            disabled={submitting}
                          >
                            {language.GENERAL_CANCEL_BTN}
                          </Button>

                          {isNewLocation ? (
                            <Button
                              type="submit"
                              color="brand-2"
                              className="btn-block-sm-down"
                              onClick={() => {
                                form.change(
                                  'updateStepAction',
                                  STEP_ACTION_ADD_NEW_LOCATION
                                );
                              }}
                              disabled={
                                (stepComplete ? false : pristine) || submitting
                              }
                            >
                              {submitting &&
                              stepAction === STEP_ACTION_ADD_NEW_LOCATION ? (
                                <SubmitButtonSpinner submitting={submitting} />
                              ) : (
                                language.GENERAL_ADD_ANOTHER_LOCATION_BTN
                              )}
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              color="brand-2"
                              className="btn-block-sm-down"
                              onClick={() => {
                                form.change(
                                  'updateStepAction',
                                  STEP_ACTION_UPDATE_LOCATION
                                );
                              }}
                              disabled={
                                (stepComplete ? false : pristine) || submitting
                              }
                            >
                              {submitting &&
                              stepAction === STEP_ACTION_UPDATE_LOCATION ? (
                                <SubmitButtonSpinner submitting={submitting} />
                              ) : (
                                language.GENERAL_UPDATE_LOCATION_BTN
                              )}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="crxdo-form-buttons crxdo-form-buttons-border-tb crxdo-form-buttons-stretch">
                  <div className="container crxdo-form-btn-container">
                    <Button
                      type={canSkipValidation ? 'button' : 'submit'}
                      color="brand-1"
                      className="btn-block-sm-down crxdo-form-btn-last"
                      onClick={
                        canSkipValidation
                          ? () => handleSkipStep(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_NEXT);
                            }
                      }
                      disabled={
                        (stepComplete || isNewLocation ? false : pristine) ||
                        submitting
                      }
                    >
                      {submitting && stepAction === STEP_ACTION_NEXT ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_SAVE_AND_CONTINUE_BTN
                      )}
                    </Button>

                    <Button
                      type={canSkipValidation ? 'button' : 'submit'}
                      outline
                      color="secondary"
                      className="btn-block-sm-down crxdo-form-btn-auto-wide crxdo-form-btn-left"
                      onClick={
                        canSkipValidation
                          ? () => handleStepBackwards(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_BACK);
                            }
                      }
                      disabled={submitting}
                    >
                      {submitting && stepAction === STEP_ACTION_BACK ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_BACK_BTN
                      )}
                    </Button>

                    <EnrollmentEjectButton
                      id="practice-locations-send-to-primary-button"
                      skipSubmit={canSkipValidation}
                      onSkipSubmit={onSkipSubmitEject}
                      stepAction={stepAction}
                      submitting={submitting}
                      disabled={
                        (stepComplete || isNewLocation ? false : pristine) ||
                        submitting
                      }
                      form={form}
                      userGuid={enrollmentParams['guid']}
                      enrollmentData={enrollmentData}
                    />
                  </div>
                </div>

                <ViewDebug className="crxdo-view-debug-bottom-right">
                  <div className="h4" role="heading">
                    Form Values & Step Details & config
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">form values</span>=
                    {JSON.stringify(values)}
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">stepDetails</span>=
                    {JSON.stringify(stepDetails)}
                  </div>
                  {config && (
                    <div className="mb-2">
                      <span className="font-weight-bold">config</span>=
                      {JSON.stringify(config)}
                    </div>
                  )}
                </ViewDebug>
              </form>
            );
          }}
        />

        <BrandFooter />
      </div>
    );
  };

  return render();
};

export const formValidatePracticeLocations = values => {
  const errors = {};
  const {
    locationName,
    addressLine1,
    phoneMasked,
    faxMasked,
    city,
    state,
    zipCodeMasked
  } = values;

  if (!locationName) {
    errors.locationName = FIELD_REQUIRED_ERROR;
  }
  if (!addressLine1) {
    errors.addressLine1 = FIELD_REQUIRED_ERROR;
  }
  if (!phoneMasked) {
    errors.phoneMasked = FIELD_REQUIRED_ERROR;
  }
  if (!faxMasked) {
    errors.faxMasked = FIELD_REQUIRED_ERROR;
  }
  if (!city) {
    errors.city = FIELD_REQUIRED_ERROR;
  }
  if (!state) {
    errors.state = FIELD_REQUIRED_ERROR;
  }
  if (!zipCodeMasked) {
    errors.zipCodeMasked = FIELD_REQUIRED_ERROR;
  }
  return errors;
};

const PracticeLocations = withRouter(PracticeLocationsView);

export default PracticeLocations;
