import React from 'react';
import PropTypes from 'prop-types';
import './SubmitButtonSpinner.scss';
import brandConfig from '../../../brand/brand-config';

const SubmitButtonSpinner = ({ submitting, color }) => {
  if (submitting !== true) {
    return null;
  }

  const textColor = `text-${color ? color : 'white'}`;
  const { language } = brandConfig;

  return (
    <span
      className={`crxdo-submit-btn-spinner spinner-border spinner-border-sm ${textColor}`}
      role="status"
    >
      <span className="sr-only">
        {language.GENERAL_SUBMITTING_STEP_LOADER_MSG}
      </span>
    </span>
  );
};

SubmitButtonSpinner.propTypes = {
  submitting: PropTypes.bool
};

export default SubmitButtonSpinner;
