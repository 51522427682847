import React, { useState, useEffect } from 'react';
import { Alert, Button, CustomInput, FormGroup, Row } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import ValidateInputText from '../shared/ValidateInputText/ValidateInputText';
import AlertError from '../shared/AlertError/AlertError';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import {
  renderRecaptcha,
  isRecaptchaConfigured,
  getRecaptchaToken
} from '../shared/TokenValidation/Recaptcha';
import {
  composeFieldValidators,
  validateFieldEmail,
  validateFieldRequired,
  FIELD_REQUIRED_ERROR,
  FIELD_MUST_MATCH_VALUE_ERROR
} from '../../utils/form';
import {
  PRACTICE_REGISTRATION_ROLE_PRACTICE,
  PRACTICE_REGISTRATION_ROLE_SALES
} from '../../constants/Roles';
import brandConfig from '../../brand/brand-config.json';
import './PracticeRegistration.scss';
import { postEnrollment } from '../../api/index';
import { useConfig } from '../../config';
import ViewDebug from '../shared/ViewDebug/ViewDebug';
import { PRACTICE_DEMOGRAPHICS_ROUTE_NAME } from '../practice-demographics/PracticeDemographics';
import { isPracticeOnly, analytics } from '../../utils';
import { OPT_OUT_ROUTE_NAME } from '../opt-out/OptOut.js';
import BrandFooter from '../shared/BrandFooter/BrandFooter';
export const PRACTICE_REGISTRATION_ROUTE_NAME = 'practice-registration';

const PracticeRegistration = ({ defaultEmptyEnrollmentData }) => {
  const config = useConfig();
  // this is only being called here because of the way we have
  // configuration setup in the app and the mix of hooks and class components
  const recaptchaConfigured = isRecaptchaConfigured(config);

  useEffect(() => {
    analytics.initAnalyticsRegistrationPage();
  }, [config]);

  useEffect(() => {
    if (recaptchaConfigured) {
      renderRecaptcha(config);
    }
  }, [config, recaptchaConfigured]);

  const [error, setError] = useState(null);
  const [
    showEmailConfirmationMessage,
    setShowEmailConfirmationMessage
  ] = useState(false);
  const [toGuidUrl, setToGuidUrl] = useState(null);
  const practiceOnly = isPracticeOnly(config);

  const handleSubmit = async formValues => {
    const token = recaptchaConfigured
      ? await getRecaptchaToken('PracticeRegistration', config)
      : '';

    const onHandleSubmitSuccess = () => {
      setShowEmailConfirmationMessage(true);
    };

    const formValueUpdates = practiceOnly
      ? { initiatorRole: PRACTICE_REGISTRATION_ROLE_PRACTICE }
      : {};

    // TODO: CLEAN THIS UP LATER
    formValues.recaptchaToken = token;

    const updateFormValues = Object.assign({}, formValues, formValueUpdates);

    const onHandleSubmitFailure = error => {
      setError(error);
    };

    return await postEnrollment(updateFormValues, config)
      .then(onHandleSubmitSuccess)
      .catch(onHandleSubmitFailure);
  };

  const isSubmitDisabled = (submitting, pristine) => {
    return submitting || pristine || showEmailConfirmationMessage;
  };

  const render = () => {
    const { language } = brandConfig;

    return (
      <div className="crxdo-practice-registration">
        <div className="crxdo-practice-registration-bg m-xs-0 my-sm-0 my-md-5 container d-flex align-items-stretch bg-white shadow shadow-none-sm-down">
          <div className="crxdo-practice-registration-decoration">&nbsp;</div>
          <div className="crxdo-practice-registration-form-wrap d-block">
            <header>
              <h1 className="h2 text-primary crxdo-heading">
                {language.PRACTICE_REGISTRATION_TITLE}
              </h1>
              <small className="mb-3 d-block crxdo-body">
                {language.PRACTICE_REGISTRATION_INTRO_TEXT}
              </small>
              <small className="d-block crxdo-body">
                {language.PRACTICE_REGISTRATION_INTRO_TEXT_2}
              </small>
            </header>

            {error && <AlertError error={error} scrollToError={true} />}

            <Form
              validateOnBlur={false}
              initialValues={defaultEmptyEnrollmentData}
              onSubmit={handleSubmit}
              validate={formValidatePracticeRegistration}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form
                  onSubmit={handleSubmit}
                  className="crxdo-form"
                  autoComplete="off"
                  noValidate={true}
                >
                  <Row>
                    <div className="col-12 col-lg-6">
                      <FormGroup>
                        <ValidateInputText
                          id="initiatorFirstName"
                          type="text"
                          labelTextLanguageKey="PRACTICE_REGISTRATION_FIELD_FIRST_NAME"
                          placeHolderTextLanguageKey="PRACTICE_REGISTRATION_FIELD_FIRST_NAME_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={50}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>

                    <div className="col-12 col-lg-6">
                      <FormGroup>
                        <ValidateInputText
                          id="initiatorLastName"
                          type="text"
                          labelTextLanguageKey="PRACTICE_REGISTRATION_FIELD_LAST_NAME"
                          placeHolderTextLanguageKey="PRACTICE_REGISTRATION_FIELD_LAST_NAME_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={50}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row className={`${practiceOnly ? 'pb-2' : ''}`}>
                    <div className="col-12 col-lg-6">
                      <FormGroup>
                        <ValidateInputText
                          id="initiatorEmail"
                          type="email"
                          labelTextLanguageKey="PRACTICE_REGISTRATION_FIELD_EMAIL"
                          placeHolderTextLanguageKey="GENERAL_FIELD_EMAIL_PLACEHOLDER"
                          validateFields={[
                            'initiatorEmail',
                            'confirmInitiatorEmail'
                          ]}
                          validate={composeFieldValidators(
                            validateFieldRequired,
                            validateFieldEmail
                          )}
                          maxLength={150}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12 col-lg-6">
                      <FormGroup>
                        <ValidateInputText
                          id="confirmInitiatorEmail"
                          type="email"
                          labelTextLanguageKey="PRACTICE_REGISTRATION_FIELD_CONFIRM_EMAIL"
                          placeHolderTextLanguageKey="GENERAL_FIELD_EMAIL_PLACEHOLDER"
                          validateFields={[
                            'initiatorEmail',
                            'confirmInitiatorEmail'
                          ]}
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={150}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  {!practiceOnly && (
                    <Row>
                      <div className="col-12">
                        <FormGroup>
                          <Field
                            name="initiatorRole"
                            component="input"
                            type="radio"
                            value={PRACTICE_REGISTRATION_ROLE_PRACTICE}
                          >
                            {({ input }) => {
                              return (
                                <CustomInput
                                  {...input}
                                  type="radio"
                                  id="initiatorRole_practice"
                                  name="initiatorRole"
                                  className="crxdo-label"
                                  label={
                                    language.PRACTICE_REGISTRATION_FIELD_INITIATOR_ROLE_PRACTICE
                                  }
                                />
                              );
                            }}
                          </Field>
                          <Field
                            name="initiatorRole"
                            component="input"
                            type="radio"
                            value={PRACTICE_REGISTRATION_ROLE_SALES}
                          >
                            {({ input }) => {
                              return (
                                <CustomInput
                                  {...input}
                                  type="radio"
                                  id="initiatorRole_sales"
                                  name="initiatorRole"
                                  className="crxdo-label"
                                  label={
                                    language.PRACTICE_REGISTRATION_FIELD_INITIATOR_ROLE_SALES
                                  }
                                />
                              );
                            }}
                          </Field>
                        </FormGroup>
                      </div>
                    </Row>
                  )}

                  {showEmailConfirmationMessage && (
                    <Alert
                      color="brand-1"
                      className="crxdo-practice-registration-confirm-email-success"
                    >
                      <div
                        className="crxdo-practice-registration-confirm-email-success-badge badge badge-pill badge-medium"
                        aria-label="Information"
                      >
                        <span aria-hidden="true">i</span>
                      </div>
                      <div className="crxdo-practice-registration-confirm-email-success-message">
                        {language.PRACTICE_REGISTRATION_SUCCESS_MSG_CHECK_EMAIL}
                      </div>
                    </Alert>
                  )}

                  <div className="crxdo-form-buttons">
                    <div className="crxdo-form-btn-container">
                      <Button
                        type="submit"
                        color="brand-1"
                        className={`btn-block-sm-down crxdo-form-btn-last`}
                        disabled={isSubmitDisabled(submitting, pristine)}
                      >
                        {submitting ? (
                          <SubmitButtonSpinner submitting={submitting} />
                        ) : (
                          language.GENERAL_BEGIN_BTN
                        )}
                      </Button>
                    </div>
                  </div>                  
                </form>
              )}
            />

            {language.SUPPORT_SERVICE_JOB_CODE && (
              <div className="crxdo-practice-registration-footer">
                <div className="crxdo-practice-registration-footer-value">
                  {language.SUPPORT_SERVICE_JOB_CODE}
                </div>
              </div>
            )}
          </div>
        </div>
        <BrandFooter />
      </div>
    );
  };

  return render();
};

export const formValidatePracticeRegistration = values => {
  const errors = {};
  const {
    initiatorFirstName,
    initiatorLastName,
    initiatorEmail,
    confirmInitiatorEmail
  } = values;
  const { language } = brandConfig;

  if (!initiatorFirstName) {
    errors.initiatorFirstName = FIELD_REQUIRED_ERROR;
  }
  if (!initiatorLastName) {
    errors.initiatorLastName = FIELD_REQUIRED_ERROR;
  }
  if (!initiatorEmail) {
    errors.initiatorEmail = FIELD_REQUIRED_ERROR;
  } else {
    errors.initiatorEmail = validateFieldEmail(initiatorEmail);
  }
  if (!confirmInitiatorEmail) {
    errors.confirmInitiatorEmail = FIELD_REQUIRED_ERROR;
  } else if (confirmInitiatorEmail !== initiatorEmail) {
    errors.confirmInitiatorEmail = FIELD_MUST_MATCH_VALUE_ERROR(
      language.PRACTICE_REGISTRATION_FIELD_EMAIL
    );
  }
  return errors;
};

export default PracticeRegistration;
