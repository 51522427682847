const formatZip = zipString => {
  const cleaned = ('' + zipString).replace(/\D/g, '');
  const matchNine = cleaned.match(/^(\d{5})(\d{4})$/);
  const matchFive = cleaned.match(/^(\d{5})$/);
  if (matchNine) {
    return matchNine[1] + '-' + matchNine[2];
  }
  if (matchFive) {
    return matchFive[1];
  }
  return null;
};

export default formatZip;
