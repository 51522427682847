import brandConfig from '../../brand/brand-config.json';

export const FIELD_ZIP_ERROR = brandConfig.language.GENERAL_ERROR_FIELD_ZIP;

const validateFieldZip = value => {
  const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/i;

  return zipRegex.test(value) ? undefined : FIELD_ZIP_ERROR;
};

export default validateFieldZip;
