import React from 'react';
import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';
import './ServiceFaxOnlyFields.scss';
import { Row } from 'reactstrap';
import ValidateCheckboxBoolean from '../ValidateCheckboxBoolean/ValidateCheckboxBoolean';
import brandConfig from '../../../brand/brand-config.json';

const ServiceFaxOnlyFields = ({
  enrollmentData,
  hasFaxOnlyService,
  form,
  showFaxOnlyWarn
}) => {
  if (!enrollmentData || !hasFaxOnlyService || !form) {
    return null;
  }

  const handleDependentFieldChange = (whichField, value) => {
    if (whichField === 'benefitsVerification' && value === true) {
      form.change('faxOnlyServices', false);
    }
  };

  const { language } = brandConfig;

  return (
    <Row className="crxdo-service-fax-only">
      <div className="col-12">
        <ValidateCheckboxBoolean
          name="faxOnlyServices"
          labelTextLanguageKey="SERVICES_SELECTION_FAX_ONLY"
          id="faxOnlyServices"
          className="crxdo-form-label-emphasis"
          fieldDescriptionTextLanguageKey="SERVICES_SELECTION_FAX_ONLY_DESCRIPTION"
          optionalText={
            language.SERVICES_SELECTION_FAX_ONLY_DISALLOW_BV_VERIFICATION_WARNING
          }
          showOptionalText={showFaxOnlyWarn}
        />
        <OnChange name="benefitsVerification">
          {(value, previous) =>
            handleDependentFieldChange('benefitsVerification', value, previous)
          }
        </OnChange>
      </div>
    </Row>
  );
};

ServiceFaxOnlyFields.propTypes = {
  hasFaxOnlyService: PropTypes.bool.isRequired,
  showFaxOnlyWarn: PropTypes.bool.isRequired,
  enrollmentData: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default ServiceFaxOnlyFields;
