import React from 'react';
import PropTypes from 'prop-types';
import './ProgressHeader.scss';
import brandLogo from '../../../brand/images/logo.png';
import brandConfig from '../../../brand/brand-config.json';
import ProgressBar from '../ProgressBar/ProgressBar';

const ProgressHeader = ({ id, progressPercentage, progressTitle }) => {
  if (!id || isNaN(progressPercentage)) {
    return null;
  }
  const { brandName, language } = brandConfig;

  return (
    <header className="crxdo-progress-header" id={id}>
      <div className="container d-flex flex-wrap justify-content-between align-items-center">
        <div className="crxdo-progress-header-logo-wrap">
          <img
            className="crxdo-progress-header-logo img-fluid"
            src={brandLogo}
            alt={`${brandName} ${
              language.GENERAL_LOGO_ALT ? language.GENERAL_LOGO_ALT : ''
            }`}
          />
        </div>

        <ProgressBar
          progressPercentage={progressPercentage}
          progressTitle={progressTitle}
          id={`${id}_progress-bar`}
        />
      </div>
    </header>
  );
};

ProgressHeader.propTypes = {
  id: PropTypes.string.isRequired,
  progressPercentage: PropTypes.number.isRequired,
  progressTitle: PropTypes.string
};

export default ProgressHeader;
