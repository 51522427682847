import React from 'react';
import PropTypes from 'prop-types';
import './UserDetails.scss';
import { formatPhoneNumber } from '../../../utils/format';
import brandConfig from '../../../brand/brand-config.json';

const UserDetails = ({ user }) => {
  if (!user) {
    return null;
  }

  const { firstName, lastName, userType, role, phone, email } = user;

  const phoneMasked = formatPhoneNumber(phone);
  const { language } = brandConfig;

  return (
    <address className="crxdo-user-details crxdo-body">
      {firstName && lastName && (
        <div className="crxdo-user-detail crxdo-user-detail-name">
          {`${firstName} ${lastName}`}
        </div>
      )}
      {userType && (
        <div className="crxdo-user-detail">
          {language.GENERAL_LABEL_USER_TYPE && (
            <span className="crxdo-user-detail-label">
              {language.GENERAL_LABEL_USER_TYPE}
            </span>
          )}
          <span className="crxdo-user-detail">{userType}</span>
        </div>
      )}
      {role && (
        <div className="crxdo-user-detail">
          {language.GENERAL_LABEL_USER_ROLE && (
            <span className="crxdo-user-detail-label">
              {language.GENERAL_LABEL_USER_ROLE}
            </span>
          )}
          <span className="crxdo-user-detail">{role}</span>
        </div>
      )}
      {phone && (
        <div className="crxdo-user-detail">
          {language.GENERAL_LABEL_PHONE && (
            <span className="crxdo-user-detail-label">
              {language.GENERAL_LABEL_PHONE}
            </span>
          )}
          <a href={`tel:${phone}`}>{phoneMasked}</a>
        </div>
      )}
      {email && (
        <div className="crxdo-user-detail">
          {language.GENERAL_LABEL_EMAIL && (
            <span className="crxdo-user-detail-label">
              {language.GENERAL_LABEL_EMAIL}
            </span>
          )}
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      )}
    </address>
  );
};

UserDetails.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserDetails;
