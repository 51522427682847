import React from 'react';
import PropTypes from 'prop-types';
import brandConfig from '../../../brand/brand-config.json';
import './ServiceBVPortalFields.scss';
import { Row } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import ValidateCheckboxBoolean from '../ValidateCheckboxBoolean/ValidateCheckboxBoolean';
import BVPortalSubServices from '../BVPortalSubServices/BVPortalSubServices';

const ServiceBVPortalFields = ({
  enrollmentData,
  availableBrandServices,
  form,
  showBVWarn
}) => {
  if (
    !enrollmentData ||
    (!availableBrandServices && !availableBrandServices.hasBVPortalService) ||
    !form
  ) {
    return null;
  }

  const handleDependentFieldChange = (whichField, value) => {
    if (whichField !== 'faxOnlyServices') {
      return;
    }

    if (value === true) {
      form.change('benefitsVerification', false);
    }
  };

  const { language } = brandConfig;

  return (
    <Row className="crxdo-service-bv-portal">
      <div className="col-12">
        <ValidateCheckboxBoolean
          name="benefitsVerification"
          labelTextLanguageKey="SERVICES_SELECTION_BV_VERIFICATION"
          id="benefitsVerification"
          className="crxdo-form-label-emphasis"
          fieldDescriptionTextLanguageKey="SERVICES_SELECTION_BV_VERIFICATION_DESCRIPTION"
          optionalText={
            language.SERVICES_SELECTION_BV_VERIFICATION_DISALLOW_FAX_ONLY_WARNING
          }
          showOptionalText={showBVWarn}
        />

        <OnChange name="faxOnlyServices">
          {(value, previous) =>
            handleDependentFieldChange('faxOnlyServices', value, previous)
          }
        </OnChange>
      </div>
      <div className="col-12">
        <BVPortalSubServices
          availableBrandServices={availableBrandServices}
          showDescriptions={true}
          className="crxdo-service-bv-portal-sub-services"
        />
      </div>
    </Row>
  );
};

ServiceBVPortalFields.propTypes = {
  availableBrandServices: PropTypes.object.isRequired,
  showBVWarn: PropTypes.bool.isRequired,
  enrollmentData: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default ServiceBVPortalFields;
