const isEnrollmentExpired = enrollmentData => {
  if (!enrollmentData) {
    return false;
  }
  const { expiredDate } = enrollmentData;

  const timezoneOffsetInMS = new Date().getTimezoneOffset() * 60 * 1000; // Timezone Offset in Milliseconds
  const expiredDateParsed = Date.parse(expiredDate); // Parsing UTC date from API
  const expireDateInMS = isNaN(expiredDateParsed)
    ? null
    : expiredDateParsed - timezoneOffsetInMS;

  const isExpiredDateBeforeToday = dateToTestInMS => {
    const dateOffset = 1;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + dateOffset);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);

    const tomorrowInMS = tomorrow.getTime();

    return dateToTestInMS - tomorrowInMS < 0;
  };

  return expireDateInMS
    ? isExpiredDateBeforeToday(expireDateInMS, timezoneOffsetInMS)
    : false;
};

export default isEnrollmentExpired;
