import React from 'react';
import brandConfig from '../../../brand/brand-config';
import './FieldRequiredIcon.scss';

const FieldRequiredIcon = () => {
  const { language } = brandConfig;
  return (
    <span
      className="crxdo-field-required-icon"
      title={language.GENERAL_FIELD_REQUIRED}
    >
      *<span className="sr-only">{language.GENERAL_FIELD_REQUIRED}</span>
    </span>
  );
};

export default FieldRequiredIcon;
