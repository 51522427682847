export const STEP_ACTION_ADD_LOCATIONS = 'step-action-add-locations';
export const STEP_ACTION_ADD_NEW_LOCATION = 'step-action-add-new-location';
export const STEP_ACTION_UPDATE_LOCATION = 'step-action-update-location';
export const STEP_ACTION_NEXT = 'step-action-next';
export const STEP_ACTION_BACK = 'step-action-back';
export const STEP_ACTION_EJECT = 'step-action-eject';
export const STEP_ACTION_COMPLETE_ENROLLMENT =
  'step-action-complete-enrollment';
export const STEP_ACTION_ADD_BV_DETAILS = 'step-action-add-bv-details';
export const STEP_ACTION_ADD_NEW_PRESCRIBER = 'step-action-add-new-prescriber';
export const STEP_ACTION_UDPATE_PRESCRIBER = 'step-action-update-prescriber';
export const STEP_ACTION_ADD_NEW_USER = 'step-action-add-new-user';
export const STEP_ACTION_UDPATE_USER = 'step-action-update-user';
