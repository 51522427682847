import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './EjectSuccess.scss';
import MessageView from '../shared/MessageView/MessageView';
import brandConfig from '../../brand/brand-config';
import { getEnrollment } from '../../api';
import { useMount } from '../../utils';
import { useConfig } from '../../config';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';

export const EJECT_SUCCESS_ROUTE_NAME = 'eject-success';

const EjectSuccess = ({
  enrollmentData,
  match,
  onResetEnrollment,
  onEnrollmentDataUpdated
}) => {
  const config = useConfig();
  const [messageReady, setMessageReady] = useState(false);

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const { salesRepresentativeGuid, practiceAdminGuid } = enrollmentData;

    if (
      guid &&
      (salesRepresentativeGuid !== guid || practiceAdminGuid !== guid)
    ) {
      fetchEnrollmentDataForGuid(guid);
    } else {
      onResetEnrollment();
    }
  });

  const fetchEnrollmentDataForGuid = guid => {
    const onHandleGetSuccess = apiValues => {
      if (apiValues) {
        handleEnrollmentDataFetch(guid, apiValues);
      } else {
        onResetEnrollment();
      }
    };

    const onHandleGetFailure = error => {
      onResetEnrollment();
    };

    return getEnrollment(guid, config)
      .then(onHandleGetSuccess)
      .catch(onHandleGetFailure);
  };

  const handleEnrollmentDataFetch = (guid, enrollmentDataFromApi) => {
    setMessageReady(true);

    onEnrollmentDataUpdated(
      EJECT_SUCCESS_ROUTE_NAME,
      guid,
      false,
      enrollmentDataFromApi,
      true
    );
  };

  const getEjectMessage = () => {
    const { language } = brandConfig;
    return language.GENERAL_EJECT_SUCCESS_MESSAGE.replace(
      '{primaryContactEmail}',
      enrollmentData['primaryContactEmail']
    );
  };

  const render = () => {
    if (!messageReady) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    return (
      <div className="crxdo-eject-success">
        <MessageView>
          <div role="heading" className="h3 crxdo-label">
            {getEjectMessage()}
          </div>
        </MessageView>
      </div>
    );
  };

  return render();
};

EjectSuccess.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default EjectSuccess;
