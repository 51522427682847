import React from 'react';
import brandConfig from '../../../brand/brand-config.json';
import './BVPortalSubServices.scss';
import PropTypes from 'prop-types';

const BVPortalSubServices = ({
  availableBrandServices,
  className,
  showDescriptions
}) => {
  const { language } = brandConfig;
  const { hasBVPortalService, subservices } = availableBrandServices;
  if (!availableBrandServices && !hasBVPortalService && !subservices) {
    return null;
  }
  const {
    patientAssistanceProgram,
    priorAuthorization,
    reverificationServices,
    specialtyPharmacy
  } = subservices;

  const BVPortalSubServicesClassName = className ? className : '';
  const showSubServiceDescription = showDescriptions ? showDescriptions : false;

  return (
    <ul
      className={`crxdo-bv-portal-sub-services list-unstyled ${BVPortalSubServicesClassName}`}
    >
      {priorAuthorization &&
        language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_PRIOR_AUTH && (
          <li className="crxdo-bv-portal-sub-service">
            <div className="crxdo-bv-portal-sub-service-title crxdo-label">
              {
                language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_PRIOR_AUTH
              }
            </div>
            {showSubServiceDescription &&
              language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_PRIOR_AUTH_DESCRIPTION && (
                <div className="crxdo-bv-portal-sub-service-description small crxdo-body">
                  {
                    language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_PRIOR_AUTH_DESCRIPTION
                  }
                </div>
              )}
          </li>
        )}
      {patientAssistanceProgram &&
        language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_PATIENT_ASSIST && (
          <li className="crxdo-bv-portal-sub-service">
            <div className="crxdo-bv-portal-sub-service-title crxdo-label">
              {
                language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_PATIENT_ASSIST
              }
            </div>
            {showSubServiceDescription &&
              language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_PATIENT_ASSIST_DESCRIPTION && (
                <div className="crxdo-bv-portal-sub-service-description small crxdo-body">
                  {
                    language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_PATIENT_ASSIST_DESCRIPTION
                  }
                </div>
              )}
          </li>
        )}
      {specialtyPharmacy &&
        language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_SPECIALTY_PHARMACY && (
          <li className="crxdo-bv-portal-sub-service">
            <div className="crxdo-bv-portal-sub-service-title crxdo-label">
              {
                language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_SPECIALTY_PHARMACY
              }
            </div>
            {showSubServiceDescription &&
              language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_SPECIALTY_PHARMACY_DESCRIPTION && (
                <div className="crxdo-bv-portal-sub-service-description small crxdo-body">
                  {
                    language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_SPECIALTY_PHARMACY_DESCRIPTION
                  }
                </div>
              )}
          </li>
        )}

      {reverificationServices &&
        language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_REVERIFICATION && (
          <li className="crxdo-bv-portal-sub-service">
            <div className="crxdo-bv-portal-sub-service-title crxdo-label">
              {
                language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_REVERIFICATION
              }
            </div>
            {showSubServiceDescription &&
              language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_REVERIFICATION_DESCRIPTION && (
                <div className="crxdo-bv-portal-sub-service-description small crxdo-body">
                  {
                    language.SERVICES_SELECTION_BV_VERIFICATION_SUB_SERVICE_REVERIFICATION_DESCRIPTION
                  }
                </div>
              )}
          </li>
        )}
    </ul>
  );
};

BVPortalSubServices.propTypes = {
  availableBrandServices: PropTypes.object.isRequired
};

export default BVPortalSubServices;
