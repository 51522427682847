import React, { useState, useContext, useEffect, createContext } from 'react';
import { getBrandApiConfig } from './api/index';
import brandConfig from './brand/brand-config';
import LoadingSpinner from './components/shared/LoadingSpinner/LoadingSpinner';

const defaultEmptyConfig = {};

export const ConfigContext = createContext(defaultEmptyConfig);

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(defaultEmptyConfig);
  useEffect(() => {
    const fetchConfig = async () => {
      return await fetch('/config.json').then(configJsonResponse =>
        configJsonResponse.status === 200
          ? configJsonResponse
              .json()
              .then(async configJson => {
                const brandConfigResultJson = await getBrandApiConfig(
                  brandConfig.brandId,
                  configJson
                )
                  .then(brandApiConfigResponse => brandApiConfigResponse)
                  .catch(reason => {
                    return { brandConfigFailure: reason };
                  });

                return Object.assign(configJson, brandConfigResultJson);
              })
              .catch(reason => {
                setConfig(defaultEmptyConfig);
              })
          : setConfig(defaultEmptyConfig)
      );
    };

    fetchConfig().then(configResult => {
      setConfig(configResult);
    });
  }, []);

  if (config === defaultEmptyConfig)
    return <LoadingSpinner loading={true} isViewSpinner={true} />;

  return (
    // eslint-disable-next-line prettier/prettier
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const ConfigConsumer = () => {
  return <ConfigContext.Consumer />;
};

export const useConfig = () => {
  return useContext(ConfigContext);
};
