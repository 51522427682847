/**
 ** @numerator = top number to be divided by...
 * @denominator = divider of @numerator
 * @isRound = Boolean to round result;
 **/

const getPercentageComplete = (numerator, denominator, isRound = true) => {
  if (
    Number.isNaN(numerator) ||
    Number.isNaN(denominator) ||
    denominator <= 0
  ) {
    return 0;
  }

  const calcPercentage = ((numerator || 0) / (denominator || 1)) * 100;
  const percentageComplete = calcPercentage > 100 ? 100 : calcPercentage;

  return isRound ? Math.round(percentageComplete) : percentageComplete;
};

export default getPercentageComplete;
