import React from 'react';
import PropTypes from 'prop-types';
import './ValidateCheckboxBoolean.scss';
import FieldRequiredIcon from '../FieldRequiredIcon/FieldRequiredIcon';
import FieldError from '../FieldError/FieldError';
import { Field } from 'react-final-form';
import brandConfig from '../../../brand/brand-config.json';
import { CustomInput, FormGroup } from 'reactstrap';

const ValidateCheckboxBoolean = ({
  id,
  name,
  className,
  validate,
  validateFields,
  required,
  disabled,
  labelTextLanguageKey,
  fieldDescriptionTextLanguageKey,
  optionalText,
  showOptionalText
}) => {
  if (!id || !name || !labelTextLanguageKey) {
    return null;
  }

  const { language } = brandConfig;
  const labelText = language[labelTextLanguageKey];
  const fieldDescriptionText = fieldDescriptionTextLanguageKey
    ? language[fieldDescriptionTextLanguageKey]
    : null;
  const isRequired = required || false;
  const isDisabled = disabled || false;

  const getLabelText = (labelStr, showRequiredIcon) => {
    return (
      <span className="crdo-validate-checkbox-label-text crxdo-label">
        {labelStr}
        {showRequiredIcon && <FieldRequiredIcon />}
      </span>
    );
  };

  return (
    <Field
      name={`${name}`}
      component="input"
      type="checkbox"
      validate={validate}
      validateFields={validateFields}
    >
      {({ input, meta }) => {
        const fieldHasError = meta.error && meta.touched;

        return (
          <FormGroup
            className={`crdo-validate-checkbox ${className ? className : ''}`}
          >
            <CustomInput
              {...input}
              type="checkbox"
              id={`${id}`}
              name={`${name}`}
              label={getLabelText(labelText, required)}
              required={isRequired}
              disabled={isDisabled}
              className={isDisabled ? 'checkbox-disabled' : 'checkbox-enabled'}
              aria-invalid={fieldHasError}
              aria-describedby={fieldHasError ? `${name}_error` : null}
            />
            {fieldDescriptionText && (
              <div
                className={`crxdo-body crxdo-form-field-description small ${
                  showOptionalText ? 'mb-2' : ''
                }`}
              >
                {fieldDescriptionText}
              </div>
            )}
            {showOptionalText && optionalText && (
              <div className="crxdo-form-field-description small crxdo-body" role="status">
                {optionalText}
              </div>
            )}
            <FieldError fieldMeta={meta} id={`${name}_error`} />
          </FormGroup>
        );
      }}
    </Field>
  );
};

ValidateCheckboxBoolean.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  validate: PropTypes.func,
  validateFields: PropTypes.array,
  labelTextLanguageKey: PropTypes.string.isRequired,
  optionalText: PropTypes.string,
  showOptionalText: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ValidateCheckboxBoolean;
