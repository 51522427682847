import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Button } from 'reactstrap';
import brandConfig from '../../brand/brand-config';
import './ServicesSelection.scss';
import { putService, patchLocations, patchEnrollment } from '../../api/index';
import { useConfig } from '../../config';
import { isNullOrUndefinedOrEmpty, useMount } from '../../utils';
import {
  FIELD_REQUIRED_ERROR,
  validateFieldOrderingOnlineLocationsRequired
} from '../../utils/form';
import { formatPatchData } from '../../utils/format';
import {
  STEP_ACTION_EJECT,
  STEP_ACTION_NEXT,
  STEP_ACTION_ADD_BV_DETAILS,
  STEP_ACTION_BACK
} from '../../constants/StepActions';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import BrandFooter from '../shared/BrandFooter/BrandFooter';
import AlertError from '../shared/AlertError/AlertError';
import EnrollmentEjectButton from '../shared/EnrollmentEjectButton/EnrollmentEjectButton';
import ViewDebug from '../shared/ViewDebug/ViewDebug';
import { BV_PORTAL_DETAILS_ROUTE_NAME } from '../bv-portal-details/BVPortalDetails';
import ServiceBVPortalFields from '../shared/ServiceBVPortalFields/ServiceBVPortalFields';
import ServiceFaxOnlyFields from '../shared/ServiceFaxOnlyFields/ServiceFaxOnlyFields';
import ServiceInventoryManagementFields from '../shared/ServiceInventoryManagementFields/ServiceInventoryManagementFields';
import ServiceOnlineProductOrdering from '../shared/ServiceOnlineProductOrdering/ServiceOnlineProductOrdering';
import { defaultAvailableServicesData } from '../../utils/format';

export const SERVICES_SELECTION_ROUTE_NAME = 'services-selection';

export const ServicesSelectionView = ({
  enrollmentData,
  stepDetails,
  match,
  onRequestEnrollmentStart,
  onRequestAddStep,
  onRequestRemoveStep,
  onEjectEnrollment,
  onStepBackwards,
  onStepComplete,
  enrollmentError,
  onClearEnrollmentError
}) => {
  const config = useConfig();
  const [error, setError] = useState(null);
  const [currentUserGuid, setCurrentUserGuid] = useState(null);
  const [selectedServicesFormValues, setSelectedServicesFormValues] = useState(
    {}
  );
  const [enrollmentGuid, setEnrollmentGuid] = useState(null);
  const [stepReady, setStepReady] = useState(false);
  const [stepHasApiValues, setStepHasApiValues] = useState(false);
  const [stepCanSubmit, setStepCanSubmit] = useState(false);
  const [showBVWarn, setShowBVWarn] = useState(false);
  const [showFaxOnlyWarn, setShowFaxOnlyWarn] = useState(false);
  const [isServiceInventoryDisabled, setIsServiceInventoryDisabled] = useState(
    true
  );
  const [stepAction, setStepAction] = useState(STEP_ACTION_NEXT);
  const [availableServices, setAvailabledServices] = useState(
    defaultAvailableServicesData
  );

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const {
      enrollmentGuid,
      salesRepresentativeGuid,
      practiceAdminGuid
    } = enrollmentData;

    const hasNoGuid = isNullOrUndefinedOrEmpty(guid);
    const hasNoEnrollmentData =
      !enrollmentGuid && !salesRepresentativeGuid && !practiceAdminGuid;

    if (
      hasNoGuid ||
      hasNoEnrollmentData ||
      (guid !== salesRepresentativeGuid && guid !== practiceAdminGuid)
    ) {
      onRequestEnrollmentStart(guid);
    } else {
      initializeEnrollmentDataStateForServices(guid);
    }
  });

  const initializeEnrollmentDataStateForServices = guid => {
    const { brandServices } = config;
    const {
      enrollmentGuid,
      onlineProductOrdering,
      inventoryManagement,
      benefitsVerification,
      faxOnlyServices,
      additionalLocations,
      ordering,
      primaryAccountNumber,
      accountNumberUnknown
    } = enrollmentData;
    const stepHasApiValues =
      onlineProductOrdering ||
      inventoryManagement ||
      benefitsVerification ||
      faxOnlyServices;
    const stepHasInventoryManagementEnabled =
      onlineProductOrdering || benefitsVerification || faxOnlyServices;

    setEnrollmentGuid(enrollmentGuid);
    setCurrentUserGuid(guid);

    const updatedInventoryManagement = stepHasInventoryManagementEnabled
      ? inventoryManagement
      : false;

    setSelectedServicesFormValues({
      onlineProductOrdering,
      inventoryManagement: updatedInventoryManagement,
      benefitsVerification,
      faxOnlyServices,
      ordering,
      primaryAccountNumber,
      accountNumberUnknown,
      additionalLocations
    });

    setStepHasApiValues(stepHasApiValues);
    setStepCanSubmit(stepHasApiValues === true);
    setIsServiceInventoryDisabled(!stepHasInventoryManagementEnabled);

    setAvailabledServices(brandServices);
    setTimeout(() => {
      setStepReady(true);
    }, 100);
  };

  const handleStepBackwards = form => {
    if (form && form.reset) {
      form.reset();
    }

    onStepBackwards(SERVICES_SELECTION_ROUTE_NAME, currentUserGuid, false);
  };

  const handleStepSubmit = async formValues => {
    const {
      updateStepAction,
      benefitsVerification,
      onlineProductOrdering,
      additionalLocations,
      ordering,
      primaryAccountNumber,
      accountNumberUnknown
    } = formValues;

    setError(null);
    setStepAction(updateStepAction);
    onClearEnrollmentError();

    const onHandleSubmitFailure = error => {
      setError(error);
      setStepAction(STEP_ACTION_NEXT);
    };

    const onHandleServicesSubmitFinalSuccess = finalApiValues => {
      const updatedEnrollmentData = { ...finalApiValues };

      if (updateStepAction === STEP_ACTION_EJECT) {
        onEjectEnrollment(
          SERVICES_SELECTION_ROUTE_NAME,
          currentUserGuid,
          enrollmentGuid,
          updateStepAction,
          config
        );
      } else {
        const finalStepAction =
          benefitsVerification && updateStepAction !== STEP_ACTION_BACK
            ? STEP_ACTION_ADD_BV_DETAILS
            : updateStepAction;

        onStepComplete(
          SERVICES_SELECTION_ROUTE_NAME,
          currentUserGuid,
          finalStepAction,
          updatedEnrollmentData
        );
      }

      return true;
    };

    const onHandleServicesSelectSubmitSuccess = async servicesUpdateApiValues => {
      if (onlineProductOrdering) {
        let additionalLocationsPatch = null;

        const additionalLocationsForPatch = Array.isArray(additionalLocations)
          ? additionalLocations.map(additionalLocation => {
              const {
                locationId,
                accountNumber,
                ordering,
                accountNumberUnknown
              } = additionalLocation;

              return {
                locationId,
                accountNumber: accountNumber ? accountNumber : null,
                ordering,
                accountNumberUnknown
              };
            })
          : [];

        const hasAdditionalLocationAccounts =
          additionalLocationsForPatch.length > 0;

        if (hasAdditionalLocationAccounts) {
          additionalLocationsPatch = patchLocations(
            additionalLocationsForPatch,
            enrollmentGuid,
            config
          );
        }

        const primaryLocationOrderingForPatch = formatPatchData(
          {
            ordering,
            primaryAccountNumber,
            accountNumberUnknown
          },
          ''
        );

        const primaryLocationPatch = patchEnrollment(
          primaryLocationOrderingForPatch,
          enrollmentGuid,
          config
        );

        const onHandleCombinedPatchSuccess = combinedLocationPatchApiValues => {
          const combinedLocationPatchData = {
            primaryLocationPatch: {},
            additionalLocationsPatch: {}
          };

          const primaryLocationPatchValues = (combinedLocationPatchData[
            'primaryLocationPatch'
          ] = combinedLocationPatchApiValues[0]);
          const additionalLocationPatchApiValues = (combinedLocationPatchData[
            'additionalLocationsPatch'
          ] = combinedLocationPatchApiValues[1] || []);

          const { additionalLocations } = servicesUpdateApiValues;

          const getLocationPatchValueById = locationId => {
            return additionalLocationPatchApiValues.find(locationPatch => {
              return locationPatch.id === locationId;
            });
          };

          const getAdditionalLocationPatchValuesById = locationId => {
            return additionalLocationsForPatch.find(locationPatchValue => {
              return locationPatchValue.locationId === locationId;
            });
          };

          const updatedAdditionalLocations = additionalLocations.map(
            additionalLocation => {
              const { locationId } = additionalLocation;
              const patchLocationData = getLocationPatchValueById(locationId);
              const updatedAdditionalLocation = { ...additionalLocation };

              if (patchLocationData.statusCode === 200) {
                const patchValuesForLocation = getAdditionalLocationPatchValuesById(
                  locationId
                );
                const {
                  accountNumber,
                  accountNumberUnknown,
                  ordering
                } = patchValuesForLocation;
                updatedAdditionalLocation.ordering = ordering;
                updatedAdditionalLocation.accountNumber = accountNumber;
                updatedAdditionalLocation.accountNumberUnknown = accountNumberUnknown;
              }

              return updatedAdditionalLocation;
            }
          );

          const updatedApiValues = Object.assign(primaryLocationPatchValues, {
            additionalLocations: updatedAdditionalLocations
          });

          return onHandleServicesSubmitFinalSuccess(updatedApiValues);
        };

        const locationsPromiseArray = [primaryLocationPatch];

        if (additionalLocationsPatch) {
          locationsPromiseArray.push(additionalLocationsPatch);
        }

        return await Promise.all(locationsPromiseArray)
          .then(onHandleCombinedPatchSuccess)
          .catch(onHandleSubmitFailure);
      } else {
        return await onHandleServicesSubmitFinalSuccess(
          servicesUpdateApiValues
        );
      }
    };

    return await putService(formValues, enrollmentGuid, config)
      .then(onHandleServicesSelectSubmitSuccess)
      .catch(onHandleSubmitFailure);
  };

  const onServicesFormChange = formState => {
    const { values, touched } = formState;
    const {
      benefitsVerification,
      faxOnlyServices,
      onlineProductOrdering,
      inventoryManagement
    } = values;

    const canSubmit =
      onlineProductOrdering ||
      inventoryManagement ||
      benefitsVerification ||
      faxOnlyServices;

    const {
      serviceBenefitsVerificationPortal,
      serviceFaxOnly,
      serviceInventoryManagement
    } = availableServices;

    setStepCanSubmit(canSubmit);

    if (serviceBenefitsVerificationPortal || serviceFaxOnly) {
      if (benefitsVerification || faxOnlyServices) {
        onRequestAddStep(BV_PORTAL_DETAILS_ROUTE_NAME);
      } else {
        onRequestRemoveStep(BV_PORTAL_DETAILS_ROUTE_NAME);
      }
    }

    if (serviceBenefitsVerificationPortal && serviceFaxOnly) {
      setShowBVWarn(benefitsVerification);
      setShowFaxOnlyWarn(faxOnlyServices);
    }

    if (serviceInventoryManagement) {
      const stepHasInventoryManagementEnabled =
        onlineProductOrdering || benefitsVerification || faxOnlyServices;

      setIsServiceInventoryDisabled(!stepHasInventoryManagementEnabled);
    }
  };

  const isSubmitDisabled = (stepCanSubmit, submitting) => {
    return !stepCanSubmit || submitting;
  };

  const canSkipServicesSelectionValidation = (formValues, pristine) => {
    const {
      onlineProductOrdering,
      inventoryManagement,
      benefitsVerification,
      faxOnlyServices
    } = enrollmentData;

    return pristine
      ? true
      : benefitsVerification === formValues['benefitsVerification'] &&
          onlineProductOrdering === formValues['onlineProductOrdering'] &&
          inventoryManagement === formValues['inventoryManagement'] &&
          faxOnlyServices === formValues['faxOnlyServices'];
  };

  const render = () => {
    const enrollmentParams = match['params'];
    const { language } = brandConfig;

    if (!stepReady) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    return (
      <div className="crxdo-services-selection">
        <div className="container">
          <h1 className="h2 text-primary crxdo-heading">
            {language.SERVICES_SELECTION_TITLE}
          </h1>
        </div>
        <div className="crxdo-form-top-notes pt-2 px-2 pb-3">
          <div className="container crxdo-body">
            <p>{language.SERVICES_SELECTION_FORM_NOTES}</p>
          </div>
        </div>

        <Form
          validateOnBlur={false}
          initialValues={selectedServicesFormValues}
          onSubmit={handleStepSubmit}
          mutators={{
            ...arrayMutators
          }}
          validate={values =>
            formValidateServicesSelection(availableServices, values)
          }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            const canSkipValidation = canSkipServicesSelectionValidation(
              values,
              pristine
            );

            return (
              <form
                onSubmit={handleSubmit}
                className="crxdo-form"
                autoComplete="off"
                noValidate={true}
              >
                <FormSpy
                  onChange={onServicesFormChange}
                  subscription={{ values: true, touched: true }}                  
                />

                <div className="container">
                  {(error || enrollmentError) && (
                    <AlertError
                      error={error || enrollmentError}
                      scrollToError={true}
                    />
                  )}
                  {availableServices.serviceBenefitsVerificationPortal && (
                    <ServiceBVPortalFields
                      form={form}
                      availableBrandServices={availableServices}
                      enrollmentData={enrollmentData}
                      showBVWarn={showBVWarn}
                    />
                  )}
                  {availableServices.serviceOnlineProductOrdering && (
                    <ServiceOnlineProductOrdering
                      form={form}
                      formValues={values}
                      hasOnlineProductOrdering={
                        availableServices.serviceOnlineProductOrdering
                      }
                      enrollmentData={enrollmentData}
                    />
                  )}
                  {availableServices.serviceInventoryManagement && (
                    <ServiceInventoryManagementFields
                      hasInventoryManagementService={
                        availableServices.serviceInventoryManagement
                      }
                      form={form}
                      formValues={values}
                      isDisabled={isServiceInventoryDisabled}
                      enrollmentData={enrollmentData}
                      availableBrandServices={availableServices}
                    />
                  )}
                  {availableServices.serviceFaxOnly && (
                    <ServiceFaxOnlyFields
                      hasFaxOnlyService={availableServices.serviceFaxOnly}
                      form={form}
                      showFaxOnlyWarn={showFaxOnlyWarn}
                      enrollmentData={enrollmentData}
                    />
                  )}
                </div>

                <div className="crxdo-form-buttons crxdo-form-buttons-border-tb crxdo-form-buttons-stretch">
                  <div className="container crxdo-form-btn-container">
                    <Button
                      type="submit"
                      color="brand-1"
                      className="btn-block-sm-down crxdo-form-btn-last"
                      onClick={() => {
                        form.change('updateStepAction', STEP_ACTION_NEXT);
                      }}
                      disabled={isSubmitDisabled(stepCanSubmit, submitting)}
                    >
                      {submitting && stepAction === STEP_ACTION_NEXT ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_SAVE_AND_CONTINUE_BTN
                      )}
                    </Button>

                    <Button
                      type={canSkipValidation ? 'button' : 'submit'}
                      outline
                      color="secondary"
                      className="btn-block-sm-down crxdo-form-btn-auto-wide crxdo-form-btn-left"
                      onClick={
                        canSkipValidation
                          ? () => handleStepBackwards(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_BACK);
                            }
                      }
                      disabled={submitting}
                    >
                      {submitting && stepAction === STEP_ACTION_BACK ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_BACK_BTN
                      )}
                    </Button>

                    <EnrollmentEjectButton
                      id="services-selection-send-to-primary-button"
                      stepAction={stepAction}
                      submitting={submitting}
                      disabled={isSubmitDisabled(stepCanSubmit, submitting)}
                      form={form}
                      userGuid={enrollmentParams['guid']}
                      enrollmentData={enrollmentData}
                    />
                  </div>
                </div>

                <ViewDebug className="crxdo-view-debug-bottom-right">
                  <div className="h4" role="heading">
                    Form Values & Step Details & Config
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">form values</span>=
                    {JSON.stringify(values)}
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">stepDetails</span>=
                    {JSON.stringify(stepDetails)}
                  </div>
                  {config && (
                    <div className="mb-2">
                      <span className="font-weight-bold">config</span>=
                      {JSON.stringify(config)}
                    </div>
                  )}
                </ViewDebug>
              </form>
            );
          }}
        />

        <BrandFooter />
      </div>
    );
  };

  return render();
};

export const formValidateServicesSelection = (availableServices, values) => {
  const errors = {};
  const {
    benefitsVerification,
    faxOnlyServices,
    primaryAccountNumber,
    accountNumberUnknown,
    ordering
  } = values;
  const { language } = brandConfig;

  if (
    availableServices.serviceBenefitsVerificationPortal &&
    availableServices.serviceFaxOnly &&
    benefitsVerification &&
    benefitsVerification === faxOnlyServices
  ) {
    errors.benefitsVerification =
      language.GENERAL_ERROR_FAX_ONLY_WITH_OTHER_SERVICE_REQUIRED || null;
  }

  if (availableServices.serviceOnlineProductOrdering) {
    if (
      ordering &&
      isNullOrUndefinedOrEmpty(primaryAccountNumber) &&
      accountNumberUnknown !== true
    ) {
      errors.primaryAccountNumber = FIELD_REQUIRED_ERROR;
    }

    errors.onlineProductOrdering = validateFieldOrderingOnlineLocationsRequired(
      availableServices.serviceOnlineProductOrdering,
      values
    );
  }

  return errors;
};

const ServicesSelection = withRouter(ServicesSelectionView);

export default ServicesSelection;
