import { formatPhoneNumber, formatZip } from './';

const formatEnrollmentData = enrollmentData => {
  if (!enrollmentData) {
    return null;
  }

  const formattedEnrollmentData = {
    ...enrollmentData,
    zipCodeMasked: formatZip(enrollmentData ? enrollmentData.zipCode : ''),
    phoneMasked: formatPhoneNumber(enrollmentData ? enrollmentData.phone : ''),
    faxMasked: formatPhoneNumber(enrollmentData ? enrollmentData.fax : ''),
    confirmPrimaryContactEmail: enrollmentData
      ? enrollmentData.primaryContactEmail
      : ''
  };

  return formattedEnrollmentData;
};

export default formatEnrollmentData;
