import { isNullOrUndefinedOrEmpty } from './';
import { BAA_SIGN_USER_SIGN } from '../constants/BaaSignChoices';

const isBaaSignatureCompleted = enrollmentData => {
  const {
    completedDocusign,
    baaSignatureChoice,
    signatureEvent
  } = enrollmentData;

  const baaSignatureChoiceHasValue = isNullOrUndefinedOrEmpty(
    baaSignatureChoice
  );
  const completedDocusignHasValue = isNullOrUndefinedOrEmpty(completedDocusign);
  const signatureEventHasValue = isNullOrUndefinedOrEmpty(signatureEvent);

  return (
    (baaSignatureChoiceHasValue &&
      (baaSignatureChoice === BAA_SIGN_USER_SIGN && completedDocusign)) ||
    (!completedDocusignHasValue && !signatureEventHasValue)
  );
};

export default isBaaSignatureCompleted;
