import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './EnrollmentSuccess.scss';
import MessageView from '../shared/MessageView/MessageView';
import brandConfig from '../../brand/brand-config';
import { getEnrollment } from '../../api';
import { useMount } from '../../utils';
import { useConfig } from '../../config';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import { BAA_SIGN_SEND_TO_OTHER_PRACTICE_USER } from '../../constants/BaaSignChoices';

export const ENROLLMENT_SUCCESS_ROUTE_NAME = 'enrollment-success';

const EnrollmentSuccess = ({
  enrollmentData,
  match,
  onResetEnrollment,
  onEnrollmentDataUpdated
}) => {
  const config = useConfig();
  const [messageReady, setMessageReady] = useState(false);

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const { salesRepresentativeGuid, practiceAdminGuid } = enrollmentData;

    if (
      guid &&
      (salesRepresentativeGuid !== guid || practiceAdminGuid !== guid)
    ) {
      fetchEnrollmentDataForGuid(guid);
    } else {
      onResetEnrollment();
    }
  });

  const fetchEnrollmentDataForGuid = guid => {
    const onHandleGetSuccess = apiValues => {
      if (apiValues) {
        handleEnrollmentDataFetch(guid, apiValues);
      } else {
        onResetEnrollment();
      }
    };

    const onHandleGetFailure = error => {
      onResetEnrollment();
    };

    return getEnrollment(guid, config)
      .then(onHandleGetSuccess)
      .catch(onHandleGetFailure);
  };

  const handleEnrollmentDataFetch = (guid, enrollmentDataFromApi) => {
    setMessageReady(true);

    onEnrollmentDataUpdated(
      ENROLLMENT_SUCCESS_ROUTE_NAME,
      guid,
      false,
      enrollmentDataFromApi,
      true
    );
  };

  const getSuccessMessageHtml = () => {
    const { language } = brandConfig;
    const { baaSignatureChoice } = enrollmentData;

    return baaSignatureChoice === BAA_SIGN_SEND_TO_OTHER_PRACTICE_USER
      ? language.GENERAL_ENROLLMENT_SUCCESS_MESSAGE_AWAITING_SIGNATURE
      : language.GENERAL_ENROLLMENT_SUCCESS_MESSAGE;
  };

  const render = () => {
    const { language } = brandConfig;
    if (!messageReady) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    return (
      <div className="crxdo-enrollment-success">
        <MessageView>
          <h1 className="h2 text-primary crxdo-heading">
            {language.ENROLLMENT_SUCCESS_TITLE}
          </h1>
          <div
            className="h3 pt-2 pb-3 crxdo-body"
            dangerouslySetInnerHTML={{
              __html: getSuccessMessageHtml()
            }}
          />
          {language.ENROLLMENT_SUCCESS_EXTRA && (
            <div className="crxdo-enrollment-success-extra pt-2 pb-3 crxdo-body">
              <p>{language.ENROLLMENT_SUCCESS_EXTRA}</p>
            </div>
          )}
        </MessageView>
      </div>
    );
  };

  return render();
};

EnrollmentSuccess.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default EnrollmentSuccess;
