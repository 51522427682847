import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, FormGroup, Row } from 'reactstrap';
import brandConfig from '../../brand/brand-config';
import './Summary.scss';
import { patchEnrollment } from '../../api/index';
import { useConfig } from '../../config';
import {
  getISODateString,
  isNullOrUndefinedOrEmpty,
  useMount,
  isPracticeUser,
  isBaaSignatureRequired,
  isBaaSignatureRequiredForPracticeUser
} from '../../utils';
import {
  STEP_ACTION_BACK,
  STEP_ACTION_COMPLETE_ENROLLMENT,
  STEP_ACTION_EJECT
} from '../../constants/StepActions';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import BrandFooter from '../shared/BrandFooter/BrandFooter';
import AlertError from '../shared/AlertError/AlertError';
import linkIcon from '../../images/link.svg';
import { formatLocationData, formatPatchData } from '../../utils/format';
import ValidateTextarea from '../shared/ValidateTextarea/ValidateTextarea';
import LocationDetails from '../shared/LocationDetails/LocationDetails';
import UserDetails from '../shared/UserDetails/UserDetails';
import PrescriberDetails from '../shared/PrescriberDetails/PrescriberDetails';
import BVPortalSubServices from '../shared/BVPortalSubServices/BVPortalSubServices';
import { defaultAvailableServicesData } from '../../utils/format';
import { PRACTICE_DEMOGRAPHICS_ROUTE_NAME } from '../practice-demographics/PracticeDemographics';
import { SERVICES_SELECTION_ROUTE_NAME } from '../services-selection/ServicesSelection';
import { ADD_USERS_ROUTE_NAME } from '../add-users/AddUsers';
import { ADD_PRESCRIBERS_ROUTE_NAME } from '../add-prescribers/AddPrescribers';
import EnrollmentEjectButton from '../shared/EnrollmentEjectButton/EnrollmentEjectButton';

export const SUMMARY_ROUTE_NAME = 'summary';

export const SummaryView = ({
  enrollmentData,
  match,
  onRequestEnrollmentStart,
  onRequestEnrollmentStep,
  onEjectEnrollment,
  onStepComplete,
  onCompleteEnrollment,
  onStepBackwards,
  enrollmentError,
  onClearEnrollmentError
}) => {
  const config = useConfig();
  const [error, setError] = useState(null);
  const [currentUserGuid, setCurrentUserGuid] = useState(null);
  const [enrollmentGuid, setEnrollmentGuid] = useState(null);
  const [summaryValues, setSummaryValues] = useState({});
  const [stepReady, setStepReady] = useState(false);
  const [stepAction, setStepAction] = useState(STEP_ACTION_COMPLETE_ENROLLMENT);
  const [baaSignatureRequired, setBaaSignatureRequired] = useState(false);
  const [
    baaSignatureRequiredForPracticeUser,
    setBaaSignatureRequiredForPracticeUser
  ] = useState(false);
  const [availableServices, setAvailableServices] = useState(
    defaultAvailableServicesData
  );

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const {
      enrollmentGuid,
      salesRepresentativeGuid,
      practiceAdminGuid
    } = enrollmentData;

    const hasNoGuid = isNullOrUndefinedOrEmpty(guid);
    const hasNoEnrollmentData =
      !enrollmentGuid && !salesRepresentativeGuid && !practiceAdminGuid;

    if (
      hasNoGuid ||
      hasNoEnrollmentData ||
      (guid !== salesRepresentativeGuid && guid !== practiceAdminGuid)
    ) {
      onRequestEnrollmentStart(guid);
    } else {
      initializeEnrollmentDataStateForUsers(guid);
    }
  });

  const initializeEnrollmentDataStateForUsers = guid => {
    const { brandServices } = config;
    const { enrollmentGuid, additionalNotes } = enrollmentData;
    const initSummaryValues = { additionalNotes };

    setEnrollmentGuid(enrollmentGuid);
    setCurrentUserGuid(guid);
    setSummaryValues(initSummaryValues);

    setBaaSignatureRequired(isBaaSignatureRequired(enrollmentData, config));
    setBaaSignatureRequiredForPracticeUser(
      isBaaSignatureRequiredForPracticeUser(enrollmentData, guid, config)
    );

    setAvailableServices(brandServices);
    setTimeout(() => {
      setStepReady(true);
    }, 100);
  };

  const handleStepBackwards = form => {
    if (form && form.reset) {
      form.reset();
    }

    onStepBackwards(SUMMARY_ROUTE_NAME, currentUserGuid, false);
  };

  const handleStepSubmit = async (formValues, form) => {
    const { updateStepAction, additionalNotes } = formValues;

    setError(null);
    setStepAction(updateStepAction);
    onClearEnrollmentError();
    let formValuesToPatch = {
      additionalNotes
    };

    const isEnrollmentComplete =
      updateStepAction !== STEP_ACTION_BACK &&
      updateStepAction !== STEP_ACTION_EJECT &&
      !baaSignatureRequiredForPracticeUser;

    if (isEnrollmentComplete) {
      formValuesToPatch['enrollmentCompletionDate'] = getISODateString();
      formValuesToPatch['sendZenDeskEmail'] = true;
    }

    const formValuesForApi = formatPatchData(formValuesToPatch);

    const onHandleSubmitFailure = error => {
      setError(error);
      setStepAction(STEP_ACTION_COMPLETE_ENROLLMENT);
    };

    const onHandleSubmitSuccess = apiValues => {
      const updatedEnrollmentData = { ...apiValues };

      if (updateStepAction === STEP_ACTION_BACK) {
        onStepComplete(
          SUMMARY_ROUTE_NAME,
          currentUserGuid,
          updateStepAction,
          updatedEnrollmentData
        );
      } else if (updateStepAction === STEP_ACTION_EJECT) {
        onEjectEnrollment(
          SUMMARY_ROUTE_NAME,
          currentUserGuid,
          enrollmentGuid,
          updateStepAction,
          config
        );
      } else {
        onCompleteEnrollment(
          SUMMARY_ROUTE_NAME,
          currentUserGuid,
          updatedEnrollmentData,
          config
        );
      }

      form.reset();

      return true;
    };

    return await patchEnrollment(formValuesForApi, enrollmentGuid, config)
      .then(onHandleSubmitSuccess)
      .catch(onHandleSubmitFailure);
  };

  const handleRequestStep = toStepId => {
    onRequestEnrollmentStep(currentUserGuid, toStepId);
  };

  const canCompleteEnrollment = () => {
    // FIXME: Add data checks/rules before user can submit?
    // const { prescribers } = enrollmentData;
    // const hasMinimumPrescribers =
    //   Array.isArray(prescribers) &&
    //   prescribers.length >= MINIMUM_PRESCRIBERS_LENGTH;
    // const isEnrollmentComplete = hasMinimumPrescribers;
    const isEnrollmentComplete = true;

    return isEnrollmentComplete;
  };

  const render = () => {
    const {
      additionalLocations,
      prescribers,
      users,
      benefitsVerification,
      faxOnlyServices,
      inventoryManagement,
      onlineProductOrdering,
      ordering,
      practiceName,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      phone,
      fax
    } = enrollmentData;

    const formattedPrimaryLocation = formatLocationData({
      locationName: practiceName,
      addressLine1,
      addressLine2,
      city,
      state,
      phone,
      zipCode,
      fax
    });

    if (!stepReady || !summaryValues) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    const { language } = brandConfig;
    const practiceUser = isPracticeUser(enrollmentData, currentUserGuid);

    return (
      <div className="crxdo-summary">
        <div className="container">
          <h1 className="h2 text-primary crxdo-heading">{language.SUMMARY_TITLE}</h1>
        </div>
        <div className="crxdo-form-top-notes pt-2 px-2 pb-3">
          <div className="container crxdo-body">
            <p>{language.SUMMARY_FORM_NOTES}</p>
          </div>
        </div>

        <Form
          validateOnBlur={false}
          initialValues={summaryValues}
          onSubmit={handleStepSubmit}
          validate={formValidateSummary}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className="crxdo-form"
                autoComplete="off"
                noValidate={true}
              >
                <div className="container">
                  {(error || enrollmentError) && (
                    <AlertError
                      error={error || enrollmentError}
                      scrollToError={true}
                    />
                  )}

                  <Row className="border-top py-3 py-md-5">
                    <section className="crxdo-summary-section col-12 col-md-6 col-lg-6">
                      <h2 className="crxdo-summary-section-title">
                        <Button
                          type="button"
                          color="link"
                          className="crxdo-summary-title-link"
                          onClick={() =>
                            handleRequestStep(PRACTICE_DEMOGRAPHICS_ROUTE_NAME)
                          }
                        >
                          <img
                            src={linkIcon}
                            aria-hidden="true"
                            className="crxdo-summary-title-link-icon"
                            alt={language.GENERAL_LINK_BTN}
                          />
                          <span className="crxdo-summary-title-link-text crxdo-heading">
                            {language.SUMMARY_SECTION_LOCATIONS_TITLE}
                          </span>
                        </Button>
                      </h2>
                      <div className="crxdo-summary-section-description pb-2 crxdo-body">
                        {language.SUMMARY_SECTION_LOCATIONS_NOTES}
                      </div>
                      <div className="crxdo-summary-section-detail">
                        {formattedPrimaryLocation['locationName'] && (
                          <ul className="crxdo-summary-list crxdo-summary-locations list-unstyled">
                            <li className="crxdo-summary-location crxdo-body">
                              <LocationDetails
                                location={formattedPrimaryLocation}
                              />
                            </li>
                            {additionalLocations &&
                              additionalLocations.map(additionalLocation => {
                                const additionalLocationId =
                                  additionalLocation['locationId'];

                                return (
                                  <li
                                    key={`summary-location-${additionalLocationId}`}
                                    className="crxdo-summary-location crxdo-body"
                                  >
                                    <LocationDetails
                                      location={additionalLocation}
                                    />
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                      </div>
                    </section>
                    <section className="crxdo-summary-section col-12 col-md-6 col-lg-6">
                      <h2 className="crxdo-summary-section-title">
                        <Button
                          type="button"
                          color="link"
                          className="crxdo-summary-title-link"
                          onClick={() =>
                            handleRequestStep(SERVICES_SELECTION_ROUTE_NAME)
                          }
                        >
                          <img
                            src={linkIcon}
                            aria-hidden="true"
                            className="crxdo-summary-title-link-icon"
                            alt={language.GENERAL_LINK_BTN}
                          />
                          <span className="crxdo-summary-title-link-text crxdo-heading">
                            {language.SUMMARY_SECTION_SERVICES_TITLE}
                          </span>
                        </Button>
                      </h2>
                      <div className="crxdo-summary-section-description pb-2 crxdo-body">
                        {language.SUMMARY_SECTION_SERVICES_NOTES}
                      </div>
                      <div className="crxdo-summary-section-detail">
                        <ul className="crxdo-summary-list crxdo-summary-services list-unstyled">
                          {benefitsVerification && (
                            <li>
                              <div className="crxdo-summary-list-title crxdo-label">
                                {language.SERVICES_SELECTION_BV_VERIFICATION}
                              </div>
                              <BVPortalSubServices
                                availableBrandServices={availableServices}
                                className="crxdo-summary-sub-list"
                                showDescriptions={false}
                              />
                            </li>
                          )}
                          {onlineProductOrdering && (
                            <li>
                              <div className="crxdo-summary-list-title crxdo-label">
                                {
                                  language.SERVICES_SELECTION_ONLINE_PRODUCT_ORDERING
                                }
                              </div>
                              <ul className="crxdo-summary-sub-list list-unstyled">
                                {ordering && (
                                  <li className="crxdo-summary-location crxdo-body">
                                    {formattedPrimaryLocation['locationName']}
                                  </li>
                                )}
                                {additionalLocations &&
                                  additionalLocations.map(
                                    additionalLocation => {
                                      const additionalLocationOrdering =
                                        additionalLocation['ordering'];
                                      const additionalLocationId =
                                        additionalLocation['locationId'];
                                      const additionalLocationName =
                                        additionalLocation['locationName'];
                                      if (additionalLocationOrdering) {
                                        return (
                                          <li
                                            key={`summary-ordering-location-${additionalLocationId}`}
                                            className="crxdo-summary-location crxdo-body"
                                          >
                                            {additionalLocationName}
                                          </li>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                              </ul>
                            </li>
                          )}
                          {inventoryManagement && (
                            <li>
                              <div className="crxdo-summary-list-title crxdo-label">
                                {
                                  language.SERVICES_SELECTION_INVENTORY_MANAGEMENT
                                }
                              </div>
                            </li>
                          )}
                          {faxOnlyServices && (
                            <li>
                              <div className="crxdo-summary-list-title crxdo-label">
                                {language.SERVICES_SELECTION_FAX_ONLY}
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </section>
                  </Row>

                  <Row className="border-top py-3 py-md-5">
                    {users && users.length > 0 && (
                      <section className="crxdo-summary-section col-12 col-md-6 col-lg-6">
                        <h2 className="crxdo-summary-section-title">
                          <Button
                            type="button"
                            color="link"
                            className="crxdo-summary-title-link"
                            onClick={() =>
                              handleRequestStep(ADD_USERS_ROUTE_NAME)
                            }
                          >
                            <img
                              src={linkIcon}
                              aria-hidden="true"
                              className="crxdo-summary-title-link-icon"
                              alt={language.GENERAL_LINK_BTN}
                            />
                            <span className="crxdo-summary-title-link-text crxdo-heading">
                              {language.SUMMARY_SECTION_USERS_TITLE}
                            </span>
                          </Button>
                        </h2>
                        <div className="crxdo-summary-section-description pb-2 crxdo-body">
                          {language.SUMMARY_SECTION_USERS_NOTES}
                        </div>
                        <div className="crxdo-summary-section-detail">
                          <ul className="crxdo-summary-list crxdo-summary-users list-unstyled">
                            {users.map(user => {
                              const { userId } = user;

                              return (
                                <li
                                  key={`summary-user-${userId}`}
                                  className="crxdo-summary-user mb-2"
                                >
                                  <UserDetails user={user} />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </section>
                    )}
                    {prescribers && prescribers.length > 0 && (
                      <section className="crxdo-summary-section col-12 col-md-6 col-lg-6">
                        <h2 className="crxdo-summary-section-title h5 text-primary font-weight-bold">
                          <Button
                            type="button"
                            color="link"
                            className="crxdo-summary-title-link"
                            onClick={() =>
                              handleRequestStep(ADD_PRESCRIBERS_ROUTE_NAME)
                            }
                          >
                            <img
                              src={linkIcon}
                              aria-hidden="true"
                              className="crxdo-summary-title-link-icon"
                              alt={language.GENERAL_LINK_BTN}
                            />
                            <span className="crxdo-summary-title-link-text crxdo-heading">
                              {language.SUMMARY_SECTION_PRESCRIBERS_TITLE}
                            </span>
                          </Button>
                        </h2>
                        <div className="crxdo-summary-section-description pb-2 crxdo-body">
                          {language.SUMMARY_SECTION_PRESCRIBERS_NOTES}
                        </div>
                        <div className="crxdo-summary-section-detail">
                          <ul className="crxdo-summary-list crxdo-summary-prescribers list-unstyled">
                            {prescribers.map(prescriber => {
                              const { prescriberId } = prescriber;

                              return (
                                <li
                                  key={`summary-prescriber-${prescriberId}`}
                                  className="crxdo-summary-prescriber mb-2"
                                >
                                  <PrescriberDetails prescriber={prescriber} />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </section>
                    )}
                  </Row>

                  <Row className="border-top py-3 pt-md-5">
                    <section className="col-12">
                      <FormGroup>
                        <ValidateTextarea
                          id="additionalNotes"
                          className="crxdo-form-label-emphasis crxdo-summary-textarea"
                          maxLength={250}
                          autoComplete="none"
                          labelTextLanguageKey="SUMMARY_SECTION_OTHER_INFO_TITLE"
                          optionalText={
                            language.SUMMARY_SECTION_OTHER_INFO_NOTES
                          }
                          showOptionalText={true}
                        />
                      </FormGroup>
                    </section>
                  </Row>
                </div>

                <div className="crxdo-form-buttons crxdo-form-buttons-border-tb crxdo-form-buttons-stretch">
                  <div className="container crxdo-form-btn-container">
                    <Button
                      id="summarySubmitButton"
                      type={
                        !practiceUser && baaSignatureRequired
                          ? 'button'
                          : 'submit'
                      }
                      color="brand-1"
                      className="btn-block-sm-down crxdo-form-btn-last"
                      onClick={() => {
                        form.change(
                          'updateStepAction',
                          STEP_ACTION_COMPLETE_ENROLLMENT
                        );
                      }}
                      disabled={!practiceUser && baaSignatureRequired}
                    >
                      {submitting &&
                      stepAction === STEP_ACTION_COMPLETE_ENROLLMENT ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : baaSignatureRequiredForPracticeUser ? (
                        language.GENERAL_SAVE_AND_CONTINUE_BTN
                      ) : (
                        language.GENERAL_FINISH_BTN
                      )}
                    </Button>

                    <Button
                      type={pristine ? 'button' : 'submit'}
                      outline
                      color="secondary"
                      className="btn-block-sm-down crxdo-form-btn-auto-wide crxdo-form-btn-left"
                      onClick={
                        pristine
                          ? () => handleStepBackwards(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_BACK);
                            }
                      }
                      disabled={submitting}
                    >
                      {submitting && stepAction === STEP_ACTION_BACK ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_BACK_BTN
                      )}
                    </Button>

                    <EnrollmentEjectButton
                      id="summary-send-to-primary-button"
                      stepAction={stepAction}
                      submitting={submitting}
                      disabled={!canCompleteEnrollment()}
                      form={form}
                      userGuid={currentUserGuid}
                      enrollmentData={enrollmentData}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        />

        <BrandFooter />
      </div>
    );
  };

  return render();
};

export const formValidateSummary = values => {
  const errors = {};

  return errors;
};

const Summary = withRouter(SummaryView);

export default Summary;
