import React, { useState } from 'react';
import './ViewDebug.scss';
import { Button } from 'reactstrap';

const ViewDebug = ({ children, className }) => {
  const [debugCollapsed, setDebugCollapsed] = useState(false);

  if (!children || process.env.NODE_ENV !== 'development') {
    return null;
  }

  const toggleViewDebug = () => {
    setDebugCollapsed(!debugCollapsed);
  };

  return (
    <code className={`crxdo-view-debug ${className}`}>
      <Button
        type="button"
        color="link"
        className="btn-sm crxdo-view-debug-collapse-btn"
        onClick={toggleViewDebug}
      >
        {debugCollapsed ? (
          <span className="crxdo-view-debug-collapse-btn-icon" title="Expand">
            &laquo;
          </span>
        ) : (
          <span className="crxdo-view-debug-collapse-btn-icon" title="Collapse">
            &raquo;
          </span>
        )}
      </Button>
      {!debugCollapsed && <div>{children}</div>}
    </code>
  );
};

export default ViewDebug;
