import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, FormGroup, Row } from 'reactstrap';
import formatString from 'format-string-by-pattern';
import brandConfig from '../../brand/brand-config';
import './AddPrescribers.scss';
import {
  postPrescriber,
  patchPrescriber,
  deletePrescriber
} from '../../api/index';
import { useConfig } from '../../config';
import {
  isNullOrUndefinedOrEmpty,
  getPrescriberSuffix,
  useMount,
} from '../../utils';
import {
  composeFieldValidators,
  FIELD_REQUIRED_ERROR,
  FIELD_EXACT_LENGTH_ERROR,
  validateFieldPhone,
  validateFieldRequired
} from '../../utils/form';
import {
  STEP_ACTION_NEXT,
  STEP_ACTION_ADD_NEW_PRESCRIBER,
  STEP_ACTION_UDPATE_PRESCRIBER,
  STEP_ACTION_EJECT,
  STEP_ACTION_BACK
} from '../../constants/StepActions';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import BrandFooter from '../shared/BrandFooter/BrandFooter';
import AlertError from '../shared/AlertError/AlertError';
import deleteIcon from '../../images/delete.svg';
import ValidateInputText from '../shared/ValidateInputText/ValidateInputText';
import EnrollmentEjectButton from '../shared/EnrollmentEjectButton/EnrollmentEjectButton';
import ViewDebug from '../shared/ViewDebug/ViewDebug';
import { phoneMask } from '../../constants/InputMasks';
import ValidateSelect from '../shared/ValidateSelect/ValidateSelect';
import { formatPrescriberData, stripNonDigits } from '../../utils/format';
import PrescriberHighlights from '../shared/PrescriberHighlights/PrescriberHighlights';
import { MINIMUM_PRESCRIBERS_LENGTH } from '../../constants/Requirements';
import validateFieldExactLength from '../../utils/form/validateFieldExactLength';

export const ADD_PRESCRIBERS_ROUTE_NAME = 'add-prescribers';

const NEW_PRESCRIBER_ID = null;

const defaultNewPrescriber = { prescriberId: NEW_PRESCRIBER_ID };

export const AddPrescribersView = ({
  enrollmentData,
  stepDetails,
  match,
  onRequestEnrollmentStart,
  onEjectEnrollment,
  onStepBackwards,
  onSkipStep,
  onStepComplete,
  onEnrollmentDataUpdated,
  enrollmentError,
  onClearEnrollmentError
}) => {
  const config = useConfig();
  const [error, setError] = useState(null);
  const [currentUserGuid, setCurrentUserGuid] = useState(null);
  const [enrollmentGuid, setEnrollmentGuid] = useState(null);
  const [formattedCurrentPrescriber, setFormattedCurrentPrescriber] = useState(
    {}
  );
  const [currentPrescriberIndex, setCurrentPrescriberIndex] = useState(0);
  const [formattedPrescribers, setFormattedPrescribers] = useState([]);
  const [stepReady, setStepReady] = useState(false);
  const [stepAction, setStepAction] = useState(STEP_ACTION_NEXT);

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const {
      enrollmentGuid,
      salesRepresentativeGuid,
      practiceAdminGuid
    } = enrollmentData;

    const hasNoGuid = isNullOrUndefinedOrEmpty(guid);
    const hasNoEnrollmentData =
      !enrollmentGuid && !salesRepresentativeGuid && !practiceAdminGuid;

    if (
      hasNoGuid ||
      hasNoEnrollmentData ||
      (guid !== salesRepresentativeGuid && guid !== practiceAdminGuid)
    ) {
      onRequestEnrollmentStart(guid);
    } else {
      initializeEnrollmentDataStateForPrescribers(guid);
    }
  });

  const initializeEnrollmentDataStateForPrescribers = guid => {
    const { enrollmentGuid, prescribers } = enrollmentData;

    const initPrescriberIndex =
      prescribers && prescribers.length > 0 ? prescribers.length : 0;

    const initFormattedPrescribers = prescribers.map(prescriber => {
      return formatPrescriberData(prescriber);
    });

    const initFormattedCurrentPrescriber = initFormattedPrescribers[
      initPrescriberIndex
    ] || { ...defaultNewPrescriber };

    setEnrollmentGuid(enrollmentGuid);
    setCurrentUserGuid(guid);
    setCurrentPrescriberIndex(initPrescriberIndex);
    setFormattedCurrentPrescriber(initFormattedCurrentPrescriber);
    setFormattedPrescribers(initFormattedPrescribers);
    setTimeout(() => {
      setStepReady(true);
    }, 300);
  };

  const isStepComplete = prescribersArray => {
    return (
      Array.isArray(prescribersArray) &&
      prescribersArray.length >= MINIMUM_PRESCRIBERS_LENGTH
    );
  };

  const getUpdatedEnrollmentData = updatedPrescribers => {
    const updatedEnrollmentData = Object.assign(
      { ...enrollmentData },
      { prescribers: updatedPrescribers }
    );

    return updatedEnrollmentData;
  };

  const onPrescribersUpdated = updatedPrescribers => {
    const updatedEnrollmentData = getUpdatedEnrollmentData(updatedPrescribers);
    const stepCompleteOnPrescribersChange = isStepComplete(updatedPrescribers);
    setFormattedPrescribers(updatedPrescribers);
    onEnrollmentDataUpdated(
      ADD_PRESCRIBERS_ROUTE_NAME,
      currentUserGuid,
      stepCompleteOnPrescribersChange,
      updatedEnrollmentData
    );
  };

  const handleDeletePrescriberById = async prescriberId => {
    const onHandleDeleteSuccess = apiValues => {
      if (apiValues && Array.isArray(apiValues)) {
        const updatedCurrentPrescriberIndex = currentPrescriberIndex - 1;
        onPrescribersUpdated(apiValues);
        setCurrentPrescriberIndex(updatedCurrentPrescriberIndex);
      }
    };

    const onHandleDeleteFailure = error => {
      setError(error);
    };

    return await deletePrescriber(enrollmentGuid, prescriberId, config)
      .then(onHandleDeleteSuccess)
      .catch(onHandleDeleteFailure);
  };

  const handleStepBackwards = form => {
    if (form && form.reset) {
      form.reset();
    }

    onStepBackwards(ADD_PRESCRIBERS_ROUTE_NAME, currentUserGuid, false);
  };

  const handleSkipStep = form => {
    if (form && form.reset) {
      form.reset();
    }

    onSkipStep(ADD_PRESCRIBERS_ROUTE_NAME, currentUserGuid, false);
  };

  const handleStepSubmit = async (formValues, form) => {
    const { prescriberId, faxMasked, updateStepAction } = formValues;

    const isPrescriberNew =
      isNullOrUndefinedOrEmpty(prescriberId) ||
      prescriberId === NEW_PRESCRIBER_ID;

    setError(null);
    setStepAction(updateStepAction);
    onClearEnrollmentError();

    const maskRemovedValues = {
      fax: stripNonDigits(faxMasked)
    };
    const formValuesToApi = Object.assign(formValues, maskRemovedValues);

    const onHandleSubmitFailure = error => {
      setError(error);
      setStepAction(STEP_ACTION_NEXT);
    };

    const onHandleSubmitSuccess = apiValues => {
      const { prescribers } = enrollmentData;

      const updatedPrescribers = [...prescribers];

      if (isPrescriberNew) {
        updatedPrescribers.push(apiValues);
      } else {
        // FIXME: update edited prescriber with apiValues
      }

      const updatedEnrollmentData = getUpdatedEnrollmentData(
        updatedPrescribers
      );

      if (
        (updateStepAction === STEP_ACTION_ADD_NEW_PRESCRIBER ||
          updateStepAction === STEP_ACTION_UDPATE_PRESCRIBER) &&
        updatedPrescribers &&
        Array.isArray(updatedPrescribers)
      ) {
        const updatedCurrentPrescriberIndex = currentPrescriberIndex + 1;
        onPrescribersUpdated(updatedPrescribers);
        setCurrentPrescriberIndex(updatedCurrentPrescriberIndex);
        setFormattedCurrentPrescriber({ ...defaultNewPrescriber });
      } else if (updateStepAction === STEP_ACTION_EJECT) {
        onEjectEnrollment(
          ADD_PRESCRIBERS_ROUTE_NAME,
          currentUserGuid,
          enrollmentGuid,
          updateStepAction,
          config
        );
      } else {
        onStepComplete(
          ADD_PRESCRIBERS_ROUTE_NAME,
          currentUserGuid,
          updateStepAction,
          updatedEnrollmentData
        );
      }

      form.reset();

      return true;
    };

    return isPrescriberNew
      ? await postPrescriber(formValuesToApi, enrollmentGuid, config)
          .then(onHandleSubmitSuccess)
          .catch(onHandleSubmitFailure)
      : await patchPrescriber(
          formValuesToApi,
          enrollmentGuid,
          prescriberId,
          config
        )
          .then(onHandleSubmitSuccess)
          .catch(onHandleSubmitFailure);
  };

  const canSkipNewPrescriberValidation = (formValues, pristine) => {
    const { prescribers } = enrollmentData;
    const hasMinimumPrescribers =
      Array.isArray(prescribers) &&
      prescribers.length >= MINIMUM_PRESCRIBERS_LENGTH;
    let canSkipValidation = false;

    if (
      hasMinimumPrescribers &&
      formValues.prescriberId === NEW_PRESCRIBER_ID
    ) {
      const formValuesKeys = Object.keys(formValues);

      canSkipValidation =
        !formValuesKeys.includes('firstName') &&
        !formValuesKeys.includes('lastName') &&
        !formValuesKeys.includes('suffix') &&
        !formValuesKeys.includes('npi') &&
        !formValuesKeys.includes('stateLicense') &&
        !formValuesKeys.includes('faxMasked');
    }

    return canSkipValidation;
  };

  const isSubmitDisabled = (
    stepComplete,
    prescribers,
    pristine,
    submitting
  ) => {
    const hasMinimumPrescibers = isStepComplete(prescribers);
    return (
      (stepComplete || hasMinimumPrescibers ? false : pristine) || submitting
    );
  };

  const onSkipSubmitEject = async () => {
    await onEjectEnrollment(
      ADD_PRESCRIBERS_ROUTE_NAME,
      currentUserGuid,
      enrollmentGuid,
      STEP_ACTION_EJECT,
      config
    );
  };

  const render = () => {
    const { stepComplete } = stepDetails || {};
    const enrollmentParams = match['params'];
    const { language } = brandConfig;

    if (!stepReady || !formattedCurrentPrescriber) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    const isNewPrescriber =
      formattedCurrentPrescriber.prescriberId === NEW_PRESCRIBER_ID;

    return (
      <div className="crxdo-add-prescribers">
        <div className="container">
          <h1 className="h2 text-primary crxdo-heading">{language.ADD_PRESCRIBERS_TITLE}</h1>
        </div>
        <div className="crxdo-form-top-notes pt-2 px-2 pb-3">
          <div className="container crxdo-body">
            <p>{language.ADD_PRESCRIBERS_FORM_NOTES}</p>
          </div>
        </div>

        <Form
          validateOnBlur={false}
          initialValues={formattedCurrentPrescriber}
          onSubmit={handleStepSubmit}
          validate={formValidateAddPrescribers}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            const canSkipValidation = canSkipNewPrescriberValidation(values);

            return (
              <form
                onSubmit={handleSubmit}
                className="crxdo-form"
                autoComplete="off"
                noValidate={true}
              >
                <div className="container">
                  {(error || enrollmentError) && (
                    <AlertError
                      error={error || enrollmentError}
                      scrollToError={true}
                    />
                  )}

                  <div className="crxdo-form-card card">
                    <div
                      className="crxdo-form-card-header card-header crxdo-heading"
                      role="heading"
                    >
                      {language.ADD_PRESCRIBERS_SUB_TITLE}
                    </div>

                    <div className="crxdo-add-prescriber-highlights">
                      {formattedPrescribers &&
                        formattedPrescribers.map((prescriber, index) => {
                          const { prescriberId } = prescriber;
                          return (
                            <div
                              className="crxdo-add-prescriber-highlight"
                              key={prescriberId}
                            >
                              <span className="crxdo-form-item-badge badge badge-pill badge-medium">
                                {index + 1}
                              </span>

                              <PrescriberHighlights prescriber={prescriber} />

                              <div className="crxdo-add-prescriber-highlight-actions">
                                <button
                                  type="button"
                                  className="crxdo-add-prescriber-highlight-action"
                                  title={language.GENERAL_DELETE_BTN}
                                  aria-label={language.GENERAL_DELETE_BTN}
                                  onClick={() =>
                                    handleDeletePrescriberById(prescriberId)
                                  }
                                >
                                  <img
                                    src={deleteIcon}
                                    alt={language.GENERAL_DELETE_BTN}
                                  />
                                </button>
                              </div>
                            </div>
                          );
                        })}

                      <div className="crxdo-add-prescriber-highlight">
                        <span className="crxdo-form-item-badge badge badge-pill badge-medium">
                          {currentPrescriberIndex + 1}
                        </span>
                      </div>
                    </div>

                    <div className="card-body">
                      <Row className="pb-4">
                        <div className="col-12 col-md-4 col-lg-4">
                          <FormGroup>
                            <ValidateInputText
                              id="firstName"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_FIRST_NAME"
                              placeHolderTextLanguageKey="GENERAL_FIELD_FIRST_NAME_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              maxLength={50}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5">
                          <FormGroup>
                            <ValidateInputText
                              id="lastName"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_LAST_NAME"
                              placeHolderTextLanguageKey="GENERAL_FIELD_LAST_NAME_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              maxLength={50}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                          <FormGroup>
                            <ValidateSelect
                              id="suffix"
                              labelTextLanguageKey="GENERAL_FIELD_SUFFIX"
                              placeHolderTextLanguageKey="GENERAL_FIELD_SUFFIX_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              autoComplete="none"
                              required={true}
                              options={getPrescriberSuffix()}
                              optionNameKey="name"
                              optionValueKey="code"
                            />
                          </FormGroup>
                        </div>
                      </Row>

                      <Row className="pb-4">
                        <div className="col-12 col-md-6 col-lg-6">
                          <FormGroup>
                            <ValidateInputText
                              id="npi"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_NPI"
                              placeHolderTextLanguageKey="GENERAL_FIELD_NPI_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired,
                                validateFieldExactLength(10)
                              )}
                              minLength={10}
                              maxLength={10}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                          <FormGroup>
                            <ValidateInputText
                              id="stateLicense"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_STATE_LICENSE"
                              placeHolderTextLanguageKey="GENERAL_FIELD_STATE_LICENSE_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              maxLength={150}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                          <FormGroup>
                            <ValidateInputText
                              id="faxMasked"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_FAX"
                              placeHolderTextLanguageKey="GENERAL_FIELD_FAX_PLACEHOLDER"
                              parse={formatString(phoneMask.parse)}
                              validate={composeFieldValidators(
                                validateFieldRequired,
                                validateFieldPhone
                              )}
                              maxLength={14}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-12 col-md-6 col-lg-6 crxdo-form-buttons-column">
                          <div className="crxdo-form-buttons crxdo-form-buttons-left">
                            <div className="crxdo-form-btn-container">
                              <Button
                                type="button"
                                outline
                                color="secondary"
                                className="btn-block-sm-down crxdo-form-btn-auto-wide"
                                onClick={form.reset}
                                disabled={submitting}
                              >
                                {language.GENERAL_CANCEL_BTN}
                              </Button>

                              {isNewPrescriber ? (
                                <Button
                                  type="submit"
                                  color="brand-2"
                                  className="btn-block-sm-down"
                                  onClick={() => {
                                    form.change(
                                      'updateStepAction',
                                      STEP_ACTION_ADD_NEW_PRESCRIBER
                                    );
                                  }}
                                  disabled={isSubmitDisabled(
                                    stepComplete,
                                    formattedPrescribers,
                                    pristine,
                                    submitting
                                  )}
                                >
                                  {submitting &&
                                  stepAction ===
                                    STEP_ACTION_ADD_NEW_PRESCRIBER ? (
                                    <SubmitButtonSpinner
                                      submitting={submitting}
                                    />
                                  ) : (
                                    language.GENERAL_ADD_ANOTHER_PRESCIBER_BTN
                                  )}
                                </Button>
                              ) : (
                                <Button
                                  type="submit"
                                  color="brand-2"
                                  className="btn-block-sm-down"
                                  onClick={() => {
                                    form.change(
                                      'updateStepAction',
                                      STEP_ACTION_UDPATE_PRESCRIBER
                                    );
                                  }}
                                  disabled={isSubmitDisabled(
                                    stepComplete,
                                    formattedPrescribers,
                                    pristine,
                                    submitting
                                  )}
                                >
                                  {submitting &&
                                  stepAction ===
                                    STEP_ACTION_UDPATE_PRESCRIBER ? (
                                    <SubmitButtonSpinner
                                      submitting={submitting}
                                    />
                                  ) : (
                                    language.GENERAL_UPDATE_PRESCRIBER_BTN
                                  )}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="crxdo-form-buttons crxdo-form-buttons-border-tb crxdo-form-buttons-stretch">
                  <div className="container crxdo-form-btn-container">
                    <Button
                      type={canSkipValidation ? 'button' : 'submit'}
                      color="brand-1"
                      className="btn-block-sm-down crxdo-form-btn-last"
                      onClick={
                        canSkipValidation
                          ? () => handleSkipStep(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_NEXT);
                            }
                      }
                      disabled={isSubmitDisabled(
                        stepComplete,
                        formattedPrescribers,
                        pristine,
                        submitting
                      )}
                    >
                      {submitting && stepAction === STEP_ACTION_NEXT ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_SAVE_AND_CONTINUE_BTN
                      )}
                    </Button>

                    <Button
                      type={canSkipValidation || pristine ? 'button' : 'submit'}
                      outline
                      color="secondary"
                      className="btn-block-sm-down crxdo-form-btn-auto-wide crxdo-form-btn-left"
                      onClick={
                        canSkipValidation || pristine
                          ? () => handleStepBackwards(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_BACK);
                            }
                      }
                      disabled={submitting}
                    >
                      {submitting && stepAction === STEP_ACTION_BACK ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_BACK_BTN
                      )}
                    </Button>

                    <EnrollmentEjectButton
                      id="add-prescribers-send-to-primary-button"
                      skipSubmit={canSkipValidation}
                      onSkipSubmit={onSkipSubmitEject}
                      stepAction={stepAction}
                      submitting={submitting}
                      disabled={isSubmitDisabled(
                        stepComplete,
                        formattedPrescribers,
                        pristine,
                        submitting
                      )}
                      form={form}
                      userGuid={enrollmentParams['guid']}
                      enrollmentData={enrollmentData}
                    />
                  </div>
                </div>

                <ViewDebug className="crxdo-view-debug-bottom-right">
                  <div className="h4" role="heading">
                    Form Values & Step Details & config
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">form values</span>=
                    {JSON.stringify(values)}
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">stepDetails</span>=
                    {JSON.stringify(stepDetails)}
                  </div>
                  {config && (
                    <div className="mb-2">
                      <span className="font-weight-bold">config</span>=
                      {JSON.stringify(config)}
                    </div>
                  )}
                </ViewDebug>
              </form>
            );
          }}
        />

        <BrandFooter />
      </div>
    );
  };

  return render();
};

export const formValidateAddPrescribers = values => {
  const errors = {};
  const { firstName, lastName, suffix, npi, stateLicense, faxMasked } = values;
  if (!firstName) {
    errors.firstName = FIELD_REQUIRED_ERROR;
  }
  if (!lastName) {
    errors.lastName = FIELD_REQUIRED_ERROR;
  }
  if (!suffix) {
    errors.suffix = FIELD_REQUIRED_ERROR;
  }
  if (!npi) {
    errors.npi = FIELD_REQUIRED_ERROR;
  }
  if (npi && (npi.length < 10 || npi.length > 10)) {
    errors.npi = FIELD_EXACT_LENGTH_ERROR(10);
  }
  if (!stateLicense) {
    errors.stateLicense = FIELD_REQUIRED_ERROR;
  }
  if (!faxMasked) {
    errors.faxMasked = FIELD_REQUIRED_ERROR;
  }
  return errors;
};

const AddPrescribers = withRouter(AddPrescribersView);

export default AddPrescribers;
