import { isPracticeUser } from './';
import { isBaaSignatureRequired } from './';

const isBaaSignatureRequiredForPracticeUser = (
  enrollmentData,
  userGuid,
  config
) => {
  const practiceUser = isPracticeUser(enrollmentData, userGuid);
  const baaSignatureRequired = isBaaSignatureRequired(enrollmentData, config);

  return practiceUser ? baaSignatureRequired : false;
};

export default isBaaSignatureRequiredForPracticeUser;
