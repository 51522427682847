import brandConfig from '../../brand/brand-config.json';

export const FIELD_PHONE_ERROR = brandConfig.language.GENERAL_ERROR_FIELD_PHONE;

const validateFieldPhone = value => {
  const phoneRegex = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/i;

  return value
    ? phoneRegex.test(value)
      ? undefined
      : FIELD_PHONE_ERROR
    : undefined;
};

export default validateFieldPhone;
