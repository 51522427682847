import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, FormGroup, Row } from 'reactstrap';
import formatString from 'format-string-by-pattern';
import brandConfig from '../../brand/brand-config';
import './AddUsers.scss';
import { postUser, patchUser, deleteUser } from '../../api/index';
import { useConfig } from '../../config';
import {
  isNullOrUndefinedOrEmpty,
  getUserRole,
  getUserType,
  useMount
} from '../../utils';
import {
  composeFieldValidators,
  FIELD_REQUIRED_ERROR,
  validateFieldEmail,
  validateFieldPhone,
  validateFieldRequired
} from '../../utils/form';
import {
  STEP_ACTION_NEXT,
  STEP_ACTION_ADD_NEW_USER,
  STEP_ACTION_UDPATE_USER,
  STEP_ACTION_EJECT,
  STEP_ACTION_BACK
} from '../../constants/StepActions';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import BrandFooter from '../shared/BrandFooter/BrandFooter';
import AlertError from '../shared/AlertError/AlertError';
import deleteIcon from '../../images/delete.svg';
import ValidateInputText from '../shared/ValidateInputText/ValidateInputText';
import EnrollmentEjectButton from '../shared/EnrollmentEjectButton/EnrollmentEjectButton';
import ViewDebug from '../shared/ViewDebug/ViewDebug';
import { phoneMask } from '../../constants/InputMasks';
import ValidateSelect from '../shared/ValidateSelect/ValidateSelect';
import { formatUserData, stripNonDigits } from '../../utils/format';
import UserHighlights from '../shared/UserHighlights/UserHighlights';
import { MINIMUM_USERS_LENGTH } from '../../constants/Requirements';

export const ADD_USERS_ROUTE_NAME = 'add-users';

const NEW_USER_ID = null;

const defaultNewUser = { userId: NEW_USER_ID };

export const AddUsersView = ({
  enrollmentData,
  stepDetails,
  match,
  onRequestEnrollmentStart,
  onEjectEnrollment,
  onStepBackwards,
  onSkipStep,
  onStepComplete,
  onEnrollmentDataUpdated,
  enrollmentError,
  onClearEnrollmentError
}) => {
  const config = useConfig();
  const [error, setError] = useState(null);
  const [currentUserGuid, setCurrentUserGuid] = useState(null);
  const [enrollmentGuid, setEnrollmentGuid] = useState(null);
  const [formattedCurrentUser, setFormattedCurrentUser] = useState({});
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [stepReady, setStepReady] = useState(false);
  const [stepAction, setStepAction] = useState(STEP_ACTION_NEXT);

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const {
      enrollmentGuid,
      salesRepresentativeGuid,
      practiceAdminGuid
    } = enrollmentData;

    const hasNoGuid = isNullOrUndefinedOrEmpty(guid);
    const hasNoEnrollmentData =
      !enrollmentGuid && !salesRepresentativeGuid && !practiceAdminGuid;

    if (
      hasNoGuid ||
      hasNoEnrollmentData ||
      (guid !== salesRepresentativeGuid && guid !== practiceAdminGuid)
    ) {
      onRequestEnrollmentStart(guid);
    } else {
      initializeEnrollmentDataStateForUsers(guid);
    }
  });

  const initializeEnrollmentDataStateForUsers = guid => {
    const { enrollmentGuid, users } = enrollmentData;

    const initUserIndex = users && users.length > 0 ? users.length : 0;

    const initFormattedUsers = users.map(user => {
      return formatUserData(user);
    });

    const initFormattedCurrentUser = initFormattedUsers[initUserIndex] || {
      ...defaultNewUser
    };

    setEnrollmentGuid(enrollmentGuid);
    setCurrentUserGuid(guid);
    setCurrentUserIndex(initUserIndex);
    setFormattedCurrentUser(initFormattedCurrentUser);
    setFormattedUsers(initFormattedUsers);
    setTimeout(() => {
      setStepReady(true);
    }, 300);
  };

  const isStepComplete = usersArray => {
    return (
      Array.isArray(usersArray) && usersArray.length > MINIMUM_USERS_LENGTH
    );
  };

  const getUpdatedEnrollmentData = updatedUsers => {
    const updatedEnrollmentData = Object.assign(
      { ...enrollmentData },
      { users: updatedUsers }
    );

    return updatedEnrollmentData;
  };

  const onUsersUpdated = updatedUsers => {
    const updatedEnrollmentData = getUpdatedEnrollmentData(updatedUsers);
    const stepCompleteOnUsersChange = isStepComplete(updatedUsers);
    setFormattedUsers(updatedUsers);
    onEnrollmentDataUpdated(
      ADD_USERS_ROUTE_NAME,
      currentUserGuid,
      stepCompleteOnUsersChange,
      updatedEnrollmentData
    );
  };

  const handleDeleteUserById = async userId => {
    const onHandleDeleteSuccess = apiValues => {
      if (apiValues && Array.isArray(apiValues)) {
        const updatedCurrentUserIndex = currentUserIndex - 1;
        onUsersUpdated(apiValues);
        setCurrentUserIndex(updatedCurrentUserIndex);
      }
    };

    const onHandleDeleteFailure = error => {
      setError(error);
    };

    return await deleteUser(enrollmentGuid, userId, config)
      .then(onHandleDeleteSuccess)
      .catch(onHandleDeleteFailure);
  };

  const handleStepBackwards = form => {
    if (form && form.reset) {
      form.reset();
    }

    onStepBackwards(ADD_USERS_ROUTE_NAME, currentUserGuid, false);
  };

  const handleSkipStep = form => {
    if (form && form.reset) {
      form.reset();
    }

    onSkipStep(ADD_USERS_ROUTE_NAME, currentUserGuid, false);
  };

  const handleStepSubmit = async (formValues, form) => {
    const { userId, phoneMasked, updateStepAction } = formValues;

    const isUserNew =
      isNullOrUndefinedOrEmpty(userId) || userId === NEW_USER_ID;

    setError(null);
    setStepAction(updateStepAction);
    onClearEnrollmentError();

    const maskRemovedValues = {
      phone: stripNonDigits(phoneMasked)
    };
    const formValuesToApi = Object.assign(formValues, maskRemovedValues);

    const onHandleSubmitFailure = error => {
      setError(error);
      setStepAction(STEP_ACTION_NEXT);
    };

    const onHandleSubmitSuccess = apiValues => {

      const { users } = enrollmentData;

      const updatedUsers = [...users];

      if (isUserNew) {
        updatedUsers.push(apiValues);
      } else {
        // FIXME: update edited user with apiValues
      }

      const updatedEnrollmentData = getUpdatedEnrollmentData(updatedUsers);

      if (
        (updateStepAction === STEP_ACTION_ADD_NEW_USER ||
          updateStepAction === STEP_ACTION_UDPATE_USER) &&
        updatedUsers &&
        Array.isArray(updatedUsers)
      ) {
        const updatedCurrentUserIndex = currentUserIndex + 1;
        onUsersUpdated(updatedUsers);
        setCurrentUserIndex(updatedCurrentUserIndex);
        setFormattedCurrentUser({ ...defaultNewUser });
      } else if (updateStepAction === STEP_ACTION_EJECT) {
        onEjectEnrollment(
          ADD_USERS_ROUTE_NAME,
          currentUserGuid,
          enrollmentGuid,
          updateStepAction,
          config
        );
      } else {
        onStepComplete(
          ADD_USERS_ROUTE_NAME,
          currentUserGuid,
          updateStepAction,
          updatedEnrollmentData
        );
      }

      form.reset();

      return true;
    };

    return isUserNew
      ? await postUser(formValuesToApi, enrollmentGuid, config)
          .then(onHandleSubmitSuccess)
          .catch(onHandleSubmitFailure)
      : await patchUser(formValuesToApi, enrollmentGuid, userId, config)
          .then(onHandleSubmitSuccess)
          .catch(onHandleSubmitFailure);
  };

  const canSkipNewUserValidation = formValues => {
    const { users } = enrollmentData;
    const hasMinimumUsers =
      Array.isArray(users) && users.length >= MINIMUM_USERS_LENGTH;
    let canSkipValidation = false;

    if (hasMinimumUsers && formValues.userId === NEW_USER_ID) {
      const formValuesKeys = Object.keys(formValues);
      canSkipValidation =
        !formValuesKeys.includes('firstName') &&
        !formValuesKeys.includes('lastName') &&
        !formValuesKeys.includes('role') &&
        !formValuesKeys.includes('phoneMasked') &&
        !formValuesKeys.includes('email') &&
        !formValuesKeys.includes('userType');
    }

    return canSkipValidation;
  };

  const isSubmitDisabled = (stepComplete, isNewUser, pristine, submitting) => {
    return (stepComplete || isNewUser ? false : pristine) || submitting;
  };

  const onSkipSubmitEject = async () => {
    await onEjectEnrollment(
      ADD_USERS_ROUTE_NAME,
      currentUserGuid,
      enrollmentGuid,
      STEP_ACTION_EJECT,
      config
    );
  };

  const render = () => {
    const { stepComplete } = stepDetails || {};
    const enrollmentParams = match['params'];
    const { language } = brandConfig;

    if (!stepReady || !formattedCurrentUser) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    const isNewUser = formattedCurrentUser.userId === NEW_USER_ID;

    return (
      <div className="crxdo-add-users">
        <div className="container">
          <h1 className="h2 text-primary crxdo-heading">{language.ADD_USERS_TITLE}</h1>
        </div>
        <div className="crxdo-form-top-notes pt-2 px-2 pb-3">
          <div className="container crxdo-body">
            <p>{language.ADD_USERS_FORM_NOTES}</p>
          </div>
        </div>

        <Form
          validateOnBlur={false}
          initialValues={formattedCurrentUser}
          onSubmit={handleStepSubmit}
          validate={formValidateAddUsers}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            const canSkipValidation = canSkipNewUserValidation(values);

            return (
              <form
                onSubmit={handleSubmit}
                className="crxdo-form"
                autoComplete="off"
                noValidate={true}
              >
                <div className="container">
                  {(error || enrollmentError) && (
                    <AlertError
                      error={error || enrollmentError}
                      scrollToError={true}
                    />
                  )}

                  <div className="crxdo-form-card card">
                    <div
                      className="crxdo-form-card-header card-header crxdo-heading"
                      role="heading"
                    >
                      {language.ADD_USERS_SUB_TITLE}
                    </div>

                    <div className="crxdo-add-user-highlights">
                      {formattedUsers &&
                        formattedUsers.map((user, index) => {
                          const { userId } = user;
                          return (
                            <div
                              className="crxdo-add-user-highlight"
                              key={userId}
                            >
                              <span className="crxdo-form-item-badge badge badge-pill badge-medium">
                                {index + 1}
                              </span>

                              <UserHighlights user={user} />

                              <div className="crxdo-add-user-highlight-actions">
                                <button
                                  type="button"
                                  className="crxdo-add-user-highlight-action"
                                  title={language.GENERAL_DELETE_BTN}
                                  aria-label={language.GENERAL_DELETE_BTN}
                                  onClick={() => handleDeleteUserById(userId)}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt={language.GENERAL_DELETE_BTN}
                                  />
                                </button>
                              </div>
                            </div>
                          );
                        })}

                      <div className="crxdo-add-user-highlight">
                        <span className="crxdo-form-item-badge badge badge-pill badge-medium">
                          {currentUserIndex + 1}
                        </span>
                      </div>
                    </div>

                    <div className="card-body">
                      <Row className="pb-4">
                        <div className="col-12 col-md-4 col-lg-4">
                          <FormGroup>
                            <ValidateInputText
                              id="firstName"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_FIRST_NAME"
                              placeHolderTextLanguageKey="GENERAL_FIELD_FIRST_NAME_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              maxLength={50}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5">
                          <FormGroup>
                            <ValidateInputText
                              id="lastName"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_LAST_NAME"
                              placeHolderTextLanguageKey="GENERAL_FIELD_LAST_NAME_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              maxLength={50}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                          <FormGroup>
                            <ValidateSelect
                              id="role"
                              labelTextLanguageKey="GENERAL_FIELD_USER_ROLE"
                              placeHolderTextLanguageKey="GENERAL_FIELD_USER_ROLE_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              autoComplete="none"
                              required={true}
                              options={getUserRole()}
                              optionNameKey="name"
                              optionValueKey="code"
                            />
                          </FormGroup>
                        </div>
                      </Row>

                      <Row className="pb-4">
                        <div className="col-12 col-md-4 col-lg-4">
                          <FormGroup>
                            <ValidateInputText
                              id="phoneMasked"
                              type="text"
                              labelTextLanguageKey="GENERAL_FIELD_PHONE"
                              placeHolderTextLanguageKey="GENERAL_FIELD_PHONE_PLACEHOLDER"
                              parse={formatString(phoneMask.parse)}
                              validate={composeFieldValidators(
                                validateFieldRequired,
                                validateFieldPhone
                              )}
                              maxLength={14}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5">
                          <FormGroup>
                            <ValidateInputText
                              id="email"
                              type="email"
                              labelTextLanguageKey="GENERAL_FIELD_EMAIL"
                              placeHolderTextLanguageKey="GENERAL_FIELD_EMAIL_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired,
                                validateFieldEmail
                              )}
                              maxLength={150}
                              required={true}
                              autoComplete="none"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3">
                          <FormGroup>
                            <ValidateSelect
                              id="userType"
                              labelTextLanguageKey="GENERAL_FIELD_USER_TYPE"
                              placeHolderTextLanguageKey="GENERAL_FIELD_USER_TYPE_PLACEHOLDER"
                              validate={composeFieldValidators(
                                validateFieldRequired
                              )}
                              autoComplete="none"
                              required={true}
                              options={getUserType()}
                              optionNameKey="name"
                              optionValueKey="code"
                            />
                          </FormGroup>
                        </div>
                      </Row>

                      <div className="crxdo-form-buttons crxdo-form-buttons-left">
                        <div className="crxdo-form-btn-container">
                          <Button
                            type="button"
                            outline
                            color="secondary"
                            className="btn-block-sm-down crxdo-form-btn-auto-wide"
                            onClick={form.reset}
                            disabled={submitting}
                          >
                            {language.GENERAL_CANCEL_BTN}
                          </Button>

                          {isNewUser ? (
                            <Button
                              type="submit"
                              color="brand-2"
                              className="btn-block-sm-down"
                              onClick={() => {
                                form.change(
                                  'updateStepAction',
                                  STEP_ACTION_ADD_NEW_USER
                                );
                              }}
                              disabled={isSubmitDisabled(
                                stepComplete,
                                isNewUser,
                                pristine,
                                submitting
                              )}
                            >
                              {submitting &&
                              stepAction === STEP_ACTION_ADD_NEW_USER ? (
                                <SubmitButtonSpinner submitting={submitting} />
                              ) : (
                                language.GENERAL_ADD_ANOTHER_PRESCIBER_BTN
                              )}
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              color="brand-2"
                              className="btn-block-sm-down"
                              onClick={() => {
                                form.change(
                                  'updateStepAction',
                                  STEP_ACTION_UDPATE_USER
                                );
                              }}
                              disabled={isSubmitDisabled(
                                stepComplete,
                                isNewUser,
                                pristine,
                                submitting
                              )}
                            >
                              {submitting &&
                              stepAction === STEP_ACTION_UDPATE_USER ? (
                                <SubmitButtonSpinner submitting={submitting} />
                              ) : (
                                language.GENERAL_UPDATE_USER_BTN
                              )}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="crxdo-form-buttons crxdo-form-buttons-border-tb crxdo-form-buttons-stretch">
                  <div className="container crxdo-form-btn-container">
                    <Button
                      type={canSkipValidation ? 'button' : 'submit'}
                      color="brand-1"
                      className="btn-block-sm-down crxdo-form-btn-last"
                      onClick={
                        canSkipValidation
                          ? () => handleSkipStep(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_NEXT);
                            }
                      }
                      disabled={isSubmitDisabled(
                        stepComplete,
                        isNewUser,
                        pristine,
                        submitting
                      )}
                    >
                      {submitting && stepAction === STEP_ACTION_NEXT ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_SAVE_AND_CONTINUE_BTN
                      )}
                    </Button>

                    <Button
                      type={canSkipValidation || pristine ? 'button' : 'submit'}
                      outline
                      color="secondary"
                      className="btn-block-sm-down crxdo-form-btn-auto-wide crxdo-form-btn-left"
                      onClick={
                        canSkipValidation || pristine
                          ? () => handleStepBackwards(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_BACK);
                            }
                      }
                      disabled={submitting}
                    >
                      {submitting && stepAction === STEP_ACTION_BACK ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_BACK_BTN
                      )}
                    </Button>

                    <EnrollmentEjectButton
                      id="add-users-send-to-primary-button"
                      skipSubmit={canSkipValidation}
                      onSkipSubmit={onSkipSubmitEject}
                      stepAction={stepAction}
                      submitting={submitting}
                      disabled={isSubmitDisabled(
                        stepComplete,
                        isNewUser,
                        pristine,
                        submitting
                      )}
                      form={form}
                      userGuid={enrollmentParams['guid']}
                      enrollmentData={enrollmentData}
                    />
                  </div>
                </div>

                <ViewDebug className="crxdo-view-debug-bottom-right">
                  <div className="h4" role="heading">
                    Form Values & Step Details & config
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">form values</span>=
                    {JSON.stringify(values)}
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">stepDetails</span>=
                    {JSON.stringify(stepDetails)}
                  </div>
                  {config && (
                    <div className="mb-2">
                      <span className="font-weight-bold">config</span>=
                      {JSON.stringify(config)}
                    </div>
                  )}
                </ViewDebug>
              </form>
            );
          }}
        />

        <BrandFooter />
      </div>
    );
  };

  return render();
};

export const formValidateAddUsers = values => {
  const errors = {};
  const { firstName, lastName, role, phoneMasked, email, userType } = values;

  if (!firstName) {
    errors.firstName = FIELD_REQUIRED_ERROR;
  }
  if (!lastName) {
    errors.lastName = FIELD_REQUIRED_ERROR;
  }
  if (!role) {
    errors.role = FIELD_REQUIRED_ERROR;
  }
  if (!phoneMasked) {
    errors.phoneMasked = FIELD_REQUIRED_ERROR;
  }
  if (!email) {
    errors.email = FIELD_REQUIRED_ERROR;
  } else {
    errors.email = validateFieldEmail(email);
  }
  if (!userType) {
    errors.userType = FIELD_REQUIRED_ERROR;
  }
  return errors;
};

const AddUsers = withRouter(AddUsersView);

export default AddUsers;
