import { formatPhoneNumber, formatZip } from './';

const formatLocationData = locationData => {
  if (!locationData) {
    return null;
  }

  const formattedLocationData = {
    ...locationData,
    zipCodeMasked: formatZip(locationData ? locationData.zipCode : ''),
    phoneMasked: formatPhoneNumber(locationData ? locationData.phone : ''),
    faxMasked: formatPhoneNumber(locationData ? locationData.fax : '')
  };

  return formattedLocationData;
};

export default formatLocationData;
