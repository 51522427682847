import React from 'react';
import PropTypes from 'prop-types';
import './ValidateTextarea.scss';
import FieldRequiredIcon from '../FieldRequiredIcon/FieldRequiredIcon';
import FieldError from '../FieldError/FieldError';
import { Field } from 'react-final-form';
import brandConfig from '../../../brand/brand-config.json';

const ValidateTextarea = ({
  id,
  className,
  autoComplete,
  validate,
  validateFields,
  minLength,
  maxLength,
  required,
  disabled,
  labelTextLanguageKey,
  placeHolderTextLanguageKey,
  optionalText,
  showOptionalText
}) => {
  if (!id || !labelTextLanguageKey) {
    return null;
  }

  const { language } = brandConfig;
  const labelText = language[labelTextLanguageKey];
  const placeHolderText = placeHolderTextLanguageKey
    ? language[placeHolderTextLanguageKey]
    : null;
  const isDisabled = disabled || false;
  const isRequired = (required && !isDisabled) || false;

  return (
    <Field
      name={id}
      validate={validate}
      validateFields={validateFields}
      component="textarea"
    >
      {({ input, meta }) => {
        const fieldHasError = meta.error && meta.touched && !isDisabled;

        return (
          <div className={`crdo-validate-textarea ${className}`}>
            <label htmlFor={id} className="crxdo-label">
              {labelText}
              {required && <FieldRequiredIcon />}
            </label>
            {showOptionalText && optionalText && (
              <div
                className="crxdo-form-field-description small ml-0 mb-4 crxdo-body"
                role="status"
              >
                {optionalText}
              </div>
            )}
            <textarea
              {...input}
              id={id}
              className="form-control"
              autoComplete={autoComplete}
              placeholder={placeHolderText}
              minLength={minLength}
              maxLength={maxLength}
              required={isRequired}
              disabled={isDisabled}
              aria-required={isRequired}
              aria-invalid={fieldHasError}
              aria-describedby={fieldHasError ? `${id}_error` : null}
            />

            <FieldError fieldMeta={meta} id={`${id}_error`} />
          </div>
        );
      }}
    </Field>
  );
};

ValidateTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  validate: PropTypes.func,
  validateFields: PropTypes.array,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  labelTextLanguageKey: PropTypes.string.isRequired,
  placeHolderTextLanguageKey: PropTypes.string,
  optionalText: PropTypes.string,
  showOptionalText: PropTypes.bool
};

export default ValidateTextarea;
