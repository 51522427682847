import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import brandConfig from '../../../brand/brand-config';
import './AlertError.scss';

class AlertError extends Component {
  constructor(props) {
    super(props);

    this.errorRef = React.createRef();
  }

  componentDidMount() {
    this.checkScrolling();
  }

  checkScrolling() {
    const { error, scrollToError } = this.props;
    const { offsetTop } = this.errorRef.current || {};

    if (error && scrollToError && offsetTop) {
      window.scrollTo(0, offsetTop);
    }
  }

  render() {
    const { error } = this.props;
    const { language } = brandConfig;

    if (!error) {
      return null;
    }

    return (
      <div ref={this.errorRef} className="crxdo-alert-error">
        <Alert color="danger">
          <div role="heading" className="h5 crxdo-alert-error-heading">
            {language.GENERAL_ERROR_TITLE}
          </div>
          {error.message && (
            <div className="crxdo-alert-error-message">{error.message}</div>
          )}
        </Alert>
      </div>
    );
  }
}

AlertError.propTypes = {
  error: PropTypes.object,
  scrollToError: PropTypes.bool
};

export default AlertError;
