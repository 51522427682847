import React from 'react';
import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';
import brandConfig from '../../../brand/brand-config.json';
import './ServiceInventoryManagementFields.scss';
import { Row } from 'reactstrap';
import ValidateCheckboxBoolean from '../ValidateCheckboxBoolean/ValidateCheckboxBoolean';

const ServiceInventoryManagementFields = ({
  enrollmentData,
  form,
  formValues,
  hasInventoryManagementService,
  availableBrandServices,
  isDisabled
}) => {
  if (
    !enrollmentData ||
    !form ||
    !formValues ||
    !hasInventoryManagementService ||
    !availableBrandServices
  ) {
    return null;
  }

  const getInventoryManagementWarning = () => {
    const availableServicesMessageArray = [];
    const { language } = brandConfig;

    const {
      serviceOnlineProductOrdering,
      serviceFaxOnly,
      serviceBenefitsVerificationPortal
    } = availableBrandServices;

    if (serviceBenefitsVerificationPortal) {
      availableServicesMessageArray.push(
        language['SERVICES_SELECTION_BV_VERIFICATION']
      );
    }
    if (serviceOnlineProductOrdering) {
      availableServicesMessageArray.push(
        language['SERVICES_SELECTION_ONLINE_PRODUCT_ORDERING']
      );
    }
    if (serviceFaxOnly) {
      availableServicesMessageArray.push(
        language['SERVICES_SELECTION_FAX_ONLY']
      );
    }

    const availableServicesMessage = availableServicesMessageArray.join(
      ' and/or '
    );

    return language.SERVICES_SELECTION_INVENTORY_MANAGEMENT_DESCRIPTION_2
      ? language.SERVICES_SELECTION_INVENTORY_MANAGEMENT_DESCRIPTION_2.replace(
          '{supportedServices}',
          availableServicesMessage
        )
      : null;
  };

  const handleDependentFieldChange = (whichField, value) => {
    const {
      onlineProductOrdering,
      benefitsVerification,
      faxOnlyServices,
      inventoryManagement
    } = formValues;
    const canHaveInventoryManagement =
      onlineProductOrdering || benefitsVerification || faxOnlyServices;

    if (value === false && inventoryManagement && !canHaveInventoryManagement) {
      form.change('inventoryManagement', false);
    }
  };

  return (
    <Row className="crxdo-service-inventory-management">
      <div className="col-12">
        <ValidateCheckboxBoolean
          name="inventoryManagement"
          labelTextLanguageKey="SERVICES_SELECTION_INVENTORY_MANAGEMENT"
          id="inventoryManagement"
          className="crxdo-form-label-emphasis"
          disabled={isDisabled}
          fieldDescriptionTextLanguageKey="SERVICES_SELECTION_INVENTORY_MANAGEMENT_DESCRIPTION_1"
          optionalText={getInventoryManagementWarning()}
          showOptionalText={true}
        />

        <OnChange name="benefitsVerification">
          {(value, previous) =>
            handleDependentFieldChange('benefitsVerification', value, previous)
          }
        </OnChange>

        <OnChange name="faxOnlyServices">
          {(value, previous) =>
            handleDependentFieldChange('faxOnlyServices', value, previous)
          }
        </OnChange>

        <OnChange name="onlineProductOrdering">
          {(value, previous) =>
            handleDependentFieldChange('onlineProductOrdering', value, previous)
          }
        </OnChange>
      </div>
    </Row>
  );
};

ServiceInventoryManagementFields.propTypes = {
  hasInventoryManagementService: PropTypes.bool.isRequired,
  enrollmentData: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  availableBrandServices: PropTypes.object.isRequired
};

export default ServiceInventoryManagementFields;
