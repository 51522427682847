import React from 'react';
import PropTypes from 'prop-types';
import './NotFound.scss';
import MessageView from '../shared/MessageView/MessageView';

const NotFound = ({ location }) => {
  const { pathname } = location;
  return (
    <div className="crxdo-not-found">
      <MessageView>
        <div role="heading" className="h3">
          NOT FOUND: {pathname}
        </div>
      </MessageView>
    </div>
  );
};

NotFound.propTypes = {
  location: PropTypes.object.isRequired
};

export default NotFound;
