import { isNullOrUndefinedOrEmpty } from '../';
import brandConfig from '../../brand/brand-config';

export const FIELD_EXACT_LENGTH_ERROR = exactLength => {
  return brandConfig.language.GENERAL_ERROR_FIELD_EXACT_LENGTH.replace(
    '{exactLength}',
    exactLength
  );
};

const validateFieldExactLength = exactLength => value => {
  return isNullOrUndefinedOrEmpty(value) || value.length >= exactLength
    ? undefined
    : FIELD_EXACT_LENGTH_ERROR(exactLength);
};

export default validateFieldExactLength;
