import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, FormGroup, Row } from 'reactstrap';
import formatString from 'format-string-by-pattern';
import brandConfig from '../../brand/brand-config';
import './PracticeDemographics.scss';
import {
  getUnitedStates,
  useMount,
  isNullOrUndefinedOrEmpty,
  isEnrollmentExpired
} from '../../utils';
import {
  composeFieldValidators,
  FIELD_MUST_MATCH_VALUE_ERROR,
  FIELD_REQUIRED_ERROR,
  validateFieldEmail,
  validateFieldPhone,
  validateFieldRequired,
  validateFieldZip
} from '../../utils/form';
import { stripNonDigits } from '../../utils/format';
import { zipMask, phoneMask } from '../../constants/InputMasks';
import {
  STEP_ACTION_EJECT,
  STEP_ACTION_ADD_LOCATIONS,
  STEP_ACTION_NEXT
} from '../../constants/StepActions';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import BrandFooter from '../shared/BrandFooter/BrandFooter';
import AlertError from '../shared/AlertError/AlertError';
import { postDemographics, getEnrollment } from '../../api/index';
import { useConfig } from '../../config';
import ValidateInputText from '../shared/ValidateInputText/ValidateInputText';
import ValidateSelect from '../shared/ValidateSelect/ValidateSelect';
import EnrollmentEjectButton from '../shared/EnrollmentEjectButton/EnrollmentEjectButton';
import ViewDebug from '../shared/ViewDebug/ViewDebug';

export const PRACTICE_DEMOGRAPHICS_ROUTE_NAME = 'practice-demographics';

export const PracticeDemographicsView = ({
  enrollmentData,
  stepDetails,
  match,
  onResetEnrollment,
  onEjectEnrollment,
  onStepComplete,
  onShowOptOut,
  onShowExpired,
  onShowEjectStatus,
  onEnrollmentDataUpdated,
  enrollmentError,
  onClearEnrollmentError
}) => {
  const [error, setError] = useState(null);
  const [stepReady, setStepReady] = useState(false);
  const [stepHasApiValues, setStepHasApiValues] = useState(false);
  const [stepAction, setStepAction] = useState(STEP_ACTION_NEXT);
  const config = useConfig();

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const { salesRepresentativeGuid, practiceAdminGuid } = enrollmentData;

    if (
      guid &&
      (salesRepresentativeGuid !== guid || practiceAdminGuid !== guid)
    ) {
      fetchEnrollmentDataForGuid(guid);
    } else {
      onResetEnrollment();
    }
  });

  const fetchEnrollmentDataForGuid = guid => {
    const onHandleGetSuccess = apiValues => {
      if (apiValues) {
        handleEnrollmentDataFetch(guid, apiValues);
      } else {
        onResetEnrollment();
      }
    };

    const onHandleGetFailure = error => {
      onResetEnrollment();
    };

    return getEnrollment(guid, config)
      .then(onHandleGetSuccess)
      .catch(onHandleGetFailure);
  };

  const handleStepSubmit = async formValues => {
    const enrollmentGuid = formValues['enrollmentGuid'];
    const enrollmentParams = match['params'];
    const currentUserGuid = enrollmentParams['guid'];

    const {
      phoneMasked,
      faxMasked,
      zipCodeMasked,
      updateStepAction
    } = formValues;

    setError(null);
    setStepAction(updateStepAction);
    onClearEnrollmentError();

    const maskRemovedValues = {
      phone: stripNonDigits(phoneMasked),
      fax: stripNonDigits(faxMasked),
      zipCode: stripNonDigits(zipCodeMasked)
    };

    const formValuesToApi = Object.assign(formValues, maskRemovedValues);

    const onHandleSubmitFailure = error => {
      setError(error);
      setStepAction(STEP_ACTION_NEXT);
    };

    const onHandleSubmitSuccess = apiValues => {
      if (updateStepAction === STEP_ACTION_EJECT) {
        onEjectEnrollment(
          PRACTICE_DEMOGRAPHICS_ROUTE_NAME,
          currentUserGuid,
          enrollmentGuid,
          updateStepAction,
          config
        );
      } else {
        onStepComplete(
          PRACTICE_DEMOGRAPHICS_ROUTE_NAME,
          currentUserGuid,
          updateStepAction,
          apiValues
        );
      }

      return true;
    };

    return await postDemographics(formValuesToApi, enrollmentGuid, config)
      .then(onHandleSubmitSuccess)
      .catch(onHandleSubmitFailure);
  };

  const handleEnrollmentDataFetch = (guid, enrollmentDataFromApi) => {
    const stepComplete = false;
    const {
      primaryContactFullName,
      practiceName,
      salesRepOptOutDate,
      submittedToPrimaryContactDate,
      salesRepresentativeGuid,
      primaryContactOptOutDate,
      practiceAdminGuid
    } = enrollmentDataFromApi;

    const currentSalesUserOptedOut =
      !isNullOrUndefinedOrEmpty(salesRepOptOutDate) &&
      guid === salesRepresentativeGuid;
    const currentPracticeUserOptedOut =
      !isNullOrUndefinedOrEmpty(primaryContactOptOutDate) &&
      guid === practiceAdminGuid;
    const currentSalesUserEjected =
      !isNullOrUndefinedOrEmpty(submittedToPrimaryContactDate) &&
      guid === salesRepresentativeGuid;
    const enrollmentExpired = isEnrollmentExpired(enrollmentDataFromApi);

    if (enrollmentExpired) {
      onShowExpired(guid);
    } else if (currentSalesUserOptedOut || currentPracticeUserOptedOut) {
      onShowOptOut(guid);
    } else if (currentSalesUserEjected) {
      onShowEjectStatus(guid);
    } else {
      // TODO: Might need more elaborate check on all form values?
      const stepHasApiValues =
        enrollmentDataFromApi &&
        (!isNullOrUndefinedOrEmpty(primaryContactFullName) ||
          !isNullOrUndefinedOrEmpty(practiceName));
      setStepHasApiValues(stepHasApiValues);

      setTimeout(() => {
        setStepReady(true);
      }, 300);

      onEnrollmentDataUpdated(
        PRACTICE_DEMOGRAPHICS_ROUTE_NAME,
        guid,
        stepComplete,
        enrollmentDataFromApi
      );
    }
  };

  const isSubmitDisabled = (stepHasApiValues, pristine, submitting) => {
    return (stepHasApiValues ? false : pristine) || submitting;
  };

  const render = () => {
    if (!stepReady) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    const enrollmentParams = match['params'];
    const { language } = brandConfig;

    return (
      <div className="crxdo-practice-demographics">
        <div className="container">
          <h1 className="h2 text-primary crxdo-heading">
            {language.PRACTICE_DEMOGRAPHICS_TITLE}
          </h1>
        </div>
        <div className="crxdo-form-top-notes pt-2 px-2 pb-3">
          <div className="container crxdo-body">
            <p>{language.PRACTICE_DEMOGRAPHICS_FORM_NOTES}</p>
          </div>
        </div>

        <Form
          validateOnBlur={false}
          initialValues={enrollmentData}
          onSubmit={handleStepSubmit}
          validate={formValidatePracticeDemographics}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className="crxdo-form crxdo-form-border-t"
                autoComplete="off"
                noValidate={true}
              >
                <div className="container">
                  {(error || enrollmentError) && (
                    <AlertError
                      error={error || enrollmentError}
                      scrollToError={true}
                    />
                  )}

                  <Row className="pb-4">
                    <div className="col-12 col-lg-6">
                      <FormGroup>
                        <ValidateInputText
                          id="practiceName"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_PRACTICE_NAME"
                          placeHolderTextLanguageKey="GENERAL_FIELD_PRACTICE_NAME_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={150}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <FormGroup>
                        <ValidateInputText
                          id="addressLine1"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_ADDRESS_1"
                          placeHolderTextLanguageKey="GENERAL_FIELD_ADDRESS_1_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={50}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row className="pb-4">
                    <div className="col-12">
                      <FormGroup>
                        <ValidateInputText
                          id="addressLine2"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_ADDRESS_2"
                          placeHolderTextLanguageKey="GENERAL_FIELD_ADDRESS_2_PLACEHOLDER"
                          maxLength={50}
                          required={false}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12 col-md-4 col-lg-4">
                      <FormGroup>
                        <ValidateInputText
                          id="city"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_CITY"
                          placeHolderTextLanguageKey="GENERAL_FIELD_CITY_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={35}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <FormGroup>
                        <ValidateSelect
                          id="state"
                          labelTextLanguageKey="GENERAL_FIELD_STATE"
                          placeHolderTextLanguageKey="GENERAL_FIELD_STATE_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          autoComplete="none"
                          required={true}
                          options={getUnitedStates()}
                          optionNameKey="name"
                          optionValueKey="code"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2">
                      <FormGroup>
                        <ValidateInputText
                          id="zipCodeMasked"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_ZIP"
                          placeHolderTextLanguageKey="GENERAL_FIELD_ZIP_PLACEHOLDER"
                          parse={formatString(zipMask.parse)}
                          validate={composeFieldValidators(
                            validateFieldRequired,
                            validateFieldZip
                          )}
                          minLength={5}
                          maxLength={5}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row className="pb-4">
                    <div className="col-12 col-md-4 col-lg-4">
                      <FormGroup>
                        <ValidateInputText
                          id="phoneMasked"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_PHONE"
                          placeHolderTextLanguageKey="GENERAL_FIELD_PHONE_PLACEHOLDER"
                          parse={formatString(phoneMask.parse)}
                          validate={composeFieldValidators(
                            validateFieldRequired,
                            validateFieldPhone
                          )}
                          maxLength={14}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                      <FormGroup>
                        <ValidateInputText
                          id="faxMasked"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_FAX"
                          placeHolderTextLanguageKey="GENERAL_FIELD_FAX_PLACEHOLDER"
                          parse={formatString(phoneMask.parse)}
                          validate={composeFieldValidators(
                            validateFieldRequired,
                            validateFieldPhone
                          )}
                          maxLength={14}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12 col-md-8 col-lg-8">
                      <FormGroup>
                        <ValidateInputText
                          id="primaryContactFullName"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_PRIMARY_CONTACT_FULL_NAME"
                          placeHolderTextLanguageKey="GENERAL_FIELD_PRIMARY_CONTACT_FULL_NAME_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={150}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row className="pb-4">
                    <div className="col-12 col-md-6 col-lg-6">
                      <FormGroup>
                        <ValidateInputText
                          id="primaryContactEmail"
                          type="email"
                          labelTextLanguageKey="GENERAL_FIELD_PRIMARY_CONTACT_EMAIL"
                          placeHolderTextLanguageKey="GENERAL_FIELD_PRIMARY_CONTACT_EMAIL_PLACEHOLDER"
                          validateFields={[
                            'primaryContactEmail',
                            'confirmPrimaryContactEmail'
                          ]}
                          validate={composeFieldValidators(
                            validateFieldRequired,
                            validateFieldEmail
                          )}
                          maxLength={150}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <FormGroup>
                        <ValidateInputText
                          id="confirmPrimaryContactEmail"
                          type="email"
                          labelTextLanguageKey="GENERAL_FIELD_PRIMARY_CONTACT_CONFIRM_EMAIL"
                          placeHolderTextLanguageKey="GENERAL_FIELD_EMAIL_PLACEHOLDER"
                          validateFields={[
                            'primaryContactEmail',
                            'confirmPrimaryContactEmail'
                          ]}
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={150}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row tag="section">
                    <div className="col-12">
                      <h2 className="h3 text-primary crxdo-heading">
                        {
                          language.PRACTICE_DEMOGRAPHICS_ADDITIONAL_LOCATIONS_TITLE
                        }
                      </h2>
                      <div className="mb-4 crxdo-body">
                        {
                          language.PRACTICE_DEMOGRAPHICS_ADDITIONAL_LOCATIONS_DESCRIPTION
                        }
                      </div>
                      <div className="crxdo-form-buttons crxdo-form-buttons-left">
                        <div className="crxdo-form-btn-container">
                          <Button
                            type="submit"
                            color="brand-2"
                            className="btn-block-sm-down"
                            onClick={() => {
                              form.change(
                                'updateStepAction',
                                STEP_ACTION_ADD_LOCATIONS
                              );
                            }}
                            disabled={isSubmitDisabled(
                              stepHasApiValues,
                              pristine,
                              submitting
                            )}
                          >
                            {submitting &&
                            stepAction === STEP_ACTION_ADD_LOCATIONS ? (
                              <SubmitButtonSpinner submitting={submitting} />
                            ) : (
                              language.GENERAL_ADD_ADDITIONAL_LOCATIONS_BTN
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <ViewDebug className="crxdo-view-debug-bottom-right">
                    <div className="h4" role="heading">
                      Form Values & Step Details & config
                    </div>
                    <div className="mb-2">
                      <span className="font-weight-bold">form values</span>=
                      {JSON.stringify(values)}
                    </div>
                    <div className="mb-2">
                      <span className="font-weight-bold">stepDetails</span>=
                      {JSON.stringify(stepDetails)}
                    </div>
                    {config && (
                      <div className="mb-2">
                        <span className="font-weight-bold">config</span>=
                        {JSON.stringify(config)}
                      </div>
                    )}
                  </ViewDebug>
                </div>

                <div className="crxdo-form-buttons crxdo-form-buttons-border-tb">
                  <div className="container crxdo-form-btn-container">
                    <Button
                      type="submit"
                      color="brand-1"
                      className="btn-block-sm-down crxdo-form-btn-last"
                      onClick={() => {
                        form.change('updateStepAction', STEP_ACTION_NEXT);
                      }}
                      disabled={isSubmitDisabled(
                        stepHasApiValues,
                        pristine,
                        submitting
                      )}
                    >
                      {submitting && stepAction === STEP_ACTION_NEXT ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_SAVE_AND_CONTINUE_BTN
                      )}
                    </Button>

                    <EnrollmentEjectButton
                      id="practice-demographics-send-to-primary-button"
                      stepAction={stepAction}
                      submitting={submitting}
                      disabled={isSubmitDisabled(
                        stepHasApiValues,
                        pristine,
                        submitting
                      )}
                      form={form}
                      userGuid={enrollmentParams['guid']}
                      enrollmentData={enrollmentData}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        />

        <BrandFooter />
      </div>
    );
  };

  return render();
};

export const formValidatePracticeDemographics = values => {
  const errors = {};
  const {
    practiceName,
    addressLine1,
    phoneMasked,
    faxMasked,
    city,
    state,
    zipCodeMasked,
    primaryContactFullName,
    primaryContactEmail,
    confirmPrimaryContactEmail
  } = values;
  const { language } = brandConfig;

  if (!practiceName) {
    errors.practiceName = FIELD_REQUIRED_ERROR;
  }
  if (!addressLine1) {
    errors.addressLine1 = FIELD_REQUIRED_ERROR;
  }
  if (!phoneMasked) {
    errors.phoneMasked = FIELD_REQUIRED_ERROR;
  }
  if (!faxMasked) {
    errors.faxMasked = FIELD_REQUIRED_ERROR;
  }
  if (!city) {
    errors.city = FIELD_REQUIRED_ERROR;
  }
  if (!state) {
    errors.state = FIELD_REQUIRED_ERROR;
  }
  if (!zipCodeMasked) {
    errors.zipCodeMasked = FIELD_REQUIRED_ERROR;
  }
  if (!primaryContactFullName) {
    errors.primaryContactFullName = FIELD_REQUIRED_ERROR;
  }
  if (!primaryContactEmail) {
    errors.primaryContactEmail = FIELD_REQUIRED_ERROR;
  } else {
    errors.primaryContactEmail = validateFieldEmail(primaryContactEmail);
  }
  if (!confirmPrimaryContactEmail) {
    errors.confirmPrimaryContactEmail = FIELD_REQUIRED_ERROR;
  } else if (confirmPrimaryContactEmail !== primaryContactEmail) {
    errors.confirmPrimaryContactEmail = FIELD_MUST_MATCH_VALUE_ERROR(
      language.GENERAL_FIELD_PRIMARY_CONTACT_EMAIL
    );
  }
  return errors;
};

const PracticeDemographics = withRouter(PracticeDemographicsView);

export default PracticeDemographics;
