const formatSimpleDate = dateString => {
  if (!dateString) {
    return null;
  }

  const dateToFormat = new Date(dateString);
  if (isNaN(dateToFormat.getMonth())) {
    return null;
  }

  const mm = dateToFormat.getMonth() + 1;
  const mmf = mm > 9 ? `${mm}` : `0${mm}`;
  const dd = dateToFormat.getDate();
  const ddf = dd > 9 ? `${dd}` : `0${dd}`;
  const yyyyf = dateToFormat.getFullYear();

  return `${mmf}/${ddf}/${yyyyf}`;
};

export default formatSimpleDate;
