import brandConfig from '../../brand/brand-config.json';

export const FIELD_ONLINE_PRODUCT_ORDERING_LOCATION_REQUIRED_ERROR =
  brandConfig.language
    .GENERAL_ERROR_FIELD_ONLINE_PRODUCT_ORDERING_LOCATION_REQUIRED_ERROR;

const validateFieldOrderingOnlineLocationsRequired = (
  serviceOnlineProductOrdering,
  servicesSelectionFormValues
) => {
  const {
    onlineProductOrdering,
    ordering,
    additionalLocations
  } = servicesSelectionFormValues;
  let additionalLocationSelected = false;

  if (Array.isArray(additionalLocations)) {
    for (let i = 0; i < additionalLocations.length; i++) {
      if (additionalLocations[i].ordering === true) {
        additionalLocationSelected = true;
        break;
      }
    }
  }

  const isMissingOnlineProductOrderingLocation =
    serviceOnlineProductOrdering &&
    onlineProductOrdering &&
    ordering === false &&
    additionalLocationSelected === false;

  return isMissingOnlineProductOrderingLocation
    ? FIELD_ONLINE_PRODUCT_ORDERING_LOCATION_REQUIRED_ERROR
    : undefined;
};

export default validateFieldOrderingOnlineLocationsRequired;
