import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './EjectStatus.scss';
import MessageView from '../shared/MessageView/MessageView';
import brandConfig from '../../brand/brand-config';
import { getEnrollment } from '../../api';
import { useMount } from '../../utils';
import { useConfig } from '../../config';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import { formatSimpleDate } from '../../utils/format';

export const EJECT_STATUS_ROUTE_NAME = 'eject-status';

const EjectStatus = ({
  enrollmentData,
  match,
  onResetEnrollment,
  onEnrollmentDataUpdated
}) => {
  const config = useConfig();
  const [messageReady, setMessageReady] = useState(false);

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const { salesRepresentativeGuid, practiceAdminGuid } = enrollmentData;

    if (
      guid &&
      (salesRepresentativeGuid !== guid || practiceAdminGuid !== guid)
    ) {
      fetchEnrollmentDataForGuid(guid);
    } else {
      onResetEnrollment();
    }
  });

  const fetchEnrollmentDataForGuid = guid => {
    const onHandleGetStatus = apiValues => {
      if (apiValues) {
        handleEnrollmentDataFetch(guid, apiValues);
      } else {
        onResetEnrollment();
      }
    };

    const onHandleGetFailure = error => {
      onResetEnrollment();
    };

    return getEnrollment(guid, config)
      .then(onHandleGetStatus)
      .catch(onHandleGetFailure);
  };

  const handleEnrollmentDataFetch = (guid, enrollmentDataFromApi) => {
    setMessageReady(true);

    onEnrollmentDataUpdated(
      EJECT_STATUS_ROUTE_NAME,
      guid,
      false,
      enrollmentDataFromApi,
      true
    );
  };

  const getEjectMessage = () => {
    const {
      primaryContactEmail,
      submittedToPrimaryContactDate,
      enrollmentCompletionDate
    } = enrollmentData;
    const { language } = brandConfig;

    const submittedToPrimaryContactDateFormatted = formatSimpleDate(
      submittedToPrimaryContactDate
    );
    const statusMessageLanguage = !!enrollmentCompletionDate
      ? language.GENERAL_EJECT_STATUS_COMPLETE_MESSAGE
      : language.GENERAL_EJECT_STATUS_MESSAGE;

    return statusMessageLanguage
      .replace('{primaryContactEmail}', primaryContactEmail)
      .replace(
        '{submittedToPrimaryContactDateFormatted}',
        submittedToPrimaryContactDateFormatted
      );
  };

  const render = () => {
    if (!messageReady) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    return (
      <div className="crxdo-eject-status">
        <MessageView>
          <div role="heading" className="h3">
            {getEjectMessage()}
          </div>
        </MessageView>
      </div>
    );
  };

  return render();
};

EjectStatus.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default EjectStatus;
