import React from 'react';
import PropTypes from 'prop-types';
import './UserHighlights.scss';

const UserHighlights = ({ user }) => {
  if (!user) {
    return null;
  }

  const { firstName, lastName, userRole, userType } = user;

  return (
    <div className="crxdo-user-highlights">
      {firstName && <span className="crxdo-user-highlight">{firstName}</span>}
      {lastName && <span className="crxdo-user-highlight">{lastName},</span>}
      {userRole && <span className="crxdo-user-highlight">{userRole},</span>}
      {userType && <span className="crxdo-user-highlight">{userType}</span>}
    </div>
  );
};

UserHighlights.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserHighlights;
