import React from 'react';
import PropTypes from 'prop-types';
import './PrescriberHighlights.scss';
import brandConfig from '../../../brand/brand-config';

const PrescriberHighlights = ({ prescriber }) => {
  if (!prescriber) {
    return null;
  }

  const { firstName, lastName, npi } = prescriber;
  const { language } = brandConfig;

  return (
    <div className="crxdo-prescriber-highlights">
      {firstName && (
        <span className="crxdo-prescriber-highlight">{firstName}</span>
      )}
      {lastName && (
        <span className="crxdo-prescriber-highlight">{lastName},</span>
      )}
      {npi && (
        <span className="crxdo-prescriber-highlight">
          {language.GENERAL_FIELD_NPI}: {npi}
        </span>
      )}
    </div>
  );
};

PrescriberHighlights.propTypes = {
  prescriber: PropTypes.object.isRequired
};

export default PrescriberHighlights;
