import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PracticeRegistration, {
  PRACTICE_REGISTRATION_ROUTE_NAME
} from './components/practice-registration/PracticeRegistration';
import { defaultEnrollmentData } from './utils/format';
import EnrollmentApp from './components/enrollment-app/EnrollmentApp';
import NotFound from './components/not-found/NotFound';
import { ConfigProvider, ConfigContext } from './config';
import OptOut, { OPT_OUT_ROUTE_NAME } from './components/opt-out/OptOut';
import Expired, { EXPIRED_ROUTE_NAME } from './components/expired/Expired';

class App extends Component {
  render() {
    return (
      <ConfigProvider>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={`(/|/${PRACTICE_REGISTRATION_ROUTE_NAME})`}
              render={props => (
                <PracticeRegistration
                  {...props}
                  defaultEmptyEnrollmentData={defaultEnrollmentData}
                />
              )}
            />
            <Route
              path={`/enrollment/:guid`}
              render={props => (
                <ConfigContext.Consumer>
                  {apiConfig => (
                    <EnrollmentApp
                      {...props}
                      defaultEmptyEnrollmentData={defaultEnrollmentData}
                      apiConfig={apiConfig}
                    />
                  )}
                </ConfigContext.Consumer>
              )}
            />
            <Route
              path={`/${EXPIRED_ROUTE_NAME}`}
              render={props => <Expired {...props} />}
            />
            <Route
              path={`/${OPT_OUT_ROUTE_NAME}/:guid`}
              render={props => <OptOut {...props} />}
            />
            <Route
              path={`/${OPT_OUT_ROUTE_NAME}`}
              render={props => <OptOut {...props} />}
            />
            <Route render={NotFound} />
          </Switch>
        </BrowserRouter>
      </ConfigProvider>
    );
  }
}

export default App;
