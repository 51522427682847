import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Button, CustomInput, FormGroup, Row } from 'reactstrap';
import './BaaSign.scss';
import MessageView from '../shared/MessageView/MessageView';
import brandConfig from '../../brand/brand-config';
import { getEnrollment, patchEnrollment } from '../../api';
import { getISODateString, isNullOrUndefinedOrEmpty, useMount } from '../../utils';
import { useConfig } from '../../config';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import AlertError from '../shared/AlertError/AlertError';
import {
  BAA_SIGN_USER_SIGN,
  BAA_OTHER_PERSON_WILL_SIGN,
  BAA_SIGN_PRACTICE_WITH_SEPARATE_BAA
} from '../../constants/BaaSignChoices';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import { formatPatchData } from '../../utils/format';
import qs from 'qs';
import BusinessAssociateAgreementDocument from '../../brand/BusinessAssociateAgreement.pdf';

export const BAA_SIGN_ROUTE_NAME = 'baa-sign';

const BaaSign = ({
  enrollmentData,
  match,
  onResetEnrollment,
  onEnrollmentDataUpdated,
  onCompleteEnrollment,
  enrollmentError,
  onClearEnrollmentError
}) => {
  const config = useConfig();
  const [error, setError] = useState(null);
  const [currentUserGuid, setCurrentUserGuid] = useState(null);
  const [enrollmentGuid, setEnrollmentGuid] = useState(null);
  const [baaSignFormDetails, setBaaSignFormDetails] = useState(null);
  const [messageReady, setMessageReady] = useState(false);
  const [showDocusign, setShowDocusign] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const enrollmentParams = match['params'];
  const { guid } = enrollmentParams;

  useMount(() => {
    const { salesRepresentativeGuid, practiceAdminGuid } = enrollmentData;

    if (
      guid &&
      (salesRepresentativeGuid !== guid || practiceAdminGuid !== guid)
    ) {
      fetchEnrollmentDataForGuid(guid);
    } else {
      onResetEnrollment();
    }
  });

  const fetchEnrollmentDataForGuid = guid => {
    const onHandleGetSuccess = apiValues => {
      if (apiValues) {
        handleEnrollmentDataFetch(guid, apiValues);
      } else {
        onResetEnrollment();
      }
    };

    const onHandleGetFailure = error => {
      onResetEnrollment();
    };

    return getEnrollment(guid, config)
      .then(onHandleGetSuccess)
      .catch(onHandleGetFailure);
  };

  const handleEnrollmentDataFetch = (guid, enrollmentDataFromApi) => {
    const {
      enrollmentGuid,
      baaSignatureChoice,
      baaSignatureRequestEmail
    } = enrollmentDataFromApi;
    setEnrollmentGuid(enrollmentGuid);
    setCurrentUserGuid(guid);
    setBaaSignFormDetails({
      baaSignatureChoice,
      baaSignatureRequestEmail
    });
    setMessageReady(true);

    onEnrollmentDataUpdated(
      BAA_SIGN_ROUTE_NAME,
      guid,
      false,
      enrollmentDataFromApi,
      true
    );
  };

  const completeEnrollment = enrollmentDataForCompletion => {
    onCompleteEnrollment(
      BAA_SIGN_ROUTE_NAME,
      currentUserGuid,
      enrollmentDataForCompletion,
      config
    );
  };

  const handleBaaSignChoiceSubmit = async (formValues, form) => {
    const { baaSignatureChoice } = formValues;
    setError(null);
    onClearEnrollmentError();

    setShowDocusign(true);
    setShowSpinner(true);

    let baaSignDataToPatch = {
      baaSignatureChoice
    };

    if (baaSignatureChoice !== BAA_SIGN_USER_SIGN) {
      baaSignDataToPatch['enrollmentCompletionDate'] = getISODateString();
      baaSignDataToPatch['sendZenDeskEmail'] = true;
    }

    const baaSignChoiceData = formatPatchData(baaSignDataToPatch, '');

    const onHandleBaaSignChoicePatchFailure = error => {
      setError(error);
    };

    const onHandleBaaSignChoicePatchSuccess = apiValues => {
      const updatedEnrollmentData = { ...apiValues };
      if (baaSignatureChoice === BAA_SIGN_USER_SIGN) {
        onEnrollmentDataUpdated(
          BAA_SIGN_ROUTE_NAME,
          currentUserGuid,
          false,
          updatedEnrollmentData,
          true
        );
        setSignatureUrl(updatedEnrollmentData.baaSignatureUrl);
        setShowSpinner(false);
        setShowDocusign(true);
      } else {
        completeEnrollment(updatedEnrollmentData);
      }
      form.reset();

      return true;
    };

    return await patchEnrollment(baaSignChoiceData, enrollmentGuid, config)
      .then(onHandleBaaSignChoicePatchSuccess)
      .catch(onHandleBaaSignChoicePatchFailure);
  };

  const onSignSuccess = async signatureEvent => {

    const baaSignDataToPatch = {
      signatureEvent,
      enrollmentCompletionDate: getISODateString(),
      sendZenDeskEmail: true
    };

    const baaDocuSignSuccessData = formatPatchData(baaSignDataToPatch, '');

    const onHandleBaaSignResponsePatchFailure = error => {
      setError(error);
    };

    const onHandleBaaSignResponsePatchSuccess = apiValues => {
      completeEnrollment({ ...apiValues });

      return true;
    };

    return await patchEnrollment(baaDocuSignSuccessData, enrollmentGuid, config)
      .then(onHandleBaaSignResponsePatchSuccess)
      .catch(onHandleBaaSignResponsePatchFailure);
  };

  const checkForDocusignCallBack = async event => {
    // when the browser has access to the iframe we know
    // docusign has made the callback
    try {
      var iFrameLocation = event.target.contentWindow.location;
      const queryStringVals = qs.parse(iFrameLocation.search, {
        ignoreQueryPrefix: true
      });
      if (
        iFrameLocation.origin === window.location.origin &&
        queryStringVals &&
        queryStringVals.hasOwnProperty('event')
      ) {
        const event = queryStringVals['event'];
        onSignSuccess(event);
      }
    } catch {}
  };

  const getBaaChoiceSelectedDisplay = (selectedValue) => {
    const { language } = brandConfig;

    switch (selectedValue) {
      case BAA_SIGN_USER_SIGN:
        return (
          <label className="crxdo-label">
            {language.BAA_SIGN_CHOICE_FIELD_USER_SIGN_ADDITIONAL_TEXT}
          </label>
        );
      case BAA_OTHER_PERSON_WILL_SIGN:
        return (
          <a className="crxdo-label" href={BusinessAssociateAgreementDocument} target='_blank'>
            {language.BAA_SIGN_CHOICE_FIELD_OTHER_PERSON_WILL_SIGN_DOWNLOAD_LINK}
          </a>
        );
      case BAA_SIGN_PRACTICE_WITH_SEPARATE_BAA:
        return (
          <label className="crxdo-label">
            {language.BAA_SIGN_CHOICE_FIELD_PRACTICE_WITH_SEPARATE_EMAIL_LABEL} {language.SUPPORT_SERVICE_EMAIL} {language.BAA_SIGN_CHOICE_FIELD_PRACTICE_WITH_SEPARATE_FAX_LABEL} {language.SUPPORT_SERVICE_FAX}
          </label>
        );
      default:
        return ('');
    };
  };

  const render = () => {
    if (!messageReady) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }
    const { language } = brandConfig;

    return (
      <div className="crxdo-baa-sign">
        <MessageView noContentPadding={true} hasHeaderBorder={true}>
          {!showDocusign ? (
            <h1 className="h2 text-primary crxdo-heading">
              {brandConfig.language.BAA_SIGN_TITLE}
            </h1>
          ) : null}
          {!showDocusign && (
            <div className="crxdo-form-top-notes pt-2 pb-3 border-bottom crxdo-body">
              <p>{brandConfig.language.BAA_SIGN_MESSAGE}</p>
            </div>
          )}
          <div className="container">
            {showDocusign ? (
              <Fragment>
                <LoadingSpinner loading={showSpinner} isViewSpinner={false} />
                <div className="crxdo-baa-sign-iframe-wrapper">
                  <div className="text-danger text-center">
                    <iframe
                      id="DocuSignIframe"
                      title="BusinessAgreementForm"
                      src={signatureUrl}
                      onLoad={checkForDocusignCallBack}
                    />
                  </div>
                </div>
              </Fragment>
            ) : (
              <Form
                validateOnBlur={false}
                initialValues={baaSignFormDetails}
                  onSubmit={handleBaaSignChoiceSubmit}
                render={({ handleSubmit, submitting, values, pristine }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      className="crxdo-form crxdo-baa-sign-form"
                      autoComplete="off"
                      noValidate={true}
                    >
                      <div className="container">
                        {(error || enrollmentError) && (
                          <AlertError
                            error={error || enrollmentError}
                            scrollToError={true}
                          />
                        )}

                        <div className="crxdo-baa-sign-choices">
                          <Row>
                            <div className="col-12">
                              <FormGroup>
                                <Field
                                  name="baaSignatureChoice"
                                  component="input"
                                  type="radio"
                                  value={BAA_SIGN_USER_SIGN}
                                >
                                  {({ input }) => {
                                    return (
                                      <CustomInput
                                        {...input}
                                        type="radio"
                                        id="baaSignatureChoice_user_sign"
                                        name="baaSignatureChoice"
                                        className="crxdo-label"
                                        label={
                                          language.BAA_SIGN_CHOICE_FIELD_USER_SIGN
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                                <Field
                                  name="baaSignatureChoice"
                                  component="input"
                                  type="radio"
                                  value={BAA_OTHER_PERSON_WILL_SIGN}
                                >
                                  {({ input }) => {
                                    return (
                                      <CustomInput
                                        {...input}
                                        type="radio"
                                        id="baaSignatureChoice_other_person_will_sign"
                                        name="baaSignatureChoice"
                                        className="crxdo-label"
                                        label={
                                          language.BAA_SIGN_CHOICE_FIELD_OTHER_PERSON_WILL_SIGN
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                                <Field
                                  name="baaSignatureChoice"
                                  component="input"
                                  type="radio"
                                  value={BAA_SIGN_PRACTICE_WITH_SEPARATE_BAA}
                                >
                                  {({ input }) => {
                                    return (
                                      <CustomInput
                                        {...input}
                                        type="radio"
                                        id="baaSignatureChoice_practice_with_separate_baa"
                                        name="baaSignatureChoice"
                                        className="crxdo-label"
                                        label={
                                          language.BAA_SIGN_CHOICE_FIELD_PRACTICE_WITH_SEPARATE_BAA
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </FormGroup>
                            </div>
                          </Row>

                          {!isNullOrUndefinedOrEmpty(values['baaSignatureChoice']) && (
                            <Row>
                              <div className="col-12">
                                {getBaaChoiceSelectedDisplay(values['baaSignatureChoice'])}
                              </div>
                            </Row>
                          )}
                        </div>
                      </div>

                      <div className="crxdo-form-buttons crxdo-form-buttons-border-tb">
                        <div className="container crxdo-form-btn-container">
                          <Button
                            type="submit"
                            color="brand-1"
                            className="btn-block-sm-down crxdo-form-btn-last"
                            disabled={pristine}
                          >
                            {submitting ? (
                              <SubmitButtonSpinner submitting={submitting} />
                            ) : (
                              language.GENERAL_FINISH_BTN
                            )}
                          </Button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              />
            )}
          </div>
        </MessageView>
      </div>
    );
  };

  return render();
};

BaaSign.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default BaaSign;
