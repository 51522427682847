import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import './ServiceOnlineProductOrdering.scss';
import { FormGroup, Row } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import ValidateInputText from '../ValidateInputText/ValidateInputText';
import LocationHighlights from '../LocationHighlights/LocationHighlights';
import { FIELD_REQUIRED_ERROR } from '../../../utils/form';
import ValidateCheckboxBoolean from '../ValidateCheckboxBoolean/ValidateCheckboxBoolean';
import brandConfig from '../../../brand/brand-config.json';
import { formatLocationData } from '../../../utils/format';
import { isNullOrUndefinedOrEmpty, useMount } from '../../../utils';

const ServiceOnlineProductOrdering = ({
  enrollmentData,
  hasOnlineProductOrdering,
  form,
  formValues
}) => {
  const {
    onlineProductOrdering,
    additionalLocations,
    practiceName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCodeMasked
  } = enrollmentData;

  const [showOrderingLocations, setShowOrderingLocations] = useState(
    onlineProductOrdering
  );
  const [
    isPrimaryAccountNumberFieldRequired,
    setIsPrimaryAccountNumberRequired
  ] = useState(false);

  useMount(() => {
    const { accountNumberUnknown, ordering } = enrollmentData;
    const updatedIsPrimaryAccountNumberRequired =
      ordering && accountNumberUnknown !== true;

    setIsPrimaryAccountNumberRequired(updatedIsPrimaryAccountNumberRequired);
  });

  if (!enrollmentData || !hasOnlineProductOrdering || !form || !formValues) {
    return null;
  }

  const handleDependentFieldChange = (whichField, value) => {
    if (whichField === 'onlineProductOrdering') {
      setShowOrderingLocations(value);
    }

    if (whichField === 'accountNumberUnknown') {
      const { ordering } = formValues;
      const isPrimaryAcctRequired = ordering ? value !== true : false;
      setIsPrimaryAccountNumberRequired(isPrimaryAcctRequired);
    }

    if (whichField === 'ordering') {
      const { accountNumberUnknown } = formValues;
      const isPrimaryAcctRequired = accountNumberUnknown ? false : value;
      setIsPrimaryAccountNumberRequired(isPrimaryAcctRequired);
    }
  };

  const validateAdditionalLocationsFieldArray = values => {
    return formValidateAdditionalLocationsFieldArray(
      hasOnlineProductOrdering,
      values
    );
  };

  const render = () => {
    const { language } = brandConfig;

    return (
      <div className="crxdo-service-online-product-ordering">
        <Row className="crxdo-service-online-product-ordering">
          <div className="col-12">
            <ValidateCheckboxBoolean
              labelTextLanguageKey="SERVICES_SELECTION_ONLINE_PRODUCT_ORDERING"
              className="crxdo-form-label-emphasis"
              id="onlineProductOrdering"
              name="onlineProductOrdering"
              fieldDescriptionTextLanguageKey="SERVICES_SELECTION_ONLINE_PRODUCT_ORDERING_DESCRIPTION"
            />

            <OnChange name="faxOnlyServices">
              {(value, previous) =>
                handleDependentFieldChange('faxOnlyServices', value, previous)
              }
            </OnChange>
            <OnChange name="onlineProductOrdering">
              {(value, previous) =>
                handleDependentFieldChange(
                  'onlineProductOrdering',
                  value,
                  previous
                )
              }
            </OnChange>
          </div>
        </Row>

        {showOrderingLocations && (
          <div className="crxdo-service-online-product-ordering-locations">
            <div className="crxdo-service-online-product-ordering-location">
              <div className="crxdo-service-online-product-location-highlight">
                <span className="crxdo-form-item-badge badge badge-pill badge-medium">
                  1
                </span>
                <LocationHighlights
                  location={{
                    locationName: practiceName,
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zipCodeMasked
                  }}
                />
              </div>
              <div className="crxdo-service-online-product-location-fields">
                <Row>
                  <div className="col-12 col-md-4 col-lg-4">
                    <ValidateCheckboxBoolean
                      labelTextLanguageKey="GENERAL_FIELD_PRODUCT_ORDERING"
                      id="ordering"
                      name="ordering"
                      optionalText={
                        language.GENERAL_FIELD_PRODUCT_ORDERING_DESCRIPTION
                      }
                      showOptionalText={true}
                    />
                  </div>
                  <div className="col-12 col-md-5 col-lg-5">
                    <FormGroup>
                      <ValidateInputText
                        id="primaryAccountNumber"
                        type="text"
                        labelTextLanguageKey="GENERAL_FIELD_ACCOUNT_NUMBER"
                        placeHolderTextLanguageKey="GENERAL_FIELD_ACCOUNT_NUMBER_PLACEHOLDER"
                        required={isPrimaryAccountNumberFieldRequired}
                        maxLength={50}
                        autoComplete="none"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12 col-md-3 col-lg-3">
                    <ValidateCheckboxBoolean
                      labelTextLanguageKey="GENERAL_FIELD_ACCOUNT_NUMBER_UNKNOWN"
                      id="accountNumberUnknown"
                      name="accountNumberUnknown"
                    />
                  </div>

                  <OnChange name="accountNumberUnknown">
                    {(value, previous) =>
                      handleDependentFieldChange(
                        'accountNumberUnknown',
                        value,
                        previous
                      )
                    }
                  </OnChange>
                  <OnChange name="ordering">
                    {(value, previous) =>
                      handleDependentFieldChange('ordering', value, previous)
                    }
                  </OnChange>
                </Row>
              </div>
            </div>

            <FieldArray
              name="additionalLocations"
              validate={validateAdditionalLocationsFieldArray}
            >
              {({ fields }) =>
                fields.map((name, index) => {
                  const rowLocation = additionalLocations[index];
                  const formattedRowLocation = formatLocationData(rowLocation);
                  const rowDirtyValues = fields['value']
                    ? fields['value'][index]
                    : null;
                  const orderingDirtyValue = rowDirtyValues['ordering'];
                  const accountNumberUnknownDirtyValue =
                    rowDirtyValues['accountNumberUnknown'];
                  const isAccountNumberFieldRequired =
                    rowDirtyValues &&
                    orderingDirtyValue &&
                    accountNumberUnknownDirtyValue === false;

                  return (
                    <div
                      key={name}
                      className="crxdo-service-online-product-ordering-location"
                    >
                      <div className="crxdo-service-online-product-location-highlight">
                        <span className="crxdo-form-item-badge badge badge-pill badge-medium">
                          {index + 2}
                        </span>
                        <LocationHighlights location={formattedRowLocation} />
                      </div>
                      <div className="crxdo-service-online-product-location-fields">
                        <Row>
                          <div className="col-12 col-md-4 col-lg-4">
                            <ValidateCheckboxBoolean
                              labelTextLanguageKey="GENERAL_FIELD_PRODUCT_ORDERING"
                              id={`${name}.ordering`}
                              name={`${name}.ordering`}
                              optionalText={
                                language.GENERAL_FIELD_PRODUCT_ORDERING_DESCRIPTION
                              }
                              showOptionalText={true}
                            />
                          </div>
                          <div className="col-12 col-md-5 col-lg-5">
                            <FormGroup>
                              <ValidateInputText
                                id={`${name}.accountNumber`}
                                type="text"
                                labelTextLanguageKey="GENERAL_FIELD_ACCOUNT_NUMBER"
                                placeHolderTextLanguageKey="GENERAL_FIELD_ACCOUNT_NUMBER_PLACEHOLDER"
                                maxLength={50}
                                required={isAccountNumberFieldRequired}
                                autoComplete="none"
                              />
                            </FormGroup>
                          </div>
                          <div className="col-12 col-md-3 col-lg-3">
                            <ValidateCheckboxBoolean
                              labelTextLanguageKey="GENERAL_FIELD_ACCOUNT_NUMBER_UNKNOWN"
                              id={`${name}.accountNumberUnknown`}
                              name={`${name}.accountNumberUnknown`}
                            />
                          </div>
                        </Row>
                      </div>
                    </div>
                  );
                })
              }
            </FieldArray>
          </div>
        )}
      </div>
    );
  };

  return render();
};

ServiceOnlineProductOrdering.propTypes = {
  hasOnlineProductOrdering: PropTypes.bool.isRequired,
  enrollmentData: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired
};

export const formValidateAdditionalLocationsFieldArray = (
  hasOnlineProductOrdering,
  values
) => {
  const errorsArray = [];

  if (hasOnlineProductOrdering && values && values.length > 0) {
    values.forEach((location, index) => {
      const { accountNumberUnknown, accountNumber, ordering } = location;
      const errors = {};
      if (
        ordering &&
        accountNumberUnknown !== true &&
        isNullOrUndefinedOrEmpty(accountNumber)
      ) {
        errors.accountNumber = FIELD_REQUIRED_ERROR;
      }
      errorsArray.push(errors);
    });
  }

  if (errorsArray.some(object => !isNullOrUndefinedOrEmpty(object))) {
    return errorsArray;
  } else {
    return undefined;
  }
};

export default ServiceOnlineProductOrdering;
