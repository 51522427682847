const getUserRole = () => {
  return [
    { code: 'Nurse', name: 'Nurse' },
    { code: 'Nurse Practitioner', name: 'Nurse Practitioner' },
    { code: 'Physician Assistant', name: 'Physician Assistant' },
    { code: 'Medical Assistant', name: 'Medical Assistant' },
    { code: 'Other', name: 'Other' }
  ];
};

export default getUserRole;
