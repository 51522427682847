import http from './http';

const onboardingApi = http();

export function getEnrollment(enrollmentGuid, config) {
  return onboardingApi.get(`${config.apiBaseUrl}Enrollment/${enrollmentGuid}`);
}

export function postEnrollment(formValues, config) {
  return onboardingApi.post(`${config.apiBaseUrl}Enrollment`, formValues);
}

export function patchEnrollment(patchValues, enrollmentGuid, config) {
  return onboardingApi.patch(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}`,
    patchValues
  );
}

export function postEnrollmentComplete(postValues, enrollmentGuid, config) {
  return onboardingApi.post(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/complete`,
    postValues
  );
}

export function postLocation(formValues, enrollmentGuid, config) {
  return onboardingApi.post(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/location`,
    formValues
  );
}

export function patchLocation(patchValues, enrollmentGuid, locationId, config) {
  return onboardingApi.patch(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/location/${locationId}`,
    patchValues
  );
}

export function deleteLocation(enrollmentGuid, locationId, config) {
  return onboardingApi.delete(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/location/${locationId}`
  );
}

export function patchLocations(patchValues, enrollmentGuid, config) {
  return onboardingApi.patch(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/locations`,
    patchValues
  );
}

export function putService(formValues, enrollmentGuid, config) {
  return onboardingApi.put(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/service`,
    formValues
  );
}

export function putServiceBVPortal(formValues, enrollmentGuid, config) {
  return onboardingApi.put(
    `${
      config.apiBaseUrl
    }Enrollment/${enrollmentGuid}/service/benefitsverification`,
    formValues
  );
}

export function postPrescriber(formValues, enrollmentGuid, config) {
  return onboardingApi.post(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/prescriber`,
    formValues
  );
}

export function patchPrescriber(
  patchValues,
  enrollmentGuid,
  prescriberId,
  config
) {
  return onboardingApi.patch(
    `${
      config.apiBaseUrl
    }Enrollment/${enrollmentGuid}/prescriber/${prescriberId}`,
    patchValues
  );
}

export function deletePrescriber(enrollmentGuid, prescriberId, config) {
  return onboardingApi.delete(
    `${
      config.apiBaseUrl
    }Enrollment/${enrollmentGuid}/prescriber/${prescriberId}`
  );
}

export function postUser(formValues, enrollmentGuid, config) {
  return onboardingApi.post(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/user`,
    formValues
  );
}

export function patchUser(patchValues, enrollmentGuid, userId, config) {
  return onboardingApi.patch(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/user/${userId}`,
    patchValues
  );
}

export function deleteUser(enrollmentGuid, userId, config) {
  return onboardingApi.delete(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/user/${userId}`
  );
}

export function postDemographics(formValues, enrollmentGuid, config) {
  return onboardingApi.post(
    `${config.apiBaseUrl}Enrollment/${enrollmentGuid}/Demographics`,
    formValues
  );
}

export function getBrandServices(brandId, config) {
  return onboardingApi.get(`${config.apiBaseUrl}brand/${brandId}/services`);
}

export function getBrandApiConfig(brandId, config) {
  return onboardingApi.get(`${config.apiBaseUrl}brand/${brandId}/config`);
}

export function getServices(config) {
  return onboardingApi.get(`${config.apiBaseUrl}Services`);
}
