import React from 'react';
import PropTypes from 'prop-types';
import './LoadingSpinner.scss';
import brandConfig from '../../../brand/brand-config';

const LoadingSpinner = ({ loading, color, isViewSpinner }) => {
  if (loading !== true) {
    return null;
  }

  const textColor = `text-${color ? color : 'secondary'}`;

  const spinnerWrapperClass = `${
    isViewSpinner
      ? 'crxdo-loading-view-spinner'
      : 'crxdo-loading-component-spinner'
  }`;

  const { language } = brandConfig;

  return (
    <div className={spinnerWrapperClass}>
      <span
        className={`crxdo-loading-spinner spinner-border spinner-border-sm ${textColor}`}
        role="status"
      >
        <span className="sr-only">{language.GENERAL_LOADING_MSG}</span>
      </span>
    </div>
  );
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool
};

export default LoadingSpinner;
