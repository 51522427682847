import { isNullOrUndefinedOrEmpty } from './';

export const isBaaSignatureRequired = (enrollmentData, config) => {
  const {
    benefitsVerification,
    faxOnlyServices,
    baaSignatureChoice
  } = enrollmentData;
  const {
    portalRequiresBusinessAgreement,
    faxRequiresBusinessAgreement
  } = config;
  const baaSignatureChoiceMissing = isNullOrUndefinedOrEmpty(
    baaSignatureChoice
  );

  return (
    baaSignatureChoiceMissing &&
    ((benefitsVerification && portalRequiresBusinessAgreement) ||
      (faxOnlyServices && faxRequiresBusinessAgreement))
  );
};

export default isBaaSignatureRequired;
