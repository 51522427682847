import React from 'react';
import PropTypes from 'prop-types';
import './FieldError.scss';

const FieldError = ({ fieldMeta, id }) => {
  if (!fieldMeta || !id) {
    return null;
  }

  const { error, touched } = fieldMeta;

  if (!error || !touched) {
    return null;
  }

  return (
    <div className="crxdo-field-error" id={id}>
      {error}
    </div>
  );
};

FieldError.propTypes = {
  id: PropTypes.string.isRequired,
  fieldMeta: PropTypes.object.isRequired
};

export default FieldError;
