import React from 'react';
import PropTypes from 'prop-types';
import './LocationHighlights.scss';

const LocationHighlights = ({ location }) => {
  if (!location) {
    return null;
  }

  const {
    locationName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCodeMasked
  } = location;

  return (
    <div className="crxdo-location-highlights">
      {locationName && (
        <span className="crxdo-location-highlight">{locationName}</span>
      )}
      {addressLine1 && (
        <span className="crxdo-location-highlight">{addressLine1},</span>
      )}
      {addressLine2 && (
        <span className="crxdo-location-highlight">{addressLine2},</span>
      )}
      {city && <span className="crxdo-location-highlight">{city},</span>}
      {state && <span className="crxdo-location-highlight">{state}</span>}
      {zipCodeMasked && (
        <span className="crxdo-location-highlight">{zipCodeMasked}</span>
      )}
    </div>
  );
};

LocationHighlights.propTypes = {
  location: PropTypes.object.isRequired
};

export default LocationHighlights;
