import isNullOrUndefined from '../isNullOrUndefined';

export const PATCH_OP_REPLACE = 'replace';
export const PATCH_OP_ADD = 'add';

const formatPatchData = (flatPatchDataObj, nullReplace, patchOp) => {
  if (!flatPatchDataObj) {
    return null;
  }

  const op = patchOp || PATCH_OP_REPLACE;
  const patchNullValue = isNullOrUndefined(nullReplace) ? null : nullReplace;
  const patchObjectKeys = Object.keys(flatPatchDataObj);

  const formattedPatchData = patchObjectKeys
    ? patchObjectKeys.map(patchObjectKey => {
        const patchValue = flatPatchDataObj[patchObjectKey];
        const patchValueUpdate =
          patchValue === false || !isNullOrUndefined(patchValue)
            ? patchValue
            : patchNullValue;

        return { op, path: patchObjectKey, value: patchValueUpdate };
      })
    : [];

  return formattedPatchData;
};

export default formatPatchData;
