export async function handleFetchErrorResponse(response) {
  if (!response.ok) {
    throw Error(
      (await response.statusText) ? response.statusText : response.text()
    );
  }
  return response;
}

export default function http() {
  async function callFetch(url, options) {
    const response = await fetch(url, {
      mode: 'cors',
      ...options,
      headers: {
        Accept: 'application/json',
        ...options.headers
      }
    });

    if (response.status === 204) return null;
    else if (response.ok) return await response.json();
    else return handleFetchErrorResponse(response);
  }

  async function callFetchFile(url, options) {
    const response = await fetch(url, {
      mode: 'cors',
      ...options,
      headers: {
        Accept: 'application/json',
        ...options.headers
      }
    });

    if (response.ok) return response.blob();
    else throw new Error(await response.text());
  }

  return {
    get(url) {
      return callFetch(url, {
        method: 'GET',
        headers: {
          pragma: 'no-cache',
          'cache-control': 'no-cache'
        }
      });
    },

    getFile(url) {
      return callFetchFile(url, {
        method: 'GET',
        headers: {
          pragma: 'no-cache',
          'cache-control': 'no-cache'
        }
      });
    },

    post(url, body) {
      return callFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    },

    postFormData(url, formData) {
      return callFetch(url, {
        method: 'POST',
        body: formData
      });
    },

    patch(url, body) {
      return callFetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;'
        },
        body: JSON.stringify(body)
      });
    },

    patchFormData(url, body) {
      return callFetch(url, {
        method: 'PATCH',
        body: body
      });
    },

    put(url, body) {
      return callFetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    },

    delete(url, body) {
      return callFetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    }
  };
}
