import React from 'react';
import './BrandHeader.scss';
import brandLogo from '../../../brand/images/logo.png';
import brandConfig from '../../../brand/brand-config.json';

const BrandHeader = ({ hasHeaderBorder }) => {
  const brandHeaderBorderClassName = hasHeaderBorder ? 'border-bottom' : '';
  const { language, brandName } = brandConfig;

  return (
    <header className={`crxdo-brand-header ${brandHeaderBorderClassName}`}>
      <div className="container d-flex flex-wrap justify-content-between align-items-center">
        <div className="crxdo-brand-header-logo-wrap">
          <img
            className="crxdo-brand-header-logo img-fluid"
            src={brandLogo}
            alt={`${brandName} ${
              language.GENERAL_LOGO_ALT ? language.GENERAL_LOGO_ALT : ''
            }`}
          />
        </div>
      </div>
    </header>
  );
};

export default BrandHeader;
