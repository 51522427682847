import brandConfig from '../../brand/brand-config.json';

export const FIELD_EMAIL_ERROR = brandConfig.language.GENERAL_ERROR_FIELD_EMAIL;

const validateFieldEmail = value => {
  const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
  return value
    ? emailRegex.test(value)
      ? undefined
      : FIELD_EMAIL_ERROR
    : undefined;
};

export default validateFieldEmail;
