const getPrescriberSuffix = () => {
  return [
    { code: 'MD', name: 'MD' },
    { code: 'DO', name: 'DO' },
    { code: 'PA', name: 'PA' },
    { code: 'NP', name: 'NP' }
  ];
};

export default getPrescriberSuffix;
