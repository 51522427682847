import React from 'react';
import PropTypes from 'prop-types';
import './ValidateSelect.scss';
import FieldRequiredIcon from '../FieldRequiredIcon/FieldRequiredIcon';
import FieldError from '../FieldError/FieldError';
import { Field } from 'react-final-form';
import brandConfig from '../../../brand/brand-config.json';

const ValidateSelect = ({
  id,
  options,
  optionNameKey,
  optionValueKey,
  className,
  autoComplete,
  validate,
  validateFields,
  required,
  labelTextLanguageKey,
  placeHolderTextLanguageKey
}) => {
  if (
    !id ||
    !options ||
    !optionNameKey ||
    !optionValueKey ||
    !labelTextLanguageKey
  ) {
    return null;
  }

  const { language } = brandConfig;
  const labelText = language[labelTextLanguageKey];
  const placeHolderText = placeHolderTextLanguageKey
    ? language[placeHolderTextLanguageKey]
    : null;

  return (
    <Field
      name={id}
      component="select"
      validate={validate}
      validateFields={validateFields}
      className={`crdo-validate-select ${className}`}
    >
      {({ input, meta }) => {
        const fieldHasError = meta.error && meta.touched;

        return (
          <div>
            <label htmlFor={id}>
              {labelText}
              {required && <FieldRequiredIcon />}
            </label>

            <select
              {...input}
              id={id}
              className="form-control"
              autoComplete={autoComplete}
              required={required}
              aria-required={required}
              aria-invalid={fieldHasError}
              aria-describedby={fieldHasError ? `${id}_error` : null}
            >
              {placeHolderText && <option value="">{placeHolderText}</option>}

              {options.map(option => {
                return (
                  <option
                    key={option[optionValueKey]}
                    value={option[optionValueKey]}
                  >
                    {option[optionNameKey]}
                  </option>
                );
              })}
            </select>

            <FieldError fieldMeta={meta} id={`${id}_error`} />
          </div>
        );
      }}
    </Field>
  );
};

ValidateSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  optionNameKey: PropTypes.string.isRequired,
  optionValueKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  validate: PropTypes.func,
  validateFields: PropTypes.array,
  labelTextLanguageKey: PropTypes.string.isRequired,
  placeHolderTextLanguageKey: PropTypes.string
};

export default ValidateSelect;
