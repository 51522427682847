import { isNullOrUndefinedOrEmpty } from '../';
import brandConfig from '../../brand/brand-config.json';

export const FIELD_REQUIRED_ERROR =
  brandConfig.language.GENERAL_ERROR_FIELD_REQUIRED;

const validateFieldRequired = value =>
  isNullOrUndefinedOrEmpty(value) ? FIELD_REQUIRED_ERROR : undefined;

export default validateFieldRequired;
