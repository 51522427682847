export const getRecaptchaToken = async (actionValue, config) => {
  const key = recaptchaPublicKey(config);

  return Promise.resolve(
    window.grecaptcha
      .execute(key, {
        action: actionValue
      })
      .then(token => {
        return token;
      })
  ).catch(err => {
    //console.log('Something went wrong with Recaptcha');
  });
};

export const renderRecaptcha = config => {
  const key = recaptchaPublicKey(config);
  const script = document.createElement('script');
  script.src = 'https://www.google.com/recaptcha/api.js?render=' + key;
  document.body.appendChild(script);
};

export const isRecaptchaConfigured = config => {
  if (!config) {
    return false;
  }
  const { recaptchaFlag } = config;

  return recaptchaFlag === true;
};

const recaptchaPublicKey = config => {
  if (!config) {
    return '';
  }
  const { recaptchaPublicKey } = config;

  return recaptchaPublicKey;
};
