import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './OptOut.scss';
import MessageView from '../shared/MessageView/MessageView';
import brandConfig from '../../brand/brand-config';
import { useConfig } from '../../config';
import { patchEnrollment } from '../../api';
import { getISODateString, useMount } from '../../utils';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import AlertError from '../shared/AlertError/AlertError';
import { formatPatchData } from '../../utils/format';

export const OPT_OUT_ROUTE_NAME = 'opt-out';

const OptOutView = ({ match }) => {
  const config = useConfig();
  const [error, setError] = useState(null);
  const [viewReady, setViewReady] = useState(false);

  useMount(() => {
    const params = match['params'];
    const { guid } = params;

    if (guid) {
      optOutForGuid(guid);
    } else {
      showMessageOnly();
    }
  });

  const showMessageOnly = () => {
    setViewReady(true);
  };

  const optOutForGuid = guid => {
    setError(null);
    const todayIsoDate = getISODateString();

    const optOutData = formatPatchData(
      {
        optOutDate: todayIsoDate
      },
      ''
    );

    const onHandleOptOutPatchSuccess = () => {
      setViewReady(true);
    };

    const onHandleOptOutPatchFailure = error => {
      setError(error);
      setViewReady(true);
    };

    return patchEnrollment(optOutData, guid, config)
      .then(onHandleOptOutPatchSuccess)
      .catch(onHandleOptOutPatchFailure);
  };

  const render = () => {
    const { language } = brandConfig;

    if (!viewReady) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    return (
      <div className="crxdo-opt-out">
        <MessageView>
          {error && <AlertError error={error} scrollToError={true} />}

          {!error && language.GENERAL_OPT_OUT_MESSAGE && (
            <div role="heading" className="h3">
              {language.GENERAL_OPT_OUT_MESSAGE}
            </div>
          )}
        </MessageView>
      </div>
    );
  };

  return render();
};

const OptOut = withRouter(OptOutView);

export default OptOut;
