import React from 'react';
import PropTypes from 'prop-types';
import './PrescriberDetails.scss';
import brandConfig from '../../../brand/brand-config.json';

const PrescriberDetails = ({ prescriber }) => {
  if (!prescriber) {
    return null;
  }

  const { firstName, lastName, suffix, npi } = prescriber;

  const { language } = brandConfig;

  return (
    <address className="crxdo-prescriber-details crxdo-body">
      {firstName && lastName && suffix && (
        <div className="crxdo-prescriber-detail crxdo-prescriber-detail-name">
          {`${firstName} ${lastName} ${suffix}`}
        </div>
      )}
      {npi && (
        <div className="crxdo-prescriber-detail">
          {language.GENERAL_LABEL_NPI && (
            <span className="crxdo-prescriber-detail-label">
              {language.GENERAL_LABEL_NPI}
            </span>
          )}
          <span className="crxdo-prescriber-detail">{npi}</span>
        </div>
      )}
    </address>
  );
};

PrescriberDetails.propTypes = {
  prescriber: PropTypes.object.isRequired
};

export default PrescriberDetails;
