import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, FormGroup, Row } from 'reactstrap';
import brandConfig from '../../brand/brand-config';
import './BVPortalDetails.scss';
import { putServiceBVPortal } from '../../api/index';
import { useConfig } from '../../config';
import { isNullOrUndefinedOrEmpty, useMount } from '../../utils';
import {
  composeFieldValidators,
  FIELD_REQUIRED_ERROR,
  validateFieldRequired,
  validateFieldExactLength,
  FIELD_EXACT_LENGTH_ERROR
} from '../../utils/form';
import {
  STEP_ACTION_NEXT,
  STEP_ACTION_EJECT,
  STEP_ACTION_BACK
} from '../../constants/StepActions';
import SubmitButtonSpinner from '../shared/SubmitButtonSpinner/SubmitButtonSpinner';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';
import BrandFooter from '../shared/BrandFooter/BrandFooter';
import AlertError from '../shared/AlertError/AlertError';
import ValidateInputText from '../shared/ValidateInputText/ValidateInputText';
import EnrollmentEjectButton from '../shared/EnrollmentEjectButton/EnrollmentEjectButton';
import ViewDebug from '../shared/ViewDebug/ViewDebug';

export const BV_PORTAL_DETAILS_ROUTE_NAME = 'bv-portal-details';

export const BVPortalDetailsView = ({
  enrollmentData,
  stepDetails,
  match,
  isStepAdded,
  onRequestAddStep,
  onRequestEnrollmentStart,
  onEjectEnrollment,
  onStepBackwards,
  onStepComplete,
  enrollmentError,
  onClearEnrollmentError
}) => {
  const config = useConfig();
  const [error, setError] = useState(null);
  const [currentUserGuid, setCurrentUserGuid] = useState(null);
  const [enrollmentGuid, setEnrollmentGuid] = useState(null);
  const [
    benefitVerificationPortalDetails,
    setBenefitVerificationPortalDetails
  ] = useState({});
  const [stepHasApiValues, setStepHasApiValues] = useState(false);
  const [stepReady, setStepReady] = useState(false);
  const [stepAction, setStepAction] = useState(STEP_ACTION_NEXT);

  useMount(() => {
    const enrollmentParams = match['params'];
    const { guid } = enrollmentParams;
    const {
      enrollmentGuid,
      salesRepresentativeGuid,
      practiceAdminGuid
    } = enrollmentData;

    const hasNoGuid = isNullOrUndefinedOrEmpty(guid);
    const hasNoEnrollmentData =
      !enrollmentGuid && !salesRepresentativeGuid && !practiceAdminGuid;

    if (
      hasNoGuid ||
      hasNoEnrollmentData ||
      (guid !== salesRepresentativeGuid && guid !== practiceAdminGuid)
    ) {
      onRequestEnrollmentStart(guid);
    } else {
      initializeEnrollmentDataStateForBVPortalDetails(guid);
    }
  });

  const initializeEnrollmentDataStateForBVPortalDetails = guid => {
    const { enrollmentGuid, groupNpi, groupTaxId, groupPtan } = enrollmentData;
    const benefitVerificationsPortalDetailFormValues = {
      groupNpi,
      groupTaxId,
      groupPtan
    };

    setEnrollmentGuid(enrollmentGuid);
    setCurrentUserGuid(guid);

    setBenefitVerificationPortalDetails(
      benefitVerificationsPortalDetailFormValues
    );

    const stepHasApiValues = !!groupNpi || !!groupTaxId;

    setStepHasApiValues(stepHasApiValues);
    setTimeout(() => {
      setStepReady(true);
    }, 300);

    if (!isStepAdded) {
      onRequestAddStep(BV_PORTAL_DETAILS_ROUTE_NAME);
    }
  };

  const handleStepBackwards = form => {
    if (form && form.reset) {
      form.reset();
    }

    onStepBackwards(BV_PORTAL_DETAILS_ROUTE_NAME, currentUserGuid, false);
  };

  const handleStepSubmit = async (formValues, form) => {
    const { updateStepAction } = formValues;

    setError(null);
    setStepAction(updateStepAction);
    onClearEnrollmentError();

    const onHandleSubmitFailure = error => {
      setError(error);
      setStepAction(STEP_ACTION_NEXT);
    };

    const onHandleSubmitSuccess = apiValues => {
      const updatedEnrollmentData = { ...apiValues };

      if (updateStepAction === STEP_ACTION_EJECT) {
        onEjectEnrollment(
          BV_PORTAL_DETAILS_ROUTE_NAME,
          currentUserGuid,
          enrollmentGuid,
          updateStepAction,
          config
        );
      } else {
        onStepComplete(
          BV_PORTAL_DETAILS_ROUTE_NAME,
          currentUserGuid,
          updateStepAction,
          updatedEnrollmentData
        );
      }

      form.reset();

      return true;
    };

    return await putServiceBVPortal(formValues, enrollmentGuid, config)
      .then(onHandleSubmitSuccess)
      .catch(onHandleSubmitFailure);
  };

  const isSubmitDisabled = (stepHasApiValues, pristine, submitting) => {
    return (stepHasApiValues ? false : pristine) || submitting;
  };

  const canSkipBVPortalValidation = (formValues, pristine) => {
    const { groupNpi, groupTaxId, groupPtan } = enrollmentData;

    return pristine
      ? true
      : groupNpi === formValues['groupNpi'] &&
          groupTaxId === formValues['groupTaxId'] &&
          groupPtan === formValues['groupPtan'];
  };

  const render = () => {
    const enrollmentParams = match['params'];
    const { language } = brandConfig;

    if (!stepReady || !benefitVerificationPortalDetails) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    return (
      <div className="crxdo-bv-portal-details">
        <div className="container">
          <h1 className="h2 text-primary crxdo-heading">
            {language.BV_PORTAL_DETAILS_TITLE}
          </h1>
        </div>
        <div className="crxdo-form-top-notes pt-2 px-2 pb-3">
          <div className="container crxdo-body">
            <p>{language.BV_PORTAL_DETAILS_FORM_NOTES}</p>
          </div>
        </div>

        <Form
          validateOnBlur={false}
          initialValues={benefitVerificationPortalDetails}
          onSubmit={handleStepSubmit}
          validate={formValidateBVPortalDetails}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            const canSkipValidation = canSkipBVPortalValidation(
              values,
              pristine
            );

            return (
              <form
                onSubmit={handleSubmit}
                className="crxdo-form"
                autoComplete="off"
                noValidate={true}
              >
                <div className="container">
                  {(error || enrollmentError) && (
                    <AlertError
                      error={error || enrollmentError}
                      scrollToError={true}
                    />
                  )}

                  <Row className="pb-4">
                    <div className="col-12">
                      <FormGroup>
                        <ValidateInputText
                          id="groupNpi"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_GROUP_NPI"
                          placeHolderTextLanguageKey="GENERAL_FIELD_GROUP_NPI_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired,
                            validateFieldExactLength(10)
                          )}
                          minLength={10}
                          maxLength={10}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row className="pb-4">
                    <div className="col-12">
                      <FormGroup>
                        <ValidateInputText
                          id="groupTaxId"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_GROUP_TAX_ID"
                          placeHolderTextLanguageKey="GENERAL_FIELD_GROUP_TAX_ID_PLACEHOLDER"
                          validate={composeFieldValidators(
                            validateFieldRequired
                          )}
                          maxLength={24}
                          required={true}
                          autoComplete="none"
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row className="pb-4">
                    <div className="col-12">
                      <FormGroup>
                        <ValidateInputText
                          id="groupPtan"
                          type="text"
                          labelTextLanguageKey="GENERAL_FIELD_GROUP_PTAN"
                          placeHolderTextLanguageKey="GENERAL_FIELD_GROUP_PTAN_PLACEHOLDER"
                          maxLength={50}
                          required={false}
                          autoComplete="none"
                        />
                      </FormGroup>
                      {language.GENERAL_FIELD_GROUP_PTAN_DESCRIPTION && (
                        <div
                          className="crxdo-bv-portal-details-ptan-description small crxdo-body"
                          dangerouslySetInnerHTML={{
                            __html:
                              language.GENERAL_FIELD_GROUP_PTAN_DESCRIPTION
                          }}
                        >
                          {/* {language.GENERAL_FIELD_GROUP_PTAN_DESCRIPTION} */}
                        </div>
                      )}
                    </div>
                  </Row>
                </div>

                <div className="crxdo-form-buttons crxdo-form-buttons-border-tb crxdo-form-buttons-stretch">
                  <div className="container crxdo-form-btn-container">
                    <Button
                      type="submit"
                      color="brand-1"
                      className="btn-block-sm-down crxdo-form-btn-last"
                      onClick={() => {
                        form.change('updateStepAction', STEP_ACTION_NEXT);
                      }}
                      disabled={isSubmitDisabled(
                        stepHasApiValues,
                        pristine,
                        submitting
                      )}
                    >
                      {submitting && stepAction === STEP_ACTION_NEXT ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_SAVE_AND_CONTINUE_BTN
                      )}
                    </Button>

                    <Button
                      type={canSkipValidation ? 'button' : 'submit'}
                      outline
                      color="secondary"
                      className="btn-block-sm-down crxdo-form-btn-auto-wide crxdo-form-btn-left"
                      onClick={
                        canSkipValidation
                          ? () => handleStepBackwards(form)
                          : () => {
                              form.change('updateStepAction', STEP_ACTION_BACK);
                            }
                      }
                      disabled={submitting}
                    >
                      {submitting && stepAction === STEP_ACTION_BACK ? (
                        <SubmitButtonSpinner submitting={submitting} />
                      ) : (
                        language.GENERAL_BACK_BTN
                      )}
                    </Button>

                    <EnrollmentEjectButton
                      id="bv-portal-details-send-to-primary-button"
                      stepAction={stepAction}
                      submitting={submitting}
                      disabled={isSubmitDisabled(
                        stepHasApiValues,
                        pristine,
                        submitting
                      )}
                      form={form}
                      userGuid={enrollmentParams['guid']}
                      enrollmentData={enrollmentData}
                    />
                  </div>
                </div>

                <ViewDebug className="crxdo-view-debug-bottom-right">
                  <div className="h4" role="heading">
                    Form Values & Step Details & config
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">form values</span>=
                    {JSON.stringify(values)}
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">stepDetails</span>=
                    {JSON.stringify(stepDetails)}
                  </div>
                  {config && (
                    <div className="mb-2">
                      <span className="font-weight-bold">config</span>=
                      {JSON.stringify(config)}
                    </div>
                  )}
                </ViewDebug>
              </form>
            );
          }}
        />

        <BrandFooter />
      </div>
    );
  };

  return render();
};

export const formValidateBVPortalDetails = values => {
  const errors = {};
  const { groupNpi, groupTaxId } = values;
  if (!groupNpi) {
    errors.groupNpi = FIELD_REQUIRED_ERROR;
  }
  if (groupNpi && (groupNpi.length < 10 || groupNpi.length > 10)) {
    errors.groupNpi = FIELD_EXACT_LENGTH_ERROR(10);
  }
  if (!groupTaxId) {
    errors.groupTaxId = FIELD_REQUIRED_ERROR;
  }
  return errors;
};

const BVPortalDetails = withRouter(BVPortalDetailsView);

export default BVPortalDetails;
