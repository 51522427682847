import brandConfig from '../../brand/brand-config.json';

export const FIELD_MUST_MATCH_VALUE_ERROR = valueToMatchDescription => {
  return brandConfig.language.GENERAL_ERROR_FIELD_MUST_MATCH.replace(
    '{valueToMatchDescription}',
    valueToMatchDescription
  );
};

const validateFieldMustMatchValue = (
  valueToMatch,
  valueToMatchDescription
) => value => {
  return value === valueToMatch
    ? undefined
    : FIELD_MUST_MATCH_VALUE_ERROR(valueToMatchDescription);
};

export default validateFieldMustMatchValue;
