
import React, { Component } from 'react';
import styles from './BrandFooter.scss';
import brandConfig from '../../../brand/brand-config.json';
import TermsOfUse from '../../../brand/TermsOfUse';
import SafetyInformationModal from '../../../brand/SafetyInformation';

const { language } = brandConfig;
class BrandFooter extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpenTermsOfUse: false };

    this.state = { isOpenSafety: false };
  }

  toggleModalTermsOfUse = () => {
    this.setState({
      isOpenTermsOfUse: !this.state.isOpenTermsOfUse
    });
  };

  toggleModalSafety = () => {
    this.setState({
      isOpenSafety: !this.state.isOpenSafety
    });
  };

  render() {
    return (
      <div className="BrandFooter">


      <footer className="crxdo-brand-footer" role="complementary">
      <div className="crxdo-brand-footer-details">
      {language.SUPPORT_SERVICE_NAME && (
        <span className="crxdo-brand-footer-label" >
          {language.SUPPORT_SERVICE_NAME}
        </span>
      )}
      {language.SUPPORT_SERVICE_VERSION && (
        <span className="crxdo-brand-footer-label" >
          {language.SUPPORT_SERVICE_VERSION}
        </span>
      )}
      {language.SUPPORT_SERVICE_YEAR && (
        <span className="crxdo-brand-footer-label" >
          {language.SUPPORT_SERVICE_YEAR}
        </span>
      )}
        {language.SUPPORT_SERVICE_TERMS && (
          <a
          href= "#" onClick={this.toggleModalTermsOfUse}
            aria-label={language.SUPPORT_SERVICE_TERMS}
            className="crxdo-brand-footer-value"
          >
            {language.SUPPORT_SERVICE_TERMS}
          </a>
        )}

        {language.SUPPORT_SERVICE_SAFETY && (
          <a
            href= "#" onClick={this.toggleModalSafety}
            aria-label={language.SUPPORT_SERVICE_SAFETY}
            className="crxdo-brand-footer-value"
          >
            {language.SUPPORT_SERVICE_SAFETY}
          </a>
        )}

        {language.SUPPORT_SERVICE_PRIVACY && (
          <a
            href= "https://www.connectiverx.com/privacy-policy/"
            target="_blank"
            aria-label={language.SUPPORT_SERVICE_PRIVACY}
            className="crxdo-brand-footer-value"
          >
            {language.SUPPORT_SERVICE_PRIVACY}
          </a>
        )}
      </div>
      {language.SUPPORT_SERVICE_COPYRIGHT && (
        <div className="crxdo-brand-footer-details">
          <span className="crxdo-brand-footer-value">
            {language.SUPPORT_SERVICE_COPYRIGHT}
          </span>
        </div>
      )}
    </footer>   
       <TermsOfUse show={this.state.isOpenTermsOfUse}
          onClose={this.toggleModalTermsOfUse}>
               
        </TermsOfUse>
        <SafetyInformationModal show={this.state.isOpenSafety}
          onClose={this.toggleModalSafety}>
               
        </SafetyInformationModal>
      </div>
    );
  }
}

export default BrandFooter;
