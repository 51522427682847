export const isPracticeOnly = config => {
  if (!config) {
    return false;
  }
  const { practiceOnly } = config;

  return practiceOnly === true;
};

export default isPracticeOnly;
