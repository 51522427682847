import { formatPhoneNumber } from './';

const formatUserData = userData => {
  if (!userData) {
    return null;
  }

  const formattedUserData = {
    ...userData,
    phoneMasked: formatPhoneNumber(userData ? userData.phone : '')
  };

  return formattedUserData;
};

export default formatUserData;
