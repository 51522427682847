import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import './ProgressBar.scss';

const ProgressBar = ({ id, progressPercentage, progressTitle }) => {
  if (!id || isNaN(progressPercentage)) {
    return null;
  }

  return (
    <div className="crxdo-progress-bar" id={id}>
      {progressTitle && (
        <div
          className="crxdo-progress-bar-title crxdo-heading"
          role="heading"
          aria-describedby={`${id}_progress-description`}
        >
          {progressTitle}
        </div>
      )}
      <div
        className="sr-only"
        id={`${id}_progress-description`}
      >{`${progressPercentage}% complete`}</div>
      <Progress color="primary" value={progressPercentage}>
        <span className="crxdo-progress-bar-track-percentage">
          {progressPercentage}%
        </span>
      </Progress>
    </div>
  );
};

ProgressBar.propTypes = {
  id: PropTypes.string.isRequired,
  progressPercentage: PropTypes.number.isRequired,
  progressTitle: PropTypes.string
};

export default ProgressBar;
