import { formatPhoneNumber } from './';

const formatPrescriberData = prescriberData => {
  if (!prescriberData) {
    return null;
  }

  const formattedPrescriberData = {
    ...prescriberData,
    faxMasked: formatPhoneNumber(prescriberData ? prescriberData.fax : '')
  };

  return formattedPrescriberData;
};

export default formatPrescriberData;
