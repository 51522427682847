import React, { useState } from 'react';
import './Expired.scss';
import MessageView from '../shared/MessageView/MessageView';
import brandConfig from '../../brand/brand-config';
import { useMount } from '../../utils';
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner';

export const EXPIRED_ROUTE_NAME = 'expired';

const Expired = () => {
  const [viewReady, setViewReady] = useState(false);

  useMount(() => {
    showMessageOnly();
  });

  const showMessageOnly = () => {
    setViewReady(true);
  };

  const render = () => {
    if (!viewReady) {
      return <LoadingSpinner loading={true} isViewSpinner={true} />;
    }

    const { language } = brandConfig;

    return (
      <div className="crxdo-opt-out">
        <MessageView>
          {language.GENERAL_EXPIRED_MESSAGE && (
            <div role="heading" className="h3 text-center">
              {language.GENERAL_EXPIRED_MESSAGE}
            </div>
          )}
        </MessageView>
      </div>
    );
  };

  return render();
};

export default Expired;
